var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "pa-2" },
    [
      _c("section", { staticClass: "mb-4" }, [
        !_vm.tripRequest.needDistrictVehicles
          ? _c(
              "div",
              { staticClass: "d-flex" },
              [
                _c(
                  "v-icon",
                  { staticClass: "mr-2", attrs: { color: "green" } },
                  [_vm._v(" mdi-checkbox-marked-circle ")]
                ),
                _c("h2", [
                  _vm._v("This trip does not require District Vehicles"),
                ]),
              ],
              1
            )
          : _vm.awaitingApproval
          ? _c(
              "div",
              { staticClass: "d-flex" },
              [
                _c(
                  "v-icon",
                  { staticClass: "mr-2", attrs: { color: "green" } },
                  [_vm._v(" mdi-alert-circle ")]
                ),
                _c("h2", [_vm._v("This trip requires approval first")]),
              ],
              1
            )
          : !_vm.assignmentsNeeded && !_vm.driversNeeded && !_vm.vehiclesNeeded
          ? _c(
              "div",
              { staticClass: "d-flex" },
              [
                _c(
                  "v-icon",
                  { staticClass: "mr-2", attrs: { color: "green" } },
                  [_vm._v(" mdi-checkbox-marked-circle ")]
                ),
                _c("h2", [_vm._v("This trip has a vehicle & driver assigned")]),
              ],
              1
            )
          : _c(
              "div",
              { staticClass: "d-flex" },
              [
                _c(
                  "v-icon",
                  { staticClass: "mr-2", attrs: { color: "blue" } },
                  [_vm._v(" mdi-alert-circle ")]
                ),
                _c("h2", [
                  _vm._v("This trip requires vehicle/driver assignment"),
                ]),
              ],
              1
            ),
      ]),
      _c(
        "v-row",
        [
          _c(
            "v-col",
            { attrs: { cols: "12", md: "12" } },
            [
              _vm.isLoading
                ? _c("v-progress-linear", { attrs: { indeterminate: "" } })
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
      _vm.tripRequest.needDistrictVehicles && !_vm.awaitingApproval
        ? _c(
            "section",
            [
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "12", md: "12" } },
                    [
                      _vm.tripIsPickupDropoffOnly["Pickup"] ||
                      _vm.tripIsPickupDropoffOnly["Dropoff"]
                        ? _c(
                            "v-card",
                            {
                              staticClass: "amber--text darken-1",
                              attrs: { outlined: "" },
                            },
                            [
                              _c(
                                "v-alert",
                                {
                                  staticClass: "pa-0 ma-0",
                                  attrs: {
                                    outlined: "",
                                    color: "amber darken-2",
                                  },
                                },
                                [
                                  _c(
                                    "v-card-title",
                                    [
                                      _c(
                                        "v-icon",
                                        {
                                          staticClass: "m-r-2",
                                          attrs: { color: "warning" },
                                        },
                                        [_vm._v("mdi-map-marker-alert-outline")]
                                      ),
                                      _vm._v(
                                        "This trip is" +
                                          _vm._s(
                                            _vm.tripIsPickupDropoffOnly.Dropoff
                                              ? " dropoff "
                                              : " pickup "
                                          ) +
                                          " only. "
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _c(
                        "v-card",
                        { attrs: { outlined: "" } },
                        [
                          _c("v-card-title", [_vm._v("Reserve Vehicles")]),
                          !_vm.orderOfAssignmentLocations.length &&
                          !_vm.canUseVehicleLocationDropdown
                            ? _c(
                                "v-banner",
                                { attrs: { color: "red lighten-4" } },
                                [
                                  _vm._v(
                                    " The chosen location for this trip -- "
                                  ),
                                  _c("strong", [
                                    _vm._v(_vm._s(_vm.tripLocationName)),
                                  ]),
                                  _vm._v(
                                    " -- does not have Vehicle Order of Assignments set up, and therefore you cannot reserve a vehicle location. Please reach out to your transportation admin. "
                                  ),
                                ]
                              )
                            : _c(
                                "v-card-text",
                                [
                                  _c(
                                    "v-row",
                                    [
                                      _c(
                                        "v-col",
                                        { attrs: { cols: "12", md: "6" } },
                                        [
                                          _c("v-select", {
                                            attrs: {
                                              disabled:
                                                !_vm.canUseVehicleLocationDropdown,
                                              hint: _vm.reserveFromLocationVehicleOwner,
                                              "item-text": _vm.getLocationText,
                                              items: _vm.vehicleLocations,
                                              "menu-props": {
                                                bottom: true,
                                                offsetY: true,
                                              },
                                              "item-value": "id",
                                              label:
                                                "Location Used to Reserve Vehicles",
                                              outlined: "",
                                              "persistent-hint": "",
                                            },
                                            on: {
                                              change: _vm.handleLocationChange,
                                            },
                                            model: {
                                              value: _vm.reserveFromLocation,
                                              callback: function ($$v) {
                                                _vm.reserveFromLocation = $$v
                                              },
                                              expression: "reserveFromLocation",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-col",
                                        { attrs: { cols: "auto" } },
                                        [
                                          _c(
                                            "v-btn",
                                            {
                                              staticClass: "mt-2",
                                              attrs: {
                                                disabled: _vm.disableNext,
                                                loading:
                                                  _vm.updatingReserveLocation,
                                                color: "primary",
                                              },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.nextLocation(false)
                                                },
                                              },
                                            },
                                            [_vm._v(" Next Location ")]
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-col",
                                        {
                                          directives: [
                                            {
                                              name: "show",
                                              rawName: "v-show",
                                              value:
                                                _vm.canUseVehicleLocationDropdown,
                                              expression:
                                                "canUseVehicleLocationDropdown",
                                            },
                                          ],
                                          attrs: { cols: "auto" },
                                        },
                                        [
                                          _c(
                                            "v-btn",
                                            {
                                              staticClass: "mt-2",
                                              attrs: {
                                                disabled:
                                                  !_vm.canAssign ||
                                                  !_vm.reserveLocationChanged,
                                                loading:
                                                  _vm.savingReserveLocation,
                                                color: "success",
                                              },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.nextLocation(true)
                                                },
                                              },
                                            },
                                            [_vm._v(" Save Reserve Location ")]
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-row",
                                    [
                                      _c(
                                        "v-col",
                                        { attrs: { cols: "12", md: "3" } },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "text-subtitle-2 font-weight-bold",
                                            },
                                            [_vm._v("Trip Location Zone")]
                                          ),
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "text-subtitle-2 font-weight-bold",
                                            },
                                            [_vm._v("Bid ID")]
                                          ),
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "text-subtitle-2 font-weight-bold",
                                            },
                                            [
                                              _vm._v(
                                                " # Vehicles Needed " +
                                                  _vm._s(
                                                    _vm.tripRequest.splitTrip
                                                      ? "(Per Leg)"
                                                      : ""
                                                  ) +
                                                  " "
                                              ),
                                            ]
                                          ),
                                          _vm.tripRequest.splitTrip
                                            ? _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "text-subtitle-2 font-weight-bold",
                                                },
                                                [_vm._v("Split Trip")]
                                              )
                                            : _vm._e(),
                                        ]
                                      ),
                                      _c(
                                        "v-col",
                                        { attrs: { cols: "12", md: "3" } },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "text-subtitle-2 font-weight-normal",
                                            },
                                            [_vm._v(_vm._s(_vm.tripZone))]
                                          ),
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "text-subtitle-2 font-weight-normal",
                                            },
                                            [_vm._v("xx-xx-xx")]
                                          ),
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "text-subtitle-2 font-weight-normal",
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.tripRequest.numVehicles
                                                )
                                              ),
                                            ]
                                          ),
                                          _vm.tripRequest.splitTrip
                                            ? _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "text-subtitle-2 font-weight-normal",
                                                },
                                                [_vm._v("Yes")]
                                              )
                                            : _vm._e(),
                                        ]
                                      ),
                                      _c(
                                        "v-col",
                                        { attrs: { cols: "12", md: "3" } },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "text-subtitle-2 font-weight-bold",
                                            },
                                            [_vm._v("Trip Year/Week")]
                                          ),
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "text-subtitle-2 font-weight-bold",
                                            },
                                            [_vm._v("Trip Hours")]
                                          ),
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "text-subtitle-2 font-weight-bold",
                                            },
                                            [_vm._v("Number w/ Lift")]
                                          ),
                                        ]
                                      ),
                                      _c(
                                        "v-col",
                                        { attrs: { cols: "12", md: "3" } },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "text-subtitle-2 font-weight-normal",
                                            },
                                            [_vm._v("YYYY-MM")]
                                          ),
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "text-subtitle-2 font-weight-normal",
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(_vm.tripHours) +
                                                  " "
                                              ),
                                              _vm.tripRequest.actualTimeTBD
                                                ? _c(
                                                    "span",
                                                    {
                                                      staticClass: "red--text",
                                                    },
                                                    [_vm._v("*Actual Time TBD")]
                                                  )
                                                : _vm._e(),
                                            ]
                                          ),
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "text-subtitle-2 font-weight-normal",
                                            },
                                            [_vm._v("0")]
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm.tripRequest.splitTrip &&
              _vm.assignments.length === 0 &&
              !_vm.showVehicleAndDriverAssignmentButtons
                ? _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        { attrs: { cols: "12", md: "6" } },
                        [
                          _c(
                            "v-btn",
                            {
                              staticClass: "mx-2",
                              attrs: {
                                color: "primary",
                                disabled:
                                  !_vm.canAssign || _vm.disableAssignments,
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.showVehicleAndDriverAssignment()
                                },
                              },
                            },
                            [
                              _vm._v(
                                " Click to Show Vehicle and Driver assignment buttons "
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm.tripRequest.splitTrip
                ? _c(
                    "v-row",
                    [
                      _vm.doLegAssignmentsNeeded
                        ? _c(
                            "v-col",
                            { attrs: { cols: "12", md: "6" } },
                            [
                              _c(
                                "div",
                                { staticClass: "subtitle-2 leg-label" },
                                [_vm._v("Drop-Off Leg:")]
                              ),
                              _vm.assignmentsNeeded
                                ? [
                                    _c(
                                      "v-btn",
                                      {
                                        staticClass: "mx-2",
                                        attrs: {
                                          color: "primary",
                                          disabled:
                                            !_vm.canAssign ||
                                            _vm.disableAssignments ||
                                            !_vm.reserveFromLocation,
                                        },
                                        on: {
                                          click: function ($event) {
                                            return _vm.openFAV(0, 1)
                                          },
                                        },
                                      },
                                      [_vm._v(" " + _vm._s(_vm.favText) + " ")]
                                    ),
                                    _vm.driverConfig.useAvailableDrivers
                                      ? _c(
                                          "v-btn",
                                          {
                                            staticClass: "mx-2",
                                            attrs: {
                                              color: "primary",
                                              disabled:
                                                !_vm.canAssign ||
                                                _vm.disableAssignments,
                                            },
                                            on: {
                                              click: function ($event) {
                                                return _vm.openFAD(0, 1)
                                              },
                                            },
                                          },
                                          [
                                            _vm._v(
                                              " " + _vm._s(_vm.fadText) + " "
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                  ]
                                : _vm._e(),
                            ],
                            2
                          )
                        : _c(
                            "v-col",
                            { attrs: { cols: "12", md: "6" } },
                            [_c("v-text")],
                            1
                          ),
                      _vm.rLegAssignmentsNeeded
                        ? _c(
                            "v-col",
                            { attrs: { cols: "12", md: "6" } },
                            [
                              _c(
                                "div",
                                { staticClass: "subtitle-2 leg-label" },
                                [_vm._v("Return Leg:")]
                              ),
                              _vm.assignmentsNeeded
                                ? [
                                    _c(
                                      "v-btn",
                                      {
                                        staticClass: "mx-2",
                                        attrs: {
                                          color: "primary",
                                          disabled:
                                            !_vm.canAssign ||
                                            _vm.disableAssignments ||
                                            !_vm.reserveFromLocation,
                                        },
                                        on: {
                                          click: function ($event) {
                                            return _vm.openFAV(0, 2)
                                          },
                                        },
                                      },
                                      [_vm._v(" " + _vm._s(_vm.favText) + " ")]
                                    ),
                                    _vm.driverConfig.useAvailableDrivers
                                      ? _c(
                                          "v-btn",
                                          {
                                            staticClass: "mx-2",
                                            attrs: {
                                              color: "primary",
                                              disabled:
                                                !_vm.canAssign ||
                                                _vm.disableAssignments,
                                            },
                                            on: {
                                              click: function ($event) {
                                                return _vm.openFAD(0, 2)
                                              },
                                            },
                                          },
                                          [
                                            _vm._v(
                                              " " + _vm._s(_vm.fadText) + " "
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                  ]
                                : _vm._e(),
                            ],
                            2
                          )
                        : _c(
                            "v-col",
                            { attrs: { cols: "12", md: "6" } },
                            [_c("v-text")],
                            1
                          ),
                    ],
                    1
                  )
                : _vm.tripRequest.vehicleType !== _vm.VEHICLE_TYPES.NORMAL &&
                  _vm.tripRequest.additionalTransportationId
                ? _c(
                    "v-row",
                    [
                      _vm.assignmentsNeeded
                        ? _c(
                            "v-col",
                            { attrs: { cols: "12", md: "6" } },
                            [
                              _c(
                                "v-btn",
                                {
                                  attrs: {
                                    color: "primary",
                                    disabled:
                                      _vm.isLoading ||
                                      !_vm.canAssign ||
                                      _vm.disableAssignments ||
                                      (_vm.tripRequest.status == -3 &&
                                        !_vm.isAdmin &&
                                        !_vm.vehicleOwnerConfig.assignCanceled),
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.addAgencyAssignments()
                                    },
                                  },
                                },
                                [
                                  _vm._v(
                                    " Fulfill Assignments From " +
                                      _vm._s(
                                        _vm.vehicleTypesById[
                                          _vm.tripRequest.vehicleTypeId
                                        ].name
                                      ) +
                                      " "
                                  ),
                                ]
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  )
                : _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        { attrs: { cols: "12", md: "6" } },
                        [
                          _vm.assignmentsNeeded
                            ? [
                                _c(
                                  "v-btn",
                                  {
                                    attrs: {
                                      color: "primary",
                                      disabled:
                                        !_vm.canAssign ||
                                        _vm.disableAssignments ||
                                        (_vm.tripRequest.status == -3 &&
                                          !_vm.isAdmin &&
                                          !_vm.vehicleOwnerConfig
                                            .assignCanceled) ||
                                        !_vm.reserveFromLocation,
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.openFAV(0)
                                      },
                                    },
                                  },
                                  [_vm._v(" " + _vm._s(_vm.favText) + " ")]
                                ),
                              ]
                            : _vm._e(),
                        ],
                        2
                      ),
                      _vm.driverConfig.useAvailableDrivers
                        ? _c(
                            "v-col",
                            { attrs: { cols: "12", md: "6" } },
                            [
                              _vm.assignmentsNeeded
                                ? [
                                    _vm.driverConfig.useAvailableDrivers
                                      ? _c(
                                          "v-btn",
                                          {
                                            attrs: {
                                              color: "primary",
                                              disabled:
                                                !_vm.canAssign ||
                                                _vm.disableAssignments ||
                                                (_vm.tripRequest.status == -3 &&
                                                  !_vm.isAdmin &&
                                                  !_vm.vehicleOwnerConfig
                                                    .assignCanceled) ||
                                                (!_vm.tripRequest
                                                  .needDriverAssigned &&
                                                  !_vm.driverConfig
                                                    .useAvailableDrivers),
                                            },
                                            on: {
                                              click: function ($event) {
                                                return _vm.openFAD(0)
                                              },
                                            },
                                          },
                                          [
                                            _vm._v(
                                              " " + _vm._s(_vm.fadText) + " "
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                  ]
                                : _vm._e(),
                            ],
                            2
                          )
                        : !_vm.assignments.length ||
                          _vm.assignments.length < _vm.tripRequest.numVehicles
                        ? _c(
                            "v-col",
                            [
                              _c(
                                "v-row",
                                [
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "9" } },
                                    [
                                      _c(
                                        "v-row",
                                        [
                                          _c(
                                            "v-col",
                                            { attrs: { cols: "12", md: "6" } },
                                            [
                                              _c(
                                                "v-combobox",
                                                _vm._b(
                                                  {
                                                    attrs: {
                                                      items: _vm.driverItems,
                                                      "item-text":
                                                        _vm.getDriverText,
                                                      label: "Driver",
                                                      "return-object": "",
                                                      disabled: !_vm.canAssign,
                                                    },
                                                    on: {
                                                      keydown: function (
                                                        $event
                                                      ) {
                                                        _vm.driver.details =
                                                          null
                                                      },
                                                      change: function (
                                                        $event
                                                      ) {
                                                        _vm.driver.email =
                                                          _vm.driver.details
                                                            ?.email ||
                                                          _vm.driver.email
                                                      },
                                                    },
                                                    model: {
                                                      value: _vm.driver.details,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.driver,
                                                          "details",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "driver.details",
                                                    },
                                                  },
                                                  "v-combobox",
                                                  _vm.inputProps,
                                                  false
                                                )
                                              ),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "v-col",
                                            { attrs: { cols: "12", md: "6" } },
                                            [
                                              _c(
                                                "v-text-field",
                                                _vm._b(
                                                  {
                                                    attrs: {
                                                      label: "Driver Email",
                                                      disabled: !_vm.canAssign,
                                                    },
                                                    model: {
                                                      value: _vm.driver.email,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.driver,
                                                          "email",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "driver.email",
                                                    },
                                                  },
                                                  "v-text-field",
                                                  _vm.inputProps,
                                                  false
                                                )
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-col",
                                    {
                                      staticClass:
                                        "d-flex align-center justify-center",
                                      attrs: { cols: "3" },
                                    },
                                    [
                                      _c(
                                        "v-btn",
                                        {
                                          attrs: {
                                            block: "",
                                            color: "primary",
                                            disabled:
                                              _vm.isReserveDriverDisabled,
                                          },
                                          on: { click: _vm.reserveDriver },
                                        },
                                        [_vm._v(" Reserve Driver ")]
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
              _c(
                "v-row",
                _vm._l(_vm.assignments, function (assignment, i) {
                  return _c(
                    "v-col",
                    { key: i, attrs: { cols: "12", md: "12" } },
                    [
                      _c("trip-request-assignment-card", {
                        key: i,
                        attrs: {
                          assignment: assignment,
                          tripRequest: _vm.tripRequest,
                          index: i,
                          isLoading: _vm.isLoading,
                          isLoadingFAD: _vm.isLoadingFAD,
                          isLoadingVehicle: _vm.isLoadingVehicle,
                          driverItems: _vm.driverItems,
                          mechanicItems: _vm.mechanicItems,
                        },
                        on: {
                          adminDecline: _vm.adminDecline,
                          openFAD: _vm.openFAD,
                          openFAV: _vm.openFAV,
                          reserve: _vm.reserve,
                          refresh: _vm.refresh,
                        },
                      }),
                    ],
                    1
                  )
                }),
                1
              ),
              _vm.declinedAssignments.length > 0
                ? _c(
                    "v-row",
                    { attrs: { dense: "" } },
                    [
                      _c(
                        "v-col",
                        { attrs: { cols: "12", md: "6" } },
                        [
                          _c(
                            "v-btn",
                            {
                              staticClass: "mt-2",
                              attrs: { color: "primary", text: "" },
                              on: { click: _vm.toggleDeclinedAssignments },
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.showDeclinedAssignments
                                      ? "Hide"
                                      : "Show"
                                  ) +
                                  " Declined Assignments "
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "v-row",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.showDeclinedAssignments,
                      expression: "showDeclinedAssignments",
                    },
                  ],
                },
                _vm._l(_vm.declinedAssignments, function (assignment, i) {
                  return _c(
                    "v-col",
                    { key: i, attrs: { cols: "12", md: "12" } },
                    [
                      _c("trip-request-assignment-card", {
                        key: i,
                        attrs: {
                          assignment: assignment,
                          tripRequest: _vm.tripRequest,
                          index: i,
                          isLoading: _vm.isLoading,
                          isLoadingFAD: _vm.isLoadingFAD,
                          isLoadingVehicle: _vm.isLoadingVehicle,
                          driverItems: _vm.driverItems,
                          mechanicItems: _vm.mechanicItems,
                        },
                        on: {
                          adminDecline: _vm.adminDecline,
                          openFAD: _vm.openFAD,
                          openFAV: _vm.openFAV,
                          reserve: _vm.reserve,
                          refresh: _vm.refresh,
                        },
                      }),
                    ],
                    1
                  )
                }),
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _c("find-available-vehicles", {
        ref: "fav",
        attrs: {
          tripRequest: _vm.tripRequest,
          tripRequestConfig: _vm.tripRequestConfig,
          voConfig: _vm.permissions.vehicleOwner,
          reserveFromLocation: _vm.reserveFromLocation,
          max: _vm.maxAssignments,
          reserved: _vm.assignments.length,
          reserving: _vm.reserving,
        },
        on: { reserve: _vm.reserve },
      }),
      _c("find-available-drivers", {
        ref: "fad",
        attrs: {
          tripRequest: _vm.tripRequest,
          tripRequestConfig: _vm.tripRequestConfig,
          driverConfig: _vm.driverConfig,
          reserveFromLocation: _vm.reserveFromLocation,
          max: _vm.maxAssignments,
          reserved: _vm.assignments.length,
          reserving: _vm.reserving,
        },
        on: { reserve: _vm.reserve },
      }),
      _c("decline-assignment", {
        ref: "decline",
        on: { refresh: _vm.refresh },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }