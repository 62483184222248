var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-dialog",
    {
      attrs: {
        "max-width": "1300px",
        "no-click-animation": "",
        persistent: "",
        scrollable: "",
        fullscreen: "",
      },
      model: {
        value: _vm.dialog,
        callback: function ($$v) {
          _vm.dialog = $$v
        },
        expression: "dialog",
      },
    },
    [
      _c(
        "v-card",
        [
          _c(
            "v-card-title",
            [
              _c(
                "v-btn",
                {
                  staticClass: "mr-2",
                  attrs: { icon: "" },
                  on: { click: _vm.close },
                },
                [_c("v-icon", [_vm._v("mdi-close")])],
                1
              ),
              _vm._v(
                " " +
                  _vm._s(
                    _vm.override
                      ? "Reserve any Vehicle"
                      : "Find Available Vehicles"
                  ) +
                  " "
              ),
            ],
            1
          ),
          _c(
            "v-card-text",
            { staticClass: "pb-0" },
            [
              _vm.override
                ? _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        { attrs: { cols: "12", md: "12" } },
                        [
                          _c(
                            "v-alert",
                            {
                              staticClass: "mb-0",
                              attrs: { outlined: "", type: "info", text: "" },
                            },
                            [
                              _vm._v(
                                " This list DOES NOT check for vehicle availability! "
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "v-row",
                { staticClass: "mt-0" },
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "12", md: "12" } },
                    [
                      _c("assignment-info", {
                        attrs: {
                          leg: _vm.leg,
                          tripRequest: _vm.tripRequest,
                          tripRequestConfig: _vm.tripRequestConfig,
                          reserveFromLocation: _vm.reserveFromLocation,
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-row",
                { attrs: { dense: "" } },
                [
                  _vm.isAdmin ||
                  (_vm.me.is.vehicleOwner &&
                    _vm.permissions.vehicleOwner.overrideVehicle) ||
                  (_vm.me.is.specialNeedsVehicleOwner &&
                    _vm.permissions.specialNeedsVehicleOwner.overrideVehicle)
                    ? _c(
                        "v-col",
                        { attrs: { cols: "auto" } },
                        [
                          _c(
                            "v-btn",
                            {
                              staticClass: "mt-4",
                              attrs: { color: "primary" },
                              on: {
                                click: function ($event) {
                                  return _vm.overrideList()
                                },
                              },
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.override
                                      ? "Check Availability"
                                      : "Override"
                                  ) +
                                  " "
                              ),
                            ]
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.isAdmin ||
                  (_vm.me.is.vehicleOwner &&
                    _vm.permissions.vehicleOwner.showAllLocations) ||
                  (_vm.me.is.specialNeedsVehicleOwner &&
                    _vm.permissions.specialNeedsVehicleOwner.showAllLocations)
                    ? _c(
                        "v-col",
                        { attrs: { cols: "auto" } },
                        [
                          _c(
                            "v-btn",
                            {
                              staticClass: "mt-4",
                              attrs: { color: "primary" },
                              on: {
                                click: function ($event) {
                                  return _vm.filterList(true)
                                },
                              },
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.allLocations
                                      ? "Trip Location"
                                      : "All Locations"
                                  ) +
                                  " "
                              ),
                            ]
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "v-col",
                    { attrs: { cols: "auto ml-4" } },
                    [
                      _c("v-checkbox", {
                        attrs: { label: "w/ Wheelchair Lift" },
                        on: {
                          change: function ($event) {
                            return _vm.filterList(false)
                          },
                        },
                        model: {
                          value: _vm.showWithLift,
                          callback: function ($$v) {
                            _vm.showWithLift = $$v
                          },
                          expression: "showWithLift",
                        },
                      }),
                    ],
                    1
                  ),
                  _c("v-spacer"),
                  _c(
                    "v-col",
                    { attrs: { cols: "12", md: "4" } },
                    [
                      _c("v-select", {
                        attrs: {
                          "menu-props": { bottom: true, offsetY: true },
                          items: _vm.vehicleTypeItems,
                          "item-text": "name",
                          "item-value": "id",
                          label: "VehicleType",
                          outlined: "",
                          clearable: "",
                          "hide-details": "",
                        },
                        on: {
                          change: function ($event) {
                            return _vm.filterList(false, false)
                          },
                        },
                        model: {
                          value: _vm.filterVehicleType,
                          callback: function ($$v) {
                            _vm.filterVehicleType = $$v
                          },
                          expression: "filterVehicleType",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-row",
                { staticClass: "mt-0" },
                [
                  _c(
                    "v-col",
                    {
                      staticClass: "mt-0 py-0",
                      attrs: { cols: "12", md: "12" },
                    },
                    [
                      _c("v-data-table", {
                        attrs: {
                          "fixed-header": "",
                          headers: _vm.headers,
                          items: _vm.filteredAvailable,
                          "hide-default-footer": "",
                          "items-per-page": _vm.filteredAvailable.length,
                          "item-key": "id",
                          "show-select": "",
                          "disable-sort": "",
                          "no-data-text": "No vehicles available",
                          height: _vm.tableHeight,
                          loading: _vm.loading,
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: `header.data-table-select`,
                              fn: function () {
                                return undefined
                              },
                              proxy: true,
                            },
                            {
                              key: `item.data-table-select`,
                              fn: function ({ item, isSelected, select }) {
                                return [
                                  _c("v-simple-checkbox", {
                                    directives: [
                                      { name: "ripple", rawName: "v-ripple" },
                                    ],
                                    attrs: {
                                      value: isSelected,
                                      disabled: item.cannotAssign,
                                    },
                                    on: {
                                      input: function ($event) {
                                        return select($event)
                                      },
                                    },
                                  }),
                                ]
                              },
                            },
                            {
                              key: `item.type`,
                              fn: function ({ item }) {
                                return [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.vehicleTypesById[item.type]
                                          ? _vm.vehicleTypesById[item.type].name
                                          : "-"
                                      ) +
                                      " "
                                  ),
                                ]
                              },
                            },
                            {
                              key: `item.driver`,
                              fn: function ({ item }) {
                                return [
                                  _c(
                                    "v-tooltip",
                                    {
                                      attrs: {
                                        right: "",
                                        disabled: !item.driverConflict,
                                      },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "activator",
                                            fn: function ({ on, attrs }) {
                                              return [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.vehicleTypesById[
                                                        item.driverId
                                                      ]
                                                        ? _vm.driversById[
                                                            item.driverId
                                                          ].firstName +
                                                            " " +
                                                            _vm.driversById[
                                                              item.driverId
                                                            ].lastName
                                                        : item.driver || "-"
                                                    ) +
                                                    " "
                                                ),
                                                item.driverConflict
                                                  ? _c(
                                                      "v-icon",
                                                      _vm._g(
                                                        _vm._b(
                                                          {
                                                            attrs: {
                                                              color: "warning",
                                                              dark: "",
                                                            },
                                                          },
                                                          "v-icon",
                                                          attrs,
                                                          false
                                                        ),
                                                        on
                                                      ),
                                                      [_vm._v(" mdi-alert ")]
                                                    )
                                                  : _vm._e(),
                                              ]
                                            },
                                          },
                                        ],
                                        null,
                                        true
                                      ),
                                    },
                                    [_vm._v(" This driver cannot be assigned ")]
                                  ),
                                ]
                              },
                            },
                            {
                              key: `item.cap`,
                              fn: function ({ item }) {
                                return [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        item.capacityElem
                                          ? item.capacityElem
                                          : ""
                                      ) +
                                      _vm._s(
                                        item.capacityMid
                                          ? `/${item.capacityMid}`
                                          : ""
                                      ) +
                                      _vm._s(
                                        item.capacityHigh
                                          ? `/${item.capacityHigh}`
                                          : ""
                                      ) +
                                      " "
                                  ),
                                ]
                              },
                            },
                            {
                              key: `item.location`,
                              fn: function ({ item }) {
                                return [
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm.getVehicleLocation(item.id)) +
                                      " "
                                  ),
                                ]
                              },
                            },
                            {
                              key: `item.conditions`,
                              fn: function ({ item }) {
                                return [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        item.travelConditions
                                          ? _vm.travelConditions[
                                              item.travelConditions
                                            ]
                                          : ""
                                      ) +
                                      " "
                                  ),
                                ]
                              },
                            },
                            {
                              key: `item.lift`,
                              fn: function ({ item }) {
                                return [
                                  _c("v-icon", [
                                    _vm._v(
                                      "mdi-" +
                                        _vm._s(
                                          item.wheelchairLift
                                            ? "check"
                                            : "close"
                                        )
                                    ),
                                  ]),
                                ]
                              },
                            },
                          ],
                          null,
                          true
                        ),
                        model: {
                          value: _vm.selected,
                          callback: function ($$v) {
                            _vm.selected = $$v
                          },
                          expression: "selected",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c("v-divider"),
          _c(
            "v-card-actions",
            [
              _c("v-spacer"),
              _vm.selected.length
                ? [
                    _vm.hasDriver(_vm.selected) && !_vm.hideDrivers
                      ? _c(
                          "v-btn",
                          {
                            staticClass: "mx-2",
                            attrs: {
                              color: "primary",
                              loading: _vm.reserving,
                              disabled:
                                _vm.reserving ||
                                _vm.isDriverManuallyAssigned ||
                                _vm.driverConflict,
                            },
                            on: {
                              click: function ($event) {
                                return _vm.reserve(true)
                              },
                            },
                          },
                          [
                            _vm._v(
                              " Reserve " +
                                _vm._s(_vm.selected.length) +
                                " Vehicle" +
                                _vm._s(_vm.selected.length > 1 ? "s" : "") +
                                " & Driver(s) "
                            ),
                          ]
                        )
                      : _vm._e(),
                    _c(
                      "v-btn",
                      {
                        staticClass: "mx-2",
                        attrs: {
                          color: "primary",
                          loading: _vm.reserving,
                          disabled: _vm.reserving,
                        },
                        on: {
                          click: function ($event) {
                            return _vm.reserve(false)
                          },
                        },
                      },
                      [
                        _vm._v(
                          " Reserve " +
                            _vm._s(_vm.selected.length) +
                            " Vehicle" +
                            _vm._s(_vm.selected.length > 1 ? "s" : "") +
                            " "
                        ),
                      ]
                    ),
                  ]
                : _vm._e(),
            ],
            2
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }