var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-dialog",
    {
      attrs: {
        "max-width": "1300px",
        "no-click-animation": "",
        persistent: "",
        scrollable: "",
        fullscreen: "",
      },
      model: {
        value: _vm.dialog,
        callback: function ($$v) {
          _vm.dialog = $$v
        },
        expression: "dialog",
      },
    },
    [
      _c(
        "v-card",
        [
          _c(
            "v-card-title",
            [
              _c(
                "v-btn",
                {
                  staticClass: "mr-2",
                  attrs: { icon: "" },
                  on: { click: _vm.close },
                },
                [_c("v-icon", [_vm._v("mdi-close")])],
                1
              ),
              _vm._v(
                " " +
                  _vm._s(
                    _vm.override
                      ? "Reserve any Driver"
                      : "Find Available Drivers"
                  ) +
                  " "
              ),
            ],
            1
          ),
          _c(
            "v-card-text",
            { staticClass: "pb-0" },
            [
              _vm.override
                ? _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        { attrs: { cols: "12", md: "12" } },
                        [
                          _c(
                            "v-alert",
                            {
                              staticClass: "mb-0",
                              attrs: { outlined: "", type: "info", text: "" },
                            },
                            [
                              _vm._v(
                                " This list DOES NOT check for driver availability! "
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "v-row",
                { staticClass: "mt-0" },
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "12", md: "12" } },
                    [
                      _c("assignment-info", {
                        attrs: {
                          leg: _vm.leg,
                          tripRequest: _vm.tripRequest,
                          tripRequestConfig: _vm.tripRequestConfig,
                          reserveFromLocation: _vm.reserveFromLocation,
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-row",
                { staticClass: "mt-0", attrs: { dense: "" } },
                [
                  _vm.isAdmin ||
                  (_vm.me.is.vehicleOwner &&
                    _vm.permissions.vehicleOwner.overrideDriver)
                    ? _c(
                        "v-col",
                        { attrs: { cols: "auto" } },
                        [
                          _c(
                            "v-btn",
                            {
                              staticClass: "mt-3",
                              attrs: { color: "primary" },
                              on: {
                                click: function ($event) {
                                  return _vm.overrideList()
                                },
                              },
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.override
                                      ? "Check Availability"
                                      : "Override"
                                  ) +
                                  " "
                              ),
                            ]
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "v-col",
                    { attrs: { cols: "auto" } },
                    [
                      _c(
                        "v-btn",
                        {
                          staticClass: "mt-3",
                          attrs: { color: "primary" },
                          on: {
                            click: function ($event) {
                              return _vm.filterList(true)
                            },
                          },
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.showAllZones ? "Trip Zone" : "All Zones"
                              ) +
                              " "
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                  _c("v-spacer"),
                  _c(
                    "v-col",
                    { attrs: { cols: "12", md: "3" } },
                    [
                      _c("v-combobox", {
                        attrs: {
                          value: _vm.nameFilter,
                          label: "Filter by name",
                          items: _vm.filteredAvailable,
                          "item-text": _vm.getDriverText,
                          "item-value": "id",
                          clearable: "",
                          outlined: "",
                          "hide-details": "",
                        },
                        on: { change: _vm.filterByName },
                        model: {
                          value: _vm.nameFilter,
                          callback: function ($$v) {
                            _vm.nameFilter = $$v
                          },
                          expression: "nameFilter",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { attrs: { cols: "12", md: "3" } },
                    [
                      _c("v-select", {
                        attrs: {
                          "menu-props": { bottom: true, offsetY: true },
                          items: _vm.locations,
                          "item-text": _vm.getLocationText,
                          "item-value": "id",
                          label: "Location",
                          outlined: "",
                          clearable: "",
                          "hide-details": "",
                        },
                        on: {
                          change: function ($event) {
                            return _vm.filterList(false)
                          },
                        },
                        model: {
                          value: _vm.filterLocation,
                          callback: function ($$v) {
                            _vm.filterLocation = $$v
                          },
                          expression: "filterLocation",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { attrs: { cols: "12", md: "2" } },
                    [
                      _c("v-select", {
                        attrs: {
                          "menu-props": { bottom: true, offsetY: true },
                          items: ["All", "None", ..._vm.designations],
                          label: "Designation",
                          outlined: "",
                          clearable: "",
                          "hide-details": "",
                        },
                        on: {
                          change: function ($event) {
                            return _vm.filterList(false)
                          },
                        },
                        model: {
                          value: _vm.filterDesignation,
                          callback: function ($$v) {
                            _vm.filterDesignation = $$v
                          },
                          expression: "filterDesignation",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-row",
                { staticClass: "mt-0" },
                [
                  _c(
                    "v-col",
                    { staticClass: "pb-0", attrs: { cols: "12", md: "12" } },
                    [
                      _c(
                        "v-radio-group",
                        {
                          staticClass: "mt-0",
                          attrs: { row: "", "hide-details": "" },
                          on: { change: _vm.handleSort },
                          model: {
                            value: _vm.sort,
                            callback: function ($$v) {
                              _vm.sort = $$v
                            },
                            expression: "sort",
                          },
                        },
                        [
                          _c("v-radio", {
                            attrs: {
                              label: "Sort By Rotation",
                              value: "rotation",
                            },
                          }),
                          _c("v-radio", {
                            attrs: { label: "Sort By Name", value: "name" },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-row",
                { staticClass: "mt-0" },
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "12", md: "12" } },
                    [
                      _c("v-data-table", {
                        attrs: {
                          "fixed-header": "",
                          headers: _vm.headers,
                          items: _vm.filteredAvailable,
                          "hide-default-footer": "",
                          "items-per-page": _vm.filteredAvailable.length,
                          "item-key": "id",
                          "show-select": "",
                          "disable-sort": "",
                          "no-data-text": "No drivers available",
                          height: _vm.tableHeight,
                          loading: _vm.loading,
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: `header.data-table-select`,
                              fn: function () {
                                return undefined
                              },
                              proxy: true,
                            },
                            {
                              key: `header.bus`,
                              fn: function ({ header }) {
                                return [
                                  _c(
                                    "v-tooltip",
                                    {
                                      attrs: {
                                        right: "",
                                        color: "rgb(227, 227, 227)",
                                      },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "activator",
                                            fn: function ({ on, attrs }) {
                                              return [
                                                _c(
                                                  "v-icon",
                                                  _vm._g(
                                                    _vm._b(
                                                      {
                                                        attrs: {
                                                          color: "primary",
                                                          dark: "",
                                                        },
                                                      },
                                                      "v-icon",
                                                      attrs,
                                                      false
                                                    ),
                                                    on
                                                  ),
                                                  [_vm._v(" mdi-information ")]
                                                ),
                                                _vm._v(
                                                  " " +
                                                    _vm._s(header.text) +
                                                    " "
                                                ),
                                              ]
                                            },
                                          },
                                        ],
                                        null,
                                        true
                                      ),
                                    },
                                    [
                                      _c("p", { staticClass: "black--text" }, [
                                        _vm._v("Matches Lift & Vehicle Type"),
                                      ]),
                                      _c(
                                        "p",
                                        {
                                          staticClass:
                                            "font-weight-bold amber--text text--darken-2",
                                        },
                                        [_vm._v("Lift mismatch")]
                                      ),
                                      _c(
                                        "p",
                                        {
                                          staticClass:
                                            "font-weight-bold deep-purple--text text--darken-3",
                                        },
                                        [_vm._v("Vehicle Type mismatch")]
                                      ),
                                      _c(
                                        "p",
                                        {
                                          staticClass:
                                            "font-weight-bold red--text text--darken-1",
                                        },
                                        [_vm._v("Lift & Vehicle Type mismatch")]
                                      ),
                                    ]
                                  ),
                                ]
                              },
                            },
                            {
                              key: `item.name`,
                              fn: function ({ item }) {
                                return [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        item.firstName + " " + item.lastName
                                      ) +
                                      " "
                                  ),
                                ]
                              },
                            },
                            {
                              key: `item.tooltip`,
                              fn: function ({ item }) {
                                return [
                                  _c(
                                    "v-tooltip",
                                    {
                                      attrs: { right: "" },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "activator",
                                            fn: function ({ on, attrs }) {
                                              return [
                                                _c(
                                                  "v-icon",
                                                  _vm._g(
                                                    _vm._b(
                                                      {
                                                        attrs: {
                                                          color: "primary",
                                                          dark: "",
                                                        },
                                                      },
                                                      "v-icon",
                                                      attrs,
                                                      false
                                                    ),
                                                    on
                                                  ),
                                                  [_vm._v(" mdi-information ")]
                                                ),
                                              ]
                                            },
                                          },
                                        ],
                                        null,
                                        true
                                      ),
                                    },
                                    [
                                      _c("p", [
                                        _c("strong", [
                                          _vm._v("Base Location: "),
                                        ]),
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.locationsById[
                                                item.baseLocationId
                                              ]
                                                ? _vm.locationsById[
                                                    item.baseLocationId
                                                  ].name
                                                : "(not assigned)"
                                            ) +
                                            " "
                                        ),
                                      ]),
                                      item.secondaryLocationId
                                        ? _c("p", [
                                            _c("strong", [
                                              _vm._v("Secondary Location: "),
                                            ]),
                                            _vm._v(
                                              _vm._s(
                                                _vm.locationsById[
                                                  item.secondaryLocationId
                                                ].name
                                              ) + " "
                                            ),
                                          ])
                                        : _vm._e(),
                                    ]
                                  ),
                                ]
                              },
                            },
                            {
                              key: `item.bus`,
                              fn: function ({ item }) {
                                return [
                                  _c(
                                    "v-tooltip",
                                    {
                                      attrs: {
                                        right: "",
                                        disabled: !item.vehicleConflict,
                                      },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "activator",
                                            fn: function ({ on, attrs }) {
                                              return [
                                                _c(
                                                  "span",
                                                  { class: item.vehicleColor },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          _vm.vehiclesById[
                                                            item
                                                              .assignedVehicleId
                                                          ]
                                                            ? _vm.vehiclesById[
                                                                item
                                                                  .assignedVehicleId
                                                              ].name
                                                            : "-"
                                                        ) +
                                                        " "
                                                    ),
                                                    item.vehicleConflict
                                                      ? _c(
                                                          "v-icon",
                                                          _vm._g(
                                                            _vm._b(
                                                              {
                                                                attrs: {
                                                                  color:
                                                                    "warning",
                                                                  dark: "",
                                                                },
                                                              },
                                                              "v-icon",
                                                              attrs,
                                                              false
                                                            ),
                                                            on
                                                          ),
                                                          [
                                                            _vm._v(
                                                              " mdi-alert "
                                                            ),
                                                          ]
                                                        )
                                                      : _vm._e(),
                                                  ],
                                                  1
                                                ),
                                              ]
                                            },
                                          },
                                        ],
                                        null,
                                        true
                                      ),
                                    },
                                    [
                                      _vm._v(
                                        " This vehicle cannot be assigned "
                                      ),
                                    ]
                                  ),
                                ]
                              },
                            },
                            {
                              key: `item.regHours`,
                              fn: function ({ item }) {
                                return [
                                  _vm._v(" " + _vm._s(item.regHoursWeek) + " "),
                                ]
                              },
                            },
                            {
                              key: `item.am`,
                              fn: function ({ item }) {
                                return [
                                  _c("v-icon", [
                                    _vm._v(
                                      "mdi-" +
                                        _vm._s(item.prefAM ? "check" : "close")
                                    ),
                                  ]),
                                ]
                              },
                            },
                            {
                              key: `item.pm`,
                              fn: function ({ item }) {
                                return [
                                  _c("v-icon", [
                                    _vm._v(
                                      "mdi-" +
                                        _vm._s(item.prefPM ? "check" : "close")
                                    ),
                                  ]),
                                ]
                              },
                            },
                            {
                              key: `item.midday`,
                              fn: function ({ item }) {
                                return [
                                  _c("v-icon", [
                                    _vm._v(
                                      "mdi-" +
                                        _vm._s(
                                          item.prefMidDay ? "check" : "close"
                                        )
                                    ),
                                  ]),
                                ]
                              },
                            },
                            {
                              key: `item.overnight`,
                              fn: function ({ item }) {
                                return [
                                  _c("v-icon", [
                                    _vm._v(
                                      "mdi-" +
                                        _vm._s(
                                          item.prefOvernight ? "check" : "close"
                                        )
                                    ),
                                  ]),
                                ]
                              },
                            },
                            {
                              key: `item.weekend`,
                              fn: function ({ item }) {
                                return [
                                  _c("v-icon", [
                                    _vm._v(
                                      "mdi-" +
                                        _vm._s(
                                          item.prefWeekend ? "check" : "close"
                                        )
                                    ),
                                  ]),
                                ]
                              },
                            },
                            {
                              key: `item.nonSchool`,
                              fn: function ({ item }) {
                                return [
                                  _c("v-icon", [
                                    _vm._v(
                                      "mdi-" +
                                        _vm._s(
                                          item.prefNonSchool ? "check" : "close"
                                        )
                                    ),
                                  ]),
                                ]
                              },
                            },
                          ],
                          null,
                          true
                        ),
                        model: {
                          value: _vm.selected,
                          callback: function ($$v) {
                            _vm.selected = $$v
                          },
                          expression: "selected",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c("v-divider"),
          _c(
            "v-card-actions",
            [
              _c("v-text-field", {
                attrs: {
                  value: _vm.driverLogNotes,
                  label: "Log Notes",
                  outlined: "",
                  "hide-details": "",
                },
                model: {
                  value: _vm.driverLogNotes,
                  callback: function ($$v) {
                    _vm.driverLogNotes =
                      typeof $$v === "string" ? $$v.trim() : $$v
                  },
                  expression: "driverLogNotes",
                },
              }),
              _c(
                "v-btn",
                {
                  staticClass: "mx-2",
                  attrs: { text: "" },
                  on: { click: _vm.submitLogNotes },
                },
                [_vm._v("Save")]
              ),
              _c("v-spacer"),
              _vm.selected.length
                ? [
                    !_vm.assistant && _vm.hasVehicle(_vm.selected)
                      ? _c(
                          "v-btn",
                          {
                            staticClass: "mx-2",
                            attrs: {
                              color: "primary",
                              loading: _vm.reserving,
                              disabled: _vm.reserving || _vm.vehicleConflict,
                            },
                            on: {
                              click: function ($event) {
                                return _vm.reserve(true)
                              },
                            },
                          },
                          [
                            _vm._v(
                              " Reserve " +
                                _vm._s(_vm.selected.length) +
                                " Driver" +
                                _vm._s(_vm.selected.length > 1 ? "s" : "") +
                                " & Vehicle(s) "
                            ),
                          ]
                        )
                      : _vm._e(),
                    _c(
                      "v-btn",
                      {
                        staticClass: "mx-2",
                        attrs: {
                          color: "primary",
                          loading: _vm.reserving,
                          disabled: _vm.reserving,
                        },
                        on: {
                          click: function ($event) {
                            return _vm.reserve(false)
                          },
                        },
                      },
                      [
                        _vm._v(
                          " Reserve " +
                            _vm._s(_vm.selected.length) +
                            " Driver" +
                            _vm._s(_vm.selected.length > 1 ? "s" : "") +
                            " " +
                            _vm._s(_vm.assistant ? "as Assistant" : "") +
                            " "
                        ),
                      ]
                    ),
                  ]
                : _vm._e(),
            ],
            2
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }