<template>
  <v-container fluid>
    <v-form class="full-width">
      <div>
        <v-row v-if="!usesAdultRoster">
          <v-col cols="12" md="4">
            <span v-if="!tripRequest.status || tripRequest.status == -1" class="red--text">
              <small>*Required</small>
            </span>

            <v-text-field
              ref="teacherName"
              v-model="tripRequest.teacherName"
              label="Teacher/Advisor/Staff Name"
              :rules="[() => !!tripRequest.teacherName || 'This field is required']"
              required
              :readonly="readonly && !tripRequest.permissions.canEditSiteAdmin"
            ></v-text-field>
          </v-col>

          <v-col cols="12" md="4">
            <span v-if="!tripRequest.status || tripRequest.status == -1" class="red--text">
              <small>*Required</small>
            </span>

            <phone-number
              ref="teacherPhone"
              v-model="tripRequest.teacherPhone"
              label="Teacher/Advisor/Staff Phone"
              :rules="[() => !!tripRequest.teacherPhone || 'This field is required']"
              required
              :readonly="readonly && !tripRequest.permissions.canEditSiteAdmin"
            ></phone-number>
          </v-col>

          <v-col cols="12" md="4">
            <!-- FOR SPACING -->
            <span v-if="!tripRequest.status || tripRequest.status == -1" class="white--text"><small>*</small></span>

            <v-text-field
              v-model="tripRequest.teacherEmail"
              label="Teacher/Advisor/Staff Email"
              :rules="rules.email"
              validate-on-blur
              :readonly="readonly && !tripRequest.permissions.canEditSiteAdmin"
            ></v-text-field>
          </v-col>

          <v-col cols="12" md="4" v-if="showEmergencyContact">
            <div class="d-flex flex-column align-center text-center">
              <div>Same as Teacher/Advisor/Staff</div>

              <div class="d-flex align-center justify-center">
                <span>No</span>
                <v-switch
                  class="ml-4"
                  v-model.number="tripRequest.emergencySameAsTeacher"
                  :true-value="1"
                  :false-value="0"
                  @change="sameSwitch"
                  inset
                  :readonly="readonly && !tripRequest.permissions.canEditSiteAdmin"
                ></v-switch>
                <span>Yes</span>
              </div>
            </div>
          </v-col>

          <v-col cols="12" md="4" v-if="showEmergencyContact">
            <span v-if="!tripRequest.status || tripRequest.status == -1" class="red--text">
              <small>*Required</small>
            </span>

            <v-text-field
              ref="emergencyContactName"
              v-model="tripRequest.emergencyContactName"
              label="Emergency Contact Name"
              :rules="[() => !!tripRequest.emergencyContactName || 'This field is required']"
              required
              :disabled="tripRequest.emergencySameAsTeacher == 1"
              :readonly="readonly && !tripRequest.permissions.canEditSiteAdmin"
            ></v-text-field>
          </v-col>

          <v-col cols="12" md="4" v-if="showEmergencyContact">
            <span v-if="!tripRequest.status || tripRequest.status == -1" class="red--text">
              <small>*Required</small>
            </span>

            <phone-number
              ref="emergencyContactPhone"
              v-model="tripRequest.emergencyContactPhone"
              label="Emergency Contact Phone"
              :rules="[() => !!tripRequest.emergencyContactPhone || 'This field is required']"
              required
              :disabled="tripRequest.emergencySameAsTeacher == 1"
              :readonly="readonly && !tripRequest.permissions.canEditSiteAdmin"
            ></phone-number>
          </v-col>
        </v-row>

        <v-row v-if="usesAdultRoster" class="mb-8">
          <v-col cols="12" md="4">
            <v-label>Adults Making Trip:</v-label>
          </v-col>
          <v-col cols="12" md="8">
            <v-row class="align-start">
              <v-col cols="12" md="8" class="elevation-1">
                <v-text-field
                  v-model="searchAdultRoster"
                  append-icon="mdi-magnify"
                  class="mt-0 pt-0"
                  label="Search"
                  single-line
                  hide-details
                  :readonly="readonly && !tripRequest.permissions.canEditSiteAdmin"
                ></v-text-field>
                <v-data-table
                  v-model="tripRequest.adultRoster"
                  :headers="adultRosterTableHeaders"
                  :items="adultRosterList"
                  item-key="id"
                  :search="searchAdultRoster"
                  :show-select="!showSelectedAdults"
                  :items-per-page="25"
                  height="530px"
                  :footer-props="{
                    itemsPerPageOptions: [25],
                  }"
                  :readonly="readonly && !tripRequest.permissions.canEditSiteAdmin"
                  @toggle-select-all="showSelectWholeTablePrompt($event, 'adult')"
                ></v-data-table>
              </v-col>
              <v-col cols="12" md="4">
                <v-select
                  label="School"
                  :value="schoolLocations[schoolId.name]"
                  v-model="adultSchoolId"
                  :items="[...schoolLocations, { id: 0, name: 'Roster Volunteer List' }]"
                  item-text="name"
                  item-value="id"
                  outlined
                  :readonly="readonly && !tripRequest.permissions.canEditSiteAdmin"
                  class="mb-0"
                  :disabled="showSelectedAdults"
                  v-on:input="setSchoolAdultList(adultSchoolId)"
                />
                <v-switch v-model="showSelectedAdults" :label="`Show Selected Adults`" />

                <v-checkbox
                  v-if="showSelectAllAdultsPrompt"
                  @click="addAlltoRoster('adult')"
                  :label="`You have currently selected ${tripRequest.adultRoster.length}/${adultRosterList.length} adults. Would you like to select All Adults?`"
                ></v-checkbox>

                <h3 class="mb-3">Totals:</h3>
                <v-simple-table dense class="mb-8">
                  <template #default>
                    <thead>
                      <tr>
                        <th class="text-left">Category</th>
                        <th class="text-left">Count</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td><strong>Total</strong></td>
                        <td>
                          <strong>{{ tripRequest.adultRoster ? tripRequest.adultRoster.length : 0 }}</strong>
                        </td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
                <v-autocomplete
                  v-model="adultRosterPrimary"
                  v-if="tripRequest.adultRoster?.length > 0"
                  :items="tripRequest.adultRoster.map((person) => person.name)"
                  outlined
                  label="Primary Contact"
                ></v-autocomplete>
                <phone-number
                  v-if="adultRosterPrimary"
                  ref="teacherPhone"
                  v-model="tripRequest.teacherPhone"
                  label="Primary Contact Phone"
                  :rules="[() => !!tripRequest.teacherPhone || 'This field is required']"
                  required
                  outlined
                  :readonly="readonly && !tripRequest.permissions.canEditSiteAdmin"
                ></phone-number>
                <v-text-field
                  ref="emergencyContactName"
                  v-model="tripRequest.emergencyContactName"
                  label="Emergency Contact Name"
                  outlined
                ></v-text-field>
                <phone-number
                  ref="emergencyContactPhone"
                  v-model="tripRequest.emergencyContactPhone"
                  label="Emergency Contact Phone"
                  :rules="[() => !!tripRequest.emergencyContactPhone || 'This field is required']"
                  required
                  :readonly="readonly && !tripRequest.permissions.canEditSiteAdmin"
                  outlined
                ></phone-number>
              </v-col>
            </v-row>
          </v-col>
        </v-row>

        <v-row
          class="align-center"
          v-show="
            tripRequestConfig.display.gradeLevel.includes(tripRequest.tripTypeId) &&
            tripRequest.category != tripCategory.STAFF_ONLY
          "
        >
          <v-col cols="12" md="4">
            <v-label>Grade Level(s) Making Trip:</v-label>
            <v-subheader v-if="!usesRoster">Must choose at least one</v-subheader>
          </v-col>

          <v-col cols="12" md="8">
            <span v-if="!tripRequest.status || tripRequest.status == -1" class="red--text">
              <small>*Required</small>
            </span>

            <v-chip-group
              ref="gradeLevels"
              v-model="tripRequest.gradeLevels"
              active-class="primary"
              column
              multiple
              :rules="[() => !!tripRequest.gradeLevels || 'This field is required']"
              required
            >
              <v-chip
                v-for="grade in grades"
                :key="grade"
                :value="grade"
                @click.native="clickGrade"
                :disabled="
                  (usesRoster && showSelectedStudents) || (readonly && !tripRequest.permissions.canEditSiteAdmin)
                "
              >
                {{ grade }}
              </v-chip>
            </v-chip-group>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12" md="4" v-if="tripRequest.category != tripCategory.STAFF_ONLY || !usesAdultRoster">
            <v-label><span v-if="!usesRoster">Number of </span>Individuals Making Trip:</v-label>
          </v-col>

          <v-col
            cols="12"
            md="10"
            v-if="
              !tripRequestConfig.display.splitAttendeesByGender && (!usesRoster || (usesRoster && !usesAdultRoster))
            "
          >
            <v-row dense>
              <v-col cols="12" md="4">
                <span v-if="!tripRequest.status || tripRequest.status == -1" class="red--text">
                  <small>*Required</small>
                </span>

                <v-text-field
                  ref="totalAdults"
                  :label="`Total ${adultsLabel}`"
                  v-model.number="tripRequest.totalAdults"
                  type="number"
                  inputmode="numeric"
                  pattern="[0-9]*"
                  min="0"
                  :rules="[() => tripRequest.totalAdults > 0 || 'This field is required']"
                  required
                  @input="checkChaperoneRules"
                  :readonly="
                    checkTripReadOnlyByPermissions({
                      permissions: tripRequest.permissions,
                      isAdmin: me.is.superAdmin || me.is.transportationAdmin || me.is.limitedAdmin,
                      isApproved: isCurrentTripRequestApproved,
                    })
                  "
                ></v-text-field>
              </v-col>

              <v-col cols="12" md="4" v-show="tripRequest.category != tripCategory.STAFF_ONLY && !usesRoster">
                <span v-if="!tripRequest.status || tripRequest.status == -1" class="red--text">
                  <small>*Required</small>
                </span>

                <v-text-field
                  ref="totalStudents"
                  :label="`Total ${tripRequestConfig.labels.numStudentsLabel}`"
                  v-model.number="tripRequest.totalStudents"
                  type="number"
                  inputmode="numeric"
                  pattern="[0-9]*"
                  min="0"
                  :rules="[() => tripRequest.totalStudents > 0 || 'This field is required']"
                  required
                  @input="checkChaperoneRules"
                  :readonly="
                    checkTripReadOnlyByPermissions({
                      permissions: tripRequest.permissions,
                      isAdmin: me.is.superAdmin || me.is.transportationAdmin || me.is.limitedAdmin,
                      isApproved: isCurrentTripRequestApproved,
                    })
                  "
                ></v-text-field>
              </v-col>

              <v-col
                cols="12"
                md="4"
                v-show="tripRequest.category != tripCategory.STAFF_ONLY"
                v-if="tripRequestConfig.display.numSpecialNeedsStudents"
              >
                <span v-if="!tripRequest.status || tripRequest.status == -1" class="white--text">
                  <small>*</small>
                </span>

                <v-text-field
                  :label="`Total ${tripRequestConfig.labels.numSpecialNeedsStudentsLabel}`"
                  v-model.number="tripRequest.numSpecialNeedsStudents"
                  type="number"
                  min="0"
                  :readonly="cannotModifyFields"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-col>

          <v-col cols="12" md="8" v-if="tripRequestConfig.display.splitAttendeesByGender && !usesRoster">
            <v-row dense v-show="tripRequest.category != tripCategory.STAFF_ONLY">
              <v-col cols="12" md="3">
                <span v-if="!tripRequest.status || tripRequest.status == -1" class="red--text">
                  <small>*Required</small>
                </span>

                <v-text-field
                  ref="numStudentsM"
                  :label="`${tripRequestConfig.labels.numStudentsLabel} - Male`"
                  v-model.number="numStudentsM"
                  type="number"
                  inputmode="numeric"
                  pattern="[0-9]*"
                  min="0"
                  :rules="[() => numStudentsM >= 0 || 'This field is required']"
                  required
                  @input="handleAttendeeInput"
                  :readonly="cannotModifyFields"
                ></v-text-field>
              </v-col>

              <v-col cols="12" md="3">
                <span v-if="!tripRequest.status || tripRequest.status == -1" class="red--text">
                  <small>*Required</small>
                </span>

                <v-text-field
                  ref="numStudentsF"
                  :label="`${tripRequestConfig.labels.numStudentsLabel} - Female`"
                  v-model.number="numStudentsF"
                  type="number"
                  inputmode="numeric"
                  pattern="[0-9]*"
                  min="0"
                  :rules="[() => numStudentsF >= 0 || 'This field is required']"
                  required
                  @input="handleAttendeeInput"
                  :readonly="cannotModifyFields"
                ></v-text-field>
              </v-col>

              <v-col cols="12" md="3" v-if="tripRequestConfig.display.nonBinary">
                <span v-if="!tripRequest.status || tripRequest.status == -1" class="red--text">
                  <small>*Required</small>
                </span>

                <v-text-field
                  ref="numStudentsO"
                  :label="`${tripRequestConfig.labels.numStudentsLabel} - Non-binary`"
                  v-model.number="numStudentsO"
                  type="number"
                  inputmode="numeric"
                  pattern="[0-9]*"
                  min="0"
                  :rules="[() => numStudentsO >= 0 || 'This field is required']"
                  required
                  @input="handleAttendeeInput"
                  :readonly="cannotModifyFields"
                ></v-text-field>
              </v-col>

              <v-col cols="12" md="3">
                <v-text-field
                  :label="`Total ${tripRequestConfig.labels.numStudentsLabel}`"
                  v-model.number="tripRequest.totalStudents"
                  type="number"
                  min="0"
                  readonly
                ></v-text-field>
              </v-col>

              <v-col cols="12" md="3">
                <v-text-field
                  :label="`Total ${tripRequestConfig.labels.numSpecialNeedsStudentsLabel}`"
                  v-model.number="tripRequest.numSpecialNeedsStudents"
                  type="number"
                  inputmode="numeric"
                  pattern="[0-9]*"
                  min="0"
                  :readonly="cannotModifyFields"
                ></v-text-field>
              </v-col>
            </v-row>

            <v-row dense>
              <v-col cols="12" md="3">
                <span v-if="!tripRequest.status || tripRequest.status == -1" class="red--text">
                  <small>*Required</small>
                </span>

                <v-text-field
                  ref="numAdultsM"
                  :label="`${adultsLabel} - Male`"
                  v-model.number="numAdultsM"
                  type="number"
                  inputmode="numeric"
                  pattern="[0-9]*"
                  min="0"
                  :rules="[() => numAdultsM >= 0 || 'This field is required']"
                  required
                  @input="handleAttendeeInput"
                  :readonly="cannotModifyFields"
                ></v-text-field>
              </v-col>

              <v-col cols="12" md="3">
                <span v-if="!tripRequest.status || tripRequest.status == -1" class="red--text">
                  <small>*Required</small>
                </span>
                <v-text-field
                  ref="numAdultsF"
                  :label="`${adultsLabel} - Female`"
                  v-model.number="numAdultsF"
                  type="number"
                  inputmode="numeric"
                  pattern="[0-9]*"
                  min="0"
                  :rules="[() => numAdultsF >= 0 || 'This field is required']"
                  required
                  @input="handleAttendeeInput"
                  :readonly="cannotModifyFields"
                ></v-text-field>
              </v-col>

              <v-col cols="12" md="3" v-if="tripRequestConfig.display.nonBinary">
                <span v-if="!tripRequest.status || tripRequest.status == -1" class="red--text">
                  <small>*Required</small>
                </span>

                <v-text-field
                  ref="numAdultsO"
                  :label="`${adultsLabel} - Non-binary`"
                  v-model.number="numAdultsO"
                  type="number"
                  inputmode="numeric"
                  pattern="[0-9]*"
                  min="0"
                  :rules="[() => numAdultsO >= 0 || 'This field is required']"
                  required
                  @input="handleAttendeeInput"
                  :readonly="cannotModifyFields"
                ></v-text-field>
              </v-col>

              <v-col cols="12" md="3">
                <v-text-field
                  :label="`Total ${adultsLabel}`"
                  v-model.number="tripRequest.totalAdults"
                  type="number"
                  min="0"
                  readonly
                ></v-text-field>
              </v-col>
            </v-row>
          </v-col>

          <v-col cols="12" md="8" v-show="tripRequest.category != tripCategory.STAFF_ONLY && usesRoster">
            <v-row>
              <v-col cols="12" md="8" class="elevation-1">
                <v-text-field
                  v-model="searchRoster"
                  append-icon="mdi-magnify"
                  class="mt-0 pt-0"
                  label="Search"
                  single-line
                  hide-details
                ></v-text-field>

                <v-data-table
                  v-model="tripRequest.roster"
                  :headers="studentRosterTableHeaders"
                  :items="studentRosterList"
                  item-key="id"
                  :search="searchRoster"
                  :show-select="!showSelectedStudents"
                  :items-per-page="25"
                  height="530px"
                  :footer-props="{
                    itemsPerPageOptions: [25],
                  }"
                  @toggle-select-all="showSelectWholeTablePrompt($event, 'student')"
                ></v-data-table>
              </v-col>

              <v-col cols="12" md="4">
                <v-select
                  label="School"
                  :value="schoolLocations[schoolId.name]"
                  v-model="schoolId"
                  :items="schoolLocations"
                  item-text="name"
                  item-value="id"
                  outlined
                  class="mb-0"
                  :disabled="showSelectedStudents"
                  v-on:input="setSchoolStudentList(schoolId)"
                />

                <v-switch v-model="showSelectedStudents" :label="`Show Selected Students`" />

                <v-checkbox
                  v-if="showSelectAllStudentsPrompt"
                  @click="addAlltoRoster('student')"
                  :label="`You have currently selected ${tripRequest.roster.length}/${studentRosterList.length} students. Would you like to select All Students?`"
                ></v-checkbox>

                <h3 class="mb-3">Totals:</h3>

                <v-simple-table dense>
                  <template #default>
                    <thead>
                      <tr>
                        <th class="text-left">Category</th>
                        <th class="text-left">Count</th>
                      </tr>
                    </thead>

                    <tbody>
                      <tr>
                        <td>Girls</td>
                        <td>
                          {{ rosterSelected('F') }}
                        </td>
                      </tr>

                      <tr>
                        <td>Boys</td>
                        <td>
                          {{ rosterSelected('M') }}
                        </td>
                      </tr>

                      <tr v-if="tripRequestConfig.display.nonBinary">
                        <td>Non-Binary</td>
                        <td>
                          {{ rosterSelected('O') }}
                        </td>
                      </tr>

                      <tr>
                        <td><strong>Total</strong></td>
                        <td>
                          <strong>{{ tripRequest.roster ? tripRequest.roster.length : 0 }}</strong>
                        </td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </v-col>
            </v-row>
          </v-col>
        </v-row>

        <v-row v-show="tripRequest.category != tripCategory.STAFF_ONLY">
          <v-spacer></v-spacer>

          <v-col cols="12" md="8">
            <v-alert v-if="chaperoneRules" outlined :type="chaperoneRulesFailed ? 'error' : 'info'" text>
              <p>
                Need {{ chaperoneRules.adultsPerStudents }} adult(s) for {{ chaperoneRules.numStudents }} or more
                students.
              </p>

              <p>
                Need {{ chaperoneRules.adultsPerAdditional }} adult(s) for every additional
                {{ chaperoneRules.additionalStudents }} students.
              </p>

              <p v-if="chaperoneRules.requireSameGender && tripRequestConfig.display.splitAttendeesByGender">
                At least one female chaperone for female students and at least one male chaperone for male students is
                required.
              </p>
            </v-alert>
          </v-col>
        </v-row>

        <v-row v-if="tripRequestConfig.display.groupDescription">
          <v-col cols="12" md="12">
            <v-textarea
              v-model="tripRequest.groupDescription"
              :label="tripRequestConfig.labels.groupDescription"
              dense
              outlined
              rows="3"
              auto-grow
              :readonly="readonly && !tripRequest.permissions.canEditSiteAdmin"
            ></v-textarea>
          </v-col>
        </v-row>

        <v-row v-show="tripRequestConfig.display.healthConcerns.includes(tripRequest.tripTypeId)">
          <v-col cols="12" md="2">
            <span
              v-if="
                (!tripRequest.status || tripRequest.status == -1) && tripRequestConfig.display.requireHealthConcerns
              "
              class="red--text"
            >
              <small>*Required</small>
            </span>

            <v-radio-group
              ref="healthConcerns"
              v-model="tripRequest.haveHealthConcerns"
              :readonly="cannotModifyFields"
              class="mt-0"
            >
              <template v-slot:label>
                <div>{{ tripRequestConfig.labels.healthConcerns }}</div>
              </template>

              <v-radio :value="1">
                <template v-slot:label>
                  <div><strong class="success--text">Yes</strong></div>
                </template>
              </v-radio>

              <v-radio :value="0">
                <template v-slot:label>
                  <div><strong class="primary--text">No</strong></div>
                </template>
              </v-radio>
            </v-radio-group>
          </v-col>

          <v-col
            cols="12"
            md="10"
            v-show="tripRequest.category != tripCategory.STAFF_ONLY && tripRequest.haveHealthConcerns == 1"
          >
            <v-textarea
              v-model="tripRequest.healthConcerns"
              :placeholder="tripRequestConfig.messages.healthConcerns"
              dense
              persistent-placeholder
              outlined
              rows="3"
              :readonly="cannotModifyFields"
            ></v-textarea>
          </v-col>
        </v-row>

        <v-row
          v-show="
            tripRequestConfig.display.awayForLunch.includes(tripRequest.tripTypeId) &&
            tripRequest.category != tripCategory.STAFF_ONLY
          "
        >
          <v-col cols="12" md="10">
            <v-label>Will the students be away from school during lunch?</v-label>
          </v-col>

          <v-col cols="12" md="2">
            <div class="d-flex align-center justify-center">
              <span>No</span>

              <v-switch
                class="ml-4"
                v-model.number="tripRequest.awayForLunch"
                :true-value="1"
                :false-value="0"
                inset
                :readonly="cannotModifyFields"
              ></v-switch>

              <span>Yes</span>
            </div>
          </v-col>
        </v-row>

        <v-row v-show="tripRequestConfig.display.packedLunch && tripRequest.awayForLunch == 1">
          <v-col cols="12" md="10">
            <v-label>If so, will these students need packed lunches?</v-label>
          </v-col>

          <v-col cols="12" md="2">
            <div class="d-flex align-center justify-center">
              <span>No</span>

              <v-switch
                class="ml-4"
                v-model.number="tripRequest.needLunch"
                :true-value="1"
                :false-value="0"
                inset
                :readonly="cannotModifyFields"
              ></v-switch>

              <span>Yes</span>
            </div>
          </v-col>
        </v-row>

        <v-row v-show="tripRequest.awayForLunch == 1 && tripRequest.needLunch == 1">
          <v-col cols="12" md="10">
            <v-label>{{ tripRequestConfig.messages.needLunch }}</v-label>
          </v-col>

          <v-col cols="12" md="12">
            <v-textarea
              :hint="tripRequestConfig.messages.packedLunch"
              :readonly="cannotModifyFields"
              dense
              outlined
              persistent-hint
              rows="5"
              v-model="tripRequest.foodAccomadations"
            ></v-textarea>
          </v-col>
        </v-row>

        <v-row v-if="customFormFields">
          <custom-form-field
            v-for="(cff, i) in customFormFields"
            :ref="cff.id"
            :key="i"
            :cff="cff"
            :value="tripRequest.customFormFields[cff.id]"
            :readonly="readonly"
            @handleCFFInput="$emit('handleCFFInput', { cffId: cff.id, value: $event })"
          ></custom-form-field>
        </v-row>
      </div>
    </v-form>
  </v-container>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import CustomFormField from './CustomFormField.vue';
import PhoneNumber from '@/components/PhoneNumber.vue';
import { GET_LOCATIONS } from '@/store/modules/Location/actions';
import { getStudentRoster, getAdultRoster } from '@/store/modules/Roster/actions';
import { GRADES, TRIP_CATEGORIES, TRIP_STATUS } from '@/shared/common';
import { readonly } from 'vue';
import { checkTripReadOnlyByPermissions } from '@/util';

export default {
  name: 'Attendees',
  inject: ['eventHub'],
  components: { CustomFormField, PhoneNumber },
  props: {
    tripRequest: Object,
    tripRequestConfig: Object,
    step: Number,
    complete: Boolean,
    customFormFields: Array,
    readonly: Boolean,
  },
  data() {
    return {
      tripCategory: TRIP_CATEGORIES,
      grades: ['All', ...GRADES],
      rules: {
        email: [(v) => (v && v.length ? /.+@.+\..+/.test(v) : true) || 'E-mail must be valid'],
      },
      chaperoneRulesFailed: false,
      numStudentsM: 0,
      numStudentsF: 0,
      numStudentsO: 0,
      numAdultsM: 0,
      numAdultsF: 0,
      numAdultsO: 0,
      studentRosterList: [],
      adultRosterList: [],
      searchRoster: '',
      searchAdultRoster: '',
      studentRosterTableHeaders: [
        { text: 'Name', value: 'name' },
        { text: 'Student ID', value: 'studentId' },
        { text: 'Grade', value: 'grade' },
        { text: 'Sex', value: 'sex' },
      ],
      adultRosterTableHeaders: [
        { text: 'Name', value: 'name' },
        { text: 'Email', value: 'email' },
      ],
      schoolId: 1,
      adultSchoolId: 1,
      schoolLocations: [],
      showSelectedStudents: false,
      showSelectedAdults: false,
      adultRosterPrimary: '',
      showSelectAllAdultsPrompt: false,
      showSelectAllStudentsPrompt: false,
    };
  },
  computed: {
    ...mapGetters('user', ['me']),
    ...mapGetters('app', ['clientConfig']),
    ...mapGetters('roster', ['studentRoster', 'adultRoster']),
    ...mapGetters('location', ['schools']),
    ...mapGetters('tripRequest', ['isCurrentTripRequestApproved']),
    chaperoneRules() {
      if (
        this.tripRequest &&
        this.tripRequest.gradeLevels &&
        this.tripRequestConfig &&
        this.tripRequestConfig.chaperoneRules &&
        this.tripRequestConfig.chaperoneRules.length
      ) {
        const gradeIndexes = [];
        const gradeLevels = this.tripRequest.gradeLevels.filter((e) => e != 'All');
        if (!gradeLevels.length) return null;
        gradeLevels.forEach((e) => gradeIndexes.push(this.grades.indexOf(e)));
        const lowestGrade = this.grades[Math.min(...gradeIndexes)];
        return this.tripRequestConfig.chaperoneRules.find((e) => e.gradeLevels.includes(lowestGrade)) || null;
      } else return null;
    },
    showEmergencyContact() {
      return this.tripRequestConfig.display.emergencyContact.includes(this.tripRequest.tripTypeId);
    },
    cannotModifyFields() {
      return (
        (this.readonly && !this.tripRequest.permissions.canEditSiteAdmin) ||
        ((!this.tripRequest.permissions.canEditPreApproval ||
          !this.tripRequest.permissions.canEditMidApproval ||
          !this.tripRequest.permissions.canEditApproved) &&
          !Number.isInteger(this.step) &&
          this.tripRequest.status !== TRIP_STATUS.DRAFT)
      );
    },
    adultsLabel() {
      return this.tripRequest.tripTypeId == 4
        ? this.tripRequestConfig.labels.numAdultsLabelStaffType
        : this.tripRequestConfig.labels.numAdultsLabel;
    },
    usesRoster() {
      const isEliteTier = this.clientConfig.data.modules.tier === 'elite';
      const usesRoster = this.clientConfig.data.modules.roster.sync;
      return isEliteTier && usesRoster;
    },
    usesAdultRoster() {
      const isEliteTier = this.clientConfig.data.modules.tier === 'elite';
      const usesAdultRoster = this.clientConfig.data.modules.roster.adultRoster;
      return isEliteTier && usesAdultRoster;
    },
  },
  created() {
    this.eventHub.$on('validateStepAttendees', (reset) => this.validate(reset));
  },
  async mounted() {
    if (this.usesRoster) {
      await this.getLocations();
      this.schoolLocations = this.schools;
    }

    if (this.tripRequest.locationId && this.usesRoster && !this.step) {
      this.schoolId = this.tripRequest.locationId;
      await this.setSchoolStudentList(this.tripRequest.locationId);
      if (this.usesAdultRoster) {
        await this.setSchoolAdultList(this.tripRequest.locationId);
        this.adultRosterPrimary = this.tripRequest.teacherName;
      }
    }
  },
  beforeDestroy() {
    this.eventHub.$off('validateStepAttendees');
  },
  methods: {
    ...mapActions('roster', [getStudentRoster, getAdultRoster]),
    ...mapActions('location', [GET_LOCATIONS]),
    checkTripReadOnlyByPermissions,
    clickGrade(event) {
      if (event.target.innerText == 'All') {
        if (this.tripRequest.gradeLevels.indexOf('All') == -1) this.tripRequest.gradeLevels = [];
        else {
          this.tripRequest.gradeLevels = [];
          this.grades.forEach((e) => this.tripRequest.gradeLevels.push(e));
        }
      }
      this.checkChaperoneRules();
    },
    sameSwitch() {
      if (this.tripRequest.emergencySameAsTeacher) {
        this.tripRequest.emergencyContactName = new String(this.tripRequest.teacherName) || '';
        this.tripRequest.emergencyContactPhone = new String(this.tripRequest.teacherPhone) || '';
      }
    },
    handleAttendeeInput() {
      this.tripRequest.totalStudents = this.numStudentsM + this.numStudentsF + this.numStudentsO;
      this.tripRequest.studentsByGender = [this.numStudentsM, this.numStudentsF, this.numStudentsO];
      this.tripRequest.totalAdults = this.numAdultsM + this.numAdultsF + this.numAdultsO;
      this.tripRequest.adultsByGender = [this.numAdultsM, this.numAdultsF, this.numAdultsO];
      this.checkChaperoneRules();
    },
    checkChaperoneRules() {
      if (
        this.chaperoneRules &&
        this.chaperoneRules.adultsPerStudents &&
        this.tripRequest.totalStudents >= this.chaperoneRules.numStudents
      ) {
        if (
          this.tripRequest.totalStudents >=
          Number(this.chaperoneRules.numStudents) + Number(this.chaperoneRules.additionalStudents)
        ) {
          const additionalAdultsNeeded =
            Math.floor(
              (this.tripRequest.totalStudents - Number(this.chaperoneRules.numStudents)) /
                Number(this.chaperoneRules.additionalStudents)
            ) * Number(this.chaperoneRules.adultsPerAdditional);
          if (this.tripRequest.totalAdults < Number(this.chaperoneRules.adultsPerStudents) + additionalAdultsNeeded) {
            this.chaperoneRulesFailed = true;
            this.$emit('preventSubmit', true);
          } else {
            this.chaperoneRulesFailed = this.checkChaperoneGenderRules();
            this.$emit('preventSubmit', this.chaperoneRulesFailed);
          }
        } else if (this.tripRequest.totalAdults < this.chaperoneRules.adultsPerStudents) {
          this.chaperoneRulesFailed = true;
          this.$emit('preventSubmit', true);
        } else {
          this.chaperoneRulesFailed = this.checkChaperoneGenderRules();
          this.$emit('preventSubmit', this.chaperoneRulesFailed);
        }
      }
      if (!this.chaperoneRulesFailed) {
        this.chaperoneRulesFailed = this.checkChaperoneGenderRules();
        this.$emit('preventSubmit', this.chaperoneRulesFailed);
      }
    },
    checkChaperoneGenderRules() {
      if (
        this.tripRequestConfig.display.splitAttendeesByGender &&
        this.chaperoneRules &&
        this.chaperoneRules.requireSameGender &&
        ((this.numStudentsM > 0 && this.numAdultsM < 1) || (this.numStudentsF > 0 && this.numAdultsF < 1))
      )
        return true;
      else return false;
    },
    validate(reset) {
      let required = [
        'teacherName',
        'teacherPhone',
        // 'gradeLevels',
      ];
      if (this.showEmergencyContact) required = required.concat(['emergencyContactName', 'emergencyContactPhone']);
      required = required.concat(
        this.tripRequestConfig.display.splitAttendeesByGender
          ? ['numStudentsM', 'numStudentsF', 'numStudentsO', 'numAdultsM', 'numAdultsF', 'numAdultsO']
          : ['totalAdults', 'totalStudents']
      );
      // if (this.tripRequestConfig.display.requireHealthConcerns) required.push('healthConcerns');
      if (!reset) {
        required.forEach((e) => this.$refs[e]?.validate(true));
        if (this.customFormFields)
          this.customFormFields.forEach((e) => {
            if (e.required) this.$refs[e.id][0].cffValidation();
          });
      } else {
        required.forEach((e) => this.$refs[e]?.resetValidation());
        if (this.customFormFields)
          this.customFormFields.forEach((e) => {
            if (e.required) this.$refs[e.id][0].cffValidation(true);
          });
      }
    },
    rosterSelected(sex) {
      if (!this.tripRequest.roster) return 0;
      let studentCount = this.tripRequest.roster.length
        ? this.tripRequest.roster.filter((student) => student.sex === sex).length
        : 0;

      if (this.tripRequestConfig.display.splitAttendeesByGender) {
        switch (sex) {
          case 'M':
            this.numStudentsM = studentCount;
            break;
          case 'F':
            this.numStudentsF = studentCount;
            break;
          case 'N':
            this.numStudentsO = studentCount;
            break;
        }
      } else {
        this.numStudents = this.tripRequest.roster.length;
      }
      this.tripRequest.totalStudents = this.tripRequest.roster.length;
      return studentCount;
    },
    showSelectWholeTablePrompt(selection, table) {
      if (table === 'student' && this.studentRosterList.length > 25)
        return (this.showSelectAllStudentsPrompt = selection.value);

      if (table === 'adult' && this.adultRosterList.length > 25)
        return (this.showSelectAllAdultsPrompt = selection.value);
    },
    addAlltoRoster(table) {
      if (table === 'student') {
        this.tripRequest.roster = this.studentRosterList;
        this.showSelectAllStudentsPrompt = false;
      }
      if (table === 'adult') {
        this.tripRequest.adultRoster = this.adultRosterList;
        this.showSelectAllAdultsPrompt = false;
      }
    },
    async setSchoolStudentList(locationId) {
      await this.getStudentRoster({ location: locationId });
      if (this.tripRequestConfig.display.gradeLevel.includes(this.tripRequest.tripTypeId))
        this.studentRosterList = this.filterStudentsByGrade();
      else this.studentRosterList = this.studentRoster;
    },
    async setSchoolAdultList(locationId) {
      await this.getAdultRoster({ location: locationId });
      this.adultRosterList = this.adultRoster;
    },
    filterStudentsByGrade() {
      return this.studentRoster.filter((student) => {
        if (!student.grade) return false;
        return (
          this.tripRequest.gradeLevels.includes(student.grade) ||
          this.tripRequest.gradeLevels.includes(parseInt(student.grade).toString())
        );
      });
    },
  },
  watch: {
    tripRequest: {
      immediate: true,
      deep: true,
      handler: async function (value) {
        if (value.studentsByGender && value.studentsByGender.length) {
          this.numStudentsM = value.studentsByGender[0];
          this.numStudentsF = value.studentsByGender[1];
          this.numStudentsO = value.studentsByGender[2];
        }
        if (value.adultsByGender && value.adultsByGender.length) {
          this.numAdultsM = value.adultsByGender[0];
          this.numAdultsF = value.adultsByGender[1];
          this.numAdultsO = value.adultsByGender[2];
        }
        if (value.gradeLevels && value.gradeLevels.length == this.grades.length - 1) {
          if (value.gradeLevels.includes('All'))
            return (value.gradeLevels = value.gradeLevels.filter((grade) => grade !== 'All'));
          if (!value.gradeLevels.includes('All')) return value.gradeLevels.push('All');
        }
        if (
          this.usesRoster &&
          this.studentRoster.length &&
          this.tripRequestConfig.display.gradeLevel.includes(this.tripRequest.tripTypeId)
        ) {
          this.studentRosterList = this.filterStudentsByGrade();
        }
        if (this.usesAdultRoster) {
          this.tripRequest.totalAdults = this.tripRequest.adultRoster?.length;
          if (Array.isArray(this.tripRequest.adultRoster) && this.tripRequest.adultRoster.length === 1)
            this.adultRosterPrimary = this.tripRequest.adultRoster[0].name;
        }
      },
    },
    step: {
      immediate: true,
      handler: async function () {
        if (this.step === 4 && this.usesRoster) {
          await this.getStudentRoster({ location: this.tripRequest.locationId });
          this.studentRosterList = this.studentRoster;
          if (this.tripRequestConfig.display.gradeLevel.includes(this.tripRequest.tripTypeId))
            this.tripRequest.gradeLevels = this.grades;
        }
        if (this.step === 4 && this.usesAdultRoster) {
          await this.setSchoolAdultList(this.tripRequest.locationId);
        }
      },
    },
    showSelectedStudents: {
      handler: function () {
        if (this.showSelectedStudents) this.studentRosterList = this.tripRequest.roster;
        else this.studentRosterList = this.studentRoster;
      },
    },
    showSelectedAdults: {
      handler: function () {
        if (this.showSelectedAdults) this.adultRosterList = this.tripRequest.adultRoster;
        else this.adultRosterList = this.adultRoster;
      },
    },
    adultRosterPrimary: {
      handler: function () {
        const person = this.tripRequest.adultRoster.find((person) => person.name === this.adultRosterPrimary);
        this.tripRequest.teacherName = person.name;
        this.tripRequest.teacherEmail = person.email;
      },
    },
  },
};
</script>

<style scoped>
.full-width {
  width: 100%;
}
</style>
