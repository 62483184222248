var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    { attrs: { fluid: "" } },
    [
      _c("v-form", { staticClass: "full-width" }, [
        _c(
          "div",
          [
            !_vm.usesAdultRoster
              ? _c(
                  "v-row",
                  [
                    _c(
                      "v-col",
                      { attrs: { cols: "12", md: "4" } },
                      [
                        !_vm.tripRequest.status || _vm.tripRequest.status == -1
                          ? _c("span", { staticClass: "red--text" }, [
                              _c("small", [_vm._v("*Required")]),
                            ])
                          : _vm._e(),
                        _c("v-text-field", {
                          ref: "teacherName",
                          attrs: {
                            label: "Teacher/Advisor/Staff Name",
                            rules: [
                              () =>
                                !!_vm.tripRequest.teacherName ||
                                "This field is required",
                            ],
                            required: "",
                            readonly:
                              _vm.readonly &&
                              !_vm.tripRequest.permissions.canEditSiteAdmin,
                          },
                          model: {
                            value: _vm.tripRequest.teacherName,
                            callback: function ($$v) {
                              _vm.$set(_vm.tripRequest, "teacherName", $$v)
                            },
                            expression: "tripRequest.teacherName",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "v-col",
                      { attrs: { cols: "12", md: "4" } },
                      [
                        !_vm.tripRequest.status || _vm.tripRequest.status == -1
                          ? _c("span", { staticClass: "red--text" }, [
                              _c("small", [_vm._v("*Required")]),
                            ])
                          : _vm._e(),
                        _c("phone-number", {
                          ref: "teacherPhone",
                          attrs: {
                            label: "Teacher/Advisor/Staff Phone",
                            rules: [
                              () =>
                                !!_vm.tripRequest.teacherPhone ||
                                "This field is required",
                            ],
                            required: "",
                            readonly:
                              _vm.readonly &&
                              !_vm.tripRequest.permissions.canEditSiteAdmin,
                          },
                          model: {
                            value: _vm.tripRequest.teacherPhone,
                            callback: function ($$v) {
                              _vm.$set(_vm.tripRequest, "teacherPhone", $$v)
                            },
                            expression: "tripRequest.teacherPhone",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "v-col",
                      { attrs: { cols: "12", md: "4" } },
                      [
                        !_vm.tripRequest.status || _vm.tripRequest.status == -1
                          ? _c("span", { staticClass: "white--text" }, [
                              _c("small", [_vm._v("*")]),
                            ])
                          : _vm._e(),
                        _c("v-text-field", {
                          attrs: {
                            label: "Teacher/Advisor/Staff Email",
                            rules: _vm.rules.email,
                            "validate-on-blur": "",
                            readonly:
                              _vm.readonly &&
                              !_vm.tripRequest.permissions.canEditSiteAdmin,
                          },
                          model: {
                            value: _vm.tripRequest.teacherEmail,
                            callback: function ($$v) {
                              _vm.$set(_vm.tripRequest, "teacherEmail", $$v)
                            },
                            expression: "tripRequest.teacherEmail",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm.showEmergencyContact
                      ? _c("v-col", { attrs: { cols: "12", md: "4" } }, [
                          _c(
                            "div",
                            {
                              staticClass:
                                "d-flex flex-column align-center text-center",
                            },
                            [
                              _c("div", [
                                _vm._v("Same as Teacher/Advisor/Staff"),
                              ]),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "d-flex align-center justify-center",
                                },
                                [
                                  _c("span", [_vm._v("No")]),
                                  _c("v-switch", {
                                    staticClass: "ml-4",
                                    attrs: {
                                      "true-value": 1,
                                      "false-value": 0,
                                      inset: "",
                                      readonly:
                                        _vm.readonly &&
                                        !_vm.tripRequest.permissions
                                          .canEditSiteAdmin,
                                    },
                                    on: { change: _vm.sameSwitch },
                                    model: {
                                      value:
                                        _vm.tripRequest.emergencySameAsTeacher,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.tripRequest,
                                          "emergencySameAsTeacher",
                                          _vm._n($$v)
                                        )
                                      },
                                      expression:
                                        "tripRequest.emergencySameAsTeacher",
                                    },
                                  }),
                                  _c("span", [_vm._v("Yes")]),
                                ],
                                1
                              ),
                            ]
                          ),
                        ])
                      : _vm._e(),
                    _vm.showEmergencyContact
                      ? _c(
                          "v-col",
                          { attrs: { cols: "12", md: "4" } },
                          [
                            !_vm.tripRequest.status ||
                            _vm.tripRequest.status == -1
                              ? _c("span", { staticClass: "red--text" }, [
                                  _c("small", [_vm._v("*Required")]),
                                ])
                              : _vm._e(),
                            _c("v-text-field", {
                              ref: "emergencyContactName",
                              attrs: {
                                label: "Emergency Contact Name",
                                rules: [
                                  () =>
                                    !!_vm.tripRequest.emergencyContactName ||
                                    "This field is required",
                                ],
                                required: "",
                                disabled:
                                  _vm.tripRequest.emergencySameAsTeacher == 1,
                                readonly:
                                  _vm.readonly &&
                                  !_vm.tripRequest.permissions.canEditSiteAdmin,
                              },
                              model: {
                                value: _vm.tripRequest.emergencyContactName,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.tripRequest,
                                    "emergencyContactName",
                                    $$v
                                  )
                                },
                                expression: "tripRequest.emergencyContactName",
                              },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.showEmergencyContact
                      ? _c(
                          "v-col",
                          { attrs: { cols: "12", md: "4" } },
                          [
                            !_vm.tripRequest.status ||
                            _vm.tripRequest.status == -1
                              ? _c("span", { staticClass: "red--text" }, [
                                  _c("small", [_vm._v("*Required")]),
                                ])
                              : _vm._e(),
                            _c("phone-number", {
                              ref: "emergencyContactPhone",
                              attrs: {
                                label: "Emergency Contact Phone",
                                rules: [
                                  () =>
                                    !!_vm.tripRequest.emergencyContactPhone ||
                                    "This field is required",
                                ],
                                required: "",
                                disabled:
                                  _vm.tripRequest.emergencySameAsTeacher == 1,
                                readonly:
                                  _vm.readonly &&
                                  !_vm.tripRequest.permissions.canEditSiteAdmin,
                              },
                              model: {
                                value: _vm.tripRequest.emergencyContactPhone,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.tripRequest,
                                    "emergencyContactPhone",
                                    $$v
                                  )
                                },
                                expression: "tripRequest.emergencyContactPhone",
                              },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                  ],
                  1
                )
              : _vm._e(),
            _vm.usesAdultRoster
              ? _c(
                  "v-row",
                  { staticClass: "mb-8" },
                  [
                    _c(
                      "v-col",
                      { attrs: { cols: "12", md: "4" } },
                      [_c("v-label", [_vm._v("Adults Making Trip:")])],
                      1
                    ),
                    _c(
                      "v-col",
                      { attrs: { cols: "12", md: "8" } },
                      [
                        _c(
                          "v-row",
                          { staticClass: "align-start" },
                          [
                            _c(
                              "v-col",
                              {
                                staticClass: "elevation-1",
                                attrs: { cols: "12", md: "8" },
                              },
                              [
                                _c("v-text-field", {
                                  staticClass: "mt-0 pt-0",
                                  attrs: {
                                    "append-icon": "mdi-magnify",
                                    label: "Search",
                                    "single-line": "",
                                    "hide-details": "",
                                    readonly:
                                      _vm.readonly &&
                                      !_vm.tripRequest.permissions
                                        .canEditSiteAdmin,
                                  },
                                  model: {
                                    value: _vm.searchAdultRoster,
                                    callback: function ($$v) {
                                      _vm.searchAdultRoster = $$v
                                    },
                                    expression: "searchAdultRoster",
                                  },
                                }),
                                _c("v-data-table", {
                                  attrs: {
                                    headers: _vm.adultRosterTableHeaders,
                                    items: _vm.adultRosterList,
                                    "item-key": "id",
                                    search: _vm.searchAdultRoster,
                                    "show-select": !_vm.showSelectedAdults,
                                    "items-per-page": 25,
                                    height: "530px",
                                    "footer-props": {
                                      itemsPerPageOptions: [25],
                                    },
                                    readonly:
                                      _vm.readonly &&
                                      !_vm.tripRequest.permissions
                                        .canEditSiteAdmin,
                                  },
                                  on: {
                                    "toggle-select-all": function ($event) {
                                      return _vm.showSelectWholeTablePrompt(
                                        $event,
                                        "adult"
                                      )
                                    },
                                  },
                                  model: {
                                    value: _vm.tripRequest.adultRoster,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.tripRequest,
                                        "adultRoster",
                                        $$v
                                      )
                                    },
                                    expression: "tripRequest.adultRoster",
                                  },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "v-col",
                              { attrs: { cols: "12", md: "4" } },
                              [
                                _c("v-select", {
                                  staticClass: "mb-0",
                                  attrs: {
                                    label: "School",
                                    value:
                                      _vm.schoolLocations[_vm.schoolId.name],
                                    items: [
                                      ..._vm.schoolLocations,
                                      { id: 0, name: "Roster Volunteer List" },
                                    ],
                                    "item-text": "name",
                                    "item-value": "id",
                                    outlined: "",
                                    readonly:
                                      _vm.readonly &&
                                      !_vm.tripRequest.permissions
                                        .canEditSiteAdmin,
                                    disabled: _vm.showSelectedAdults,
                                  },
                                  on: {
                                    input: function ($event) {
                                      return _vm.setSchoolAdultList(
                                        _vm.adultSchoolId
                                      )
                                    },
                                  },
                                  model: {
                                    value: _vm.adultSchoolId,
                                    callback: function ($$v) {
                                      _vm.adultSchoolId = $$v
                                    },
                                    expression: "adultSchoolId",
                                  },
                                }),
                                _c("v-switch", {
                                  attrs: { label: `Show Selected Adults` },
                                  model: {
                                    value: _vm.showSelectedAdults,
                                    callback: function ($$v) {
                                      _vm.showSelectedAdults = $$v
                                    },
                                    expression: "showSelectedAdults",
                                  },
                                }),
                                _vm.showSelectAllAdultsPrompt
                                  ? _c("v-checkbox", {
                                      attrs: {
                                        label: `You have currently selected ${_vm.tripRequest.adultRoster.length}/${_vm.adultRosterList.length} adults. Would you like to select All Adults?`,
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.addAlltoRoster("adult")
                                        },
                                      },
                                    })
                                  : _vm._e(),
                                _c("h3", { staticClass: "mb-3" }, [
                                  _vm._v("Totals:"),
                                ]),
                                _c("v-simple-table", {
                                  staticClass: "mb-8",
                                  attrs: { dense: "" },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "default",
                                        fn: function () {
                                          return [
                                            _c("thead", [
                                              _c("tr", [
                                                _c(
                                                  "th",
                                                  { staticClass: "text-left" },
                                                  [_vm._v("Category")]
                                                ),
                                                _c(
                                                  "th",
                                                  { staticClass: "text-left" },
                                                  [_vm._v("Count")]
                                                ),
                                              ]),
                                            ]),
                                            _c("tbody", [
                                              _c("tr", [
                                                _c("td", [
                                                  _c("strong", [
                                                    _vm._v("Total"),
                                                  ]),
                                                ]),
                                                _c("td", [
                                                  _c("strong", [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.tripRequest
                                                          .adultRoster
                                                          ? _vm.tripRequest
                                                              .adultRoster
                                                              .length
                                                          : 0
                                                      )
                                                    ),
                                                  ]),
                                                ]),
                                              ]),
                                            ]),
                                          ]
                                        },
                                        proxy: true,
                                      },
                                    ],
                                    null,
                                    false,
                                    1588650865
                                  ),
                                }),
                                _vm.tripRequest.adultRoster?.length > 0
                                  ? _c("v-autocomplete", {
                                      attrs: {
                                        items: _vm.tripRequest.adultRoster.map(
                                          (person) => person.name
                                        ),
                                        outlined: "",
                                        label: "Primary Contact",
                                      },
                                      model: {
                                        value: _vm.adultRosterPrimary,
                                        callback: function ($$v) {
                                          _vm.adultRosterPrimary = $$v
                                        },
                                        expression: "adultRosterPrimary",
                                      },
                                    })
                                  : _vm._e(),
                                _vm.adultRosterPrimary
                                  ? _c("phone-number", {
                                      ref: "teacherPhone",
                                      attrs: {
                                        label: "Primary Contact Phone",
                                        rules: [
                                          () =>
                                            !!_vm.tripRequest.teacherPhone ||
                                            "This field is required",
                                        ],
                                        required: "",
                                        outlined: "",
                                        readonly:
                                          _vm.readonly &&
                                          !_vm.tripRequest.permissions
                                            .canEditSiteAdmin,
                                      },
                                      model: {
                                        value: _vm.tripRequest.teacherPhone,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.tripRequest,
                                            "teacherPhone",
                                            $$v
                                          )
                                        },
                                        expression: "tripRequest.teacherPhone",
                                      },
                                    })
                                  : _vm._e(),
                                _c("v-text-field", {
                                  ref: "emergencyContactName",
                                  attrs: {
                                    label: "Emergency Contact Name",
                                    outlined: "",
                                  },
                                  model: {
                                    value: _vm.tripRequest.emergencyContactName,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.tripRequest,
                                        "emergencyContactName",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "tripRequest.emergencyContactName",
                                  },
                                }),
                                _c("phone-number", {
                                  ref: "emergencyContactPhone",
                                  attrs: {
                                    label: "Emergency Contact Phone",
                                    rules: [
                                      () =>
                                        !!_vm.tripRequest
                                          .emergencyContactPhone ||
                                        "This field is required",
                                    ],
                                    required: "",
                                    readonly:
                                      _vm.readonly &&
                                      !_vm.tripRequest.permissions
                                        .canEditSiteAdmin,
                                    outlined: "",
                                  },
                                  model: {
                                    value:
                                      _vm.tripRequest.emergencyContactPhone,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.tripRequest,
                                        "emergencyContactPhone",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "tripRequest.emergencyContactPhone",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                )
              : _vm._e(),
            _c(
              "v-row",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value:
                      _vm.tripRequestConfig.display.gradeLevel.includes(
                        _vm.tripRequest.tripTypeId
                      ) &&
                      _vm.tripRequest.category != _vm.tripCategory.STAFF_ONLY,
                    expression:
                      "\n          tripRequestConfig.display.gradeLevel.includes(tripRequest.tripTypeId) &&\n          tripRequest.category != tripCategory.STAFF_ONLY\n        ",
                  },
                ],
                staticClass: "align-center",
              },
              [
                _c(
                  "v-col",
                  { attrs: { cols: "12", md: "4" } },
                  [
                    _c("v-label", [_vm._v("Grade Level(s) Making Trip:")]),
                    !_vm.usesRoster
                      ? _c("v-subheader", [_vm._v("Must choose at least one")])
                      : _vm._e(),
                  ],
                  1
                ),
                _c(
                  "v-col",
                  { attrs: { cols: "12", md: "8" } },
                  [
                    !_vm.tripRequest.status || _vm.tripRequest.status == -1
                      ? _c("span", { staticClass: "red--text" }, [
                          _c("small", [_vm._v("*Required")]),
                        ])
                      : _vm._e(),
                    _c(
                      "v-chip-group",
                      {
                        ref: "gradeLevels",
                        attrs: {
                          "active-class": "primary",
                          column: "",
                          multiple: "",
                          rules: [
                            () =>
                              !!_vm.tripRequest.gradeLevels ||
                              "This field is required",
                          ],
                          required: "",
                        },
                        model: {
                          value: _vm.tripRequest.gradeLevels,
                          callback: function ($$v) {
                            _vm.$set(_vm.tripRequest, "gradeLevels", $$v)
                          },
                          expression: "tripRequest.gradeLevels",
                        },
                      },
                      _vm._l(_vm.grades, function (grade) {
                        return _c(
                          "v-chip",
                          {
                            key: grade,
                            attrs: {
                              value: grade,
                              disabled:
                                (_vm.usesRoster && _vm.showSelectedStudents) ||
                                (_vm.readonly &&
                                  !_vm.tripRequest.permissions
                                    .canEditSiteAdmin),
                            },
                            nativeOn: {
                              click: function ($event) {
                                return _vm.clickGrade.apply(null, arguments)
                              },
                            },
                          },
                          [_vm._v(" " + _vm._s(grade) + " ")]
                        )
                      }),
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
            _c(
              "v-row",
              [
                _vm.tripRequest.category != _vm.tripCategory.STAFF_ONLY ||
                !_vm.usesAdultRoster
                  ? _c(
                      "v-col",
                      { attrs: { cols: "12", md: "4" } },
                      [
                        _c("v-label", [
                          !_vm.usesRoster
                            ? _c("span", [_vm._v("Number of ")])
                            : _vm._e(),
                          _vm._v("Individuals Making Trip:"),
                        ]),
                      ],
                      1
                    )
                  : _vm._e(),
                !_vm.tripRequestConfig.display.splitAttendeesByGender &&
                (!_vm.usesRoster || (_vm.usesRoster && !_vm.usesAdultRoster))
                  ? _c(
                      "v-col",
                      { attrs: { cols: "12", md: "10" } },
                      [
                        _c(
                          "v-row",
                          { attrs: { dense: "" } },
                          [
                            _c(
                              "v-col",
                              { attrs: { cols: "12", md: "4" } },
                              [
                                !_vm.tripRequest.status ||
                                _vm.tripRequest.status == -1
                                  ? _c("span", { staticClass: "red--text" }, [
                                      _c("small", [_vm._v("*Required")]),
                                    ])
                                  : _vm._e(),
                                _c("v-text-field", {
                                  ref: "totalAdults",
                                  attrs: {
                                    label: `Total ${_vm.adultsLabel}`,
                                    type: "number",
                                    inputmode: "numeric",
                                    pattern: "[0-9]*",
                                    min: "0",
                                    rules: [
                                      () =>
                                        _vm.tripRequest.totalAdults > 0 ||
                                        "This field is required",
                                    ],
                                    required: "",
                                    readonly:
                                      _vm.checkTripReadOnlyByPermissions({
                                        permissions:
                                          _vm.tripRequest.permissions,
                                        isAdmin:
                                          _vm.me.is.superAdmin ||
                                          _vm.me.is.transportationAdmin ||
                                          _vm.me.is.limitedAdmin,
                                        isApproved:
                                          _vm.isCurrentTripRequestApproved,
                                      }),
                                  },
                                  on: { input: _vm.checkChaperoneRules },
                                  model: {
                                    value: _vm.tripRequest.totalAdults,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.tripRequest,
                                        "totalAdults",
                                        _vm._n($$v)
                                      )
                                    },
                                    expression: "tripRequest.totalAdults",
                                  },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "v-col",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value:
                                      _vm.tripRequest.category !=
                                        _vm.tripCategory.STAFF_ONLY &&
                                      !_vm.usesRoster,
                                    expression:
                                      "tripRequest.category != tripCategory.STAFF_ONLY && !usesRoster",
                                  },
                                ],
                                attrs: { cols: "12", md: "4" },
                              },
                              [
                                !_vm.tripRequest.status ||
                                _vm.tripRequest.status == -1
                                  ? _c("span", { staticClass: "red--text" }, [
                                      _c("small", [_vm._v("*Required")]),
                                    ])
                                  : _vm._e(),
                                _c("v-text-field", {
                                  ref: "totalStudents",
                                  attrs: {
                                    label: `Total ${_vm.tripRequestConfig.labels.numStudentsLabel}`,
                                    type: "number",
                                    inputmode: "numeric",
                                    pattern: "[0-9]*",
                                    min: "0",
                                    rules: [
                                      () =>
                                        _vm.tripRequest.totalStudents > 0 ||
                                        "This field is required",
                                    ],
                                    required: "",
                                    readonly:
                                      _vm.checkTripReadOnlyByPermissions({
                                        permissions:
                                          _vm.tripRequest.permissions,
                                        isAdmin:
                                          _vm.me.is.superAdmin ||
                                          _vm.me.is.transportationAdmin ||
                                          _vm.me.is.limitedAdmin,
                                        isApproved:
                                          _vm.isCurrentTripRequestApproved,
                                      }),
                                  },
                                  on: { input: _vm.checkChaperoneRules },
                                  model: {
                                    value: _vm.tripRequest.totalStudents,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.tripRequest,
                                        "totalStudents",
                                        _vm._n($$v)
                                      )
                                    },
                                    expression: "tripRequest.totalStudents",
                                  },
                                }),
                              ],
                              1
                            ),
                            _vm.tripRequestConfig.display
                              .numSpecialNeedsStudents
                              ? _c(
                                  "v-col",
                                  {
                                    directives: [
                                      {
                                        name: "show",
                                        rawName: "v-show",
                                        value:
                                          _vm.tripRequest.category !=
                                          _vm.tripCategory.STAFF_ONLY,
                                        expression:
                                          "tripRequest.category != tripCategory.STAFF_ONLY",
                                      },
                                    ],
                                    attrs: { cols: "12", md: "4" },
                                  },
                                  [
                                    !_vm.tripRequest.status ||
                                    _vm.tripRequest.status == -1
                                      ? _c(
                                          "span",
                                          { staticClass: "white--text" },
                                          [_c("small", [_vm._v("*")])]
                                        )
                                      : _vm._e(),
                                    _c("v-text-field", {
                                      attrs: {
                                        label: `Total ${_vm.tripRequestConfig.labels.numSpecialNeedsStudentsLabel}`,
                                        type: "number",
                                        min: "0",
                                        readonly: _vm.cannotModifyFields,
                                      },
                                      model: {
                                        value:
                                          _vm.tripRequest
                                            .numSpecialNeedsStudents,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.tripRequest,
                                            "numSpecialNeedsStudents",
                                            _vm._n($$v)
                                          )
                                        },
                                        expression:
                                          "tripRequest.numSpecialNeedsStudents",
                                      },
                                    }),
                                  ],
                                  1
                                )
                              : _vm._e(),
                          ],
                          1
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.tripRequestConfig.display.splitAttendeesByGender &&
                !_vm.usesRoster
                  ? _c(
                      "v-col",
                      { attrs: { cols: "12", md: "8" } },
                      [
                        _c(
                          "v-row",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value:
                                  _vm.tripRequest.category !=
                                  _vm.tripCategory.STAFF_ONLY,
                                expression:
                                  "tripRequest.category != tripCategory.STAFF_ONLY",
                              },
                            ],
                            attrs: { dense: "" },
                          },
                          [
                            _c(
                              "v-col",
                              { attrs: { cols: "12", md: "3" } },
                              [
                                !_vm.tripRequest.status ||
                                _vm.tripRequest.status == -1
                                  ? _c("span", { staticClass: "red--text" }, [
                                      _c("small", [_vm._v("*Required")]),
                                    ])
                                  : _vm._e(),
                                _c("v-text-field", {
                                  ref: "numStudentsM",
                                  attrs: {
                                    label: `${_vm.tripRequestConfig.labels.numStudentsLabel} - Male`,
                                    type: "number",
                                    inputmode: "numeric",
                                    pattern: "[0-9]*",
                                    min: "0",
                                    rules: [
                                      () =>
                                        _vm.numStudentsM >= 0 ||
                                        "This field is required",
                                    ],
                                    required: "",
                                    readonly: _vm.cannotModifyFields,
                                  },
                                  on: { input: _vm.handleAttendeeInput },
                                  model: {
                                    value: _vm.numStudentsM,
                                    callback: function ($$v) {
                                      _vm.numStudentsM = _vm._n($$v)
                                    },
                                    expression: "numStudentsM",
                                  },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "v-col",
                              { attrs: { cols: "12", md: "3" } },
                              [
                                !_vm.tripRequest.status ||
                                _vm.tripRequest.status == -1
                                  ? _c("span", { staticClass: "red--text" }, [
                                      _c("small", [_vm._v("*Required")]),
                                    ])
                                  : _vm._e(),
                                _c("v-text-field", {
                                  ref: "numStudentsF",
                                  attrs: {
                                    label: `${_vm.tripRequestConfig.labels.numStudentsLabel} - Female`,
                                    type: "number",
                                    inputmode: "numeric",
                                    pattern: "[0-9]*",
                                    min: "0",
                                    rules: [
                                      () =>
                                        _vm.numStudentsF >= 0 ||
                                        "This field is required",
                                    ],
                                    required: "",
                                    readonly: _vm.cannotModifyFields,
                                  },
                                  on: { input: _vm.handleAttendeeInput },
                                  model: {
                                    value: _vm.numStudentsF,
                                    callback: function ($$v) {
                                      _vm.numStudentsF = _vm._n($$v)
                                    },
                                    expression: "numStudentsF",
                                  },
                                }),
                              ],
                              1
                            ),
                            _vm.tripRequestConfig.display.nonBinary
                              ? _c(
                                  "v-col",
                                  { attrs: { cols: "12", md: "3" } },
                                  [
                                    !_vm.tripRequest.status ||
                                    _vm.tripRequest.status == -1
                                      ? _c(
                                          "span",
                                          { staticClass: "red--text" },
                                          [_c("small", [_vm._v("*Required")])]
                                        )
                                      : _vm._e(),
                                    _c("v-text-field", {
                                      ref: "numStudentsO",
                                      attrs: {
                                        label: `${_vm.tripRequestConfig.labels.numStudentsLabel} - Non-binary`,
                                        type: "number",
                                        inputmode: "numeric",
                                        pattern: "[0-9]*",
                                        min: "0",
                                        rules: [
                                          () =>
                                            _vm.numStudentsO >= 0 ||
                                            "This field is required",
                                        ],
                                        required: "",
                                        readonly: _vm.cannotModifyFields,
                                      },
                                      on: { input: _vm.handleAttendeeInput },
                                      model: {
                                        value: _vm.numStudentsO,
                                        callback: function ($$v) {
                                          _vm.numStudentsO = _vm._n($$v)
                                        },
                                        expression: "numStudentsO",
                                      },
                                    }),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _c(
                              "v-col",
                              { attrs: { cols: "12", md: "3" } },
                              [
                                _c("v-text-field", {
                                  attrs: {
                                    label: `Total ${_vm.tripRequestConfig.labels.numStudentsLabel}`,
                                    type: "number",
                                    min: "0",
                                    readonly: "",
                                  },
                                  model: {
                                    value: _vm.tripRequest.totalStudents,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.tripRequest,
                                        "totalStudents",
                                        _vm._n($$v)
                                      )
                                    },
                                    expression: "tripRequest.totalStudents",
                                  },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "v-col",
                              { attrs: { cols: "12", md: "3" } },
                              [
                                _c("v-text-field", {
                                  attrs: {
                                    label: `Total ${_vm.tripRequestConfig.labels.numSpecialNeedsStudentsLabel}`,
                                    type: "number",
                                    inputmode: "numeric",
                                    pattern: "[0-9]*",
                                    min: "0",
                                    readonly: _vm.cannotModifyFields,
                                  },
                                  model: {
                                    value:
                                      _vm.tripRequest.numSpecialNeedsStudents,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.tripRequest,
                                        "numSpecialNeedsStudents",
                                        _vm._n($$v)
                                      )
                                    },
                                    expression:
                                      "tripRequest.numSpecialNeedsStudents",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "v-row",
                          { attrs: { dense: "" } },
                          [
                            _c(
                              "v-col",
                              { attrs: { cols: "12", md: "3" } },
                              [
                                !_vm.tripRequest.status ||
                                _vm.tripRequest.status == -1
                                  ? _c("span", { staticClass: "red--text" }, [
                                      _c("small", [_vm._v("*Required")]),
                                    ])
                                  : _vm._e(),
                                _c("v-text-field", {
                                  ref: "numAdultsM",
                                  attrs: {
                                    label: `${_vm.adultsLabel} - Male`,
                                    type: "number",
                                    inputmode: "numeric",
                                    pattern: "[0-9]*",
                                    min: "0",
                                    rules: [
                                      () =>
                                        _vm.numAdultsM >= 0 ||
                                        "This field is required",
                                    ],
                                    required: "",
                                    readonly: _vm.cannotModifyFields,
                                  },
                                  on: { input: _vm.handleAttendeeInput },
                                  model: {
                                    value: _vm.numAdultsM,
                                    callback: function ($$v) {
                                      _vm.numAdultsM = _vm._n($$v)
                                    },
                                    expression: "numAdultsM",
                                  },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "v-col",
                              { attrs: { cols: "12", md: "3" } },
                              [
                                !_vm.tripRequest.status ||
                                _vm.tripRequest.status == -1
                                  ? _c("span", { staticClass: "red--text" }, [
                                      _c("small", [_vm._v("*Required")]),
                                    ])
                                  : _vm._e(),
                                _c("v-text-field", {
                                  ref: "numAdultsF",
                                  attrs: {
                                    label: `${_vm.adultsLabel} - Female`,
                                    type: "number",
                                    inputmode: "numeric",
                                    pattern: "[0-9]*",
                                    min: "0",
                                    rules: [
                                      () =>
                                        _vm.numAdultsF >= 0 ||
                                        "This field is required",
                                    ],
                                    required: "",
                                    readonly: _vm.cannotModifyFields,
                                  },
                                  on: { input: _vm.handleAttendeeInput },
                                  model: {
                                    value: _vm.numAdultsF,
                                    callback: function ($$v) {
                                      _vm.numAdultsF = _vm._n($$v)
                                    },
                                    expression: "numAdultsF",
                                  },
                                }),
                              ],
                              1
                            ),
                            _vm.tripRequestConfig.display.nonBinary
                              ? _c(
                                  "v-col",
                                  { attrs: { cols: "12", md: "3" } },
                                  [
                                    !_vm.tripRequest.status ||
                                    _vm.tripRequest.status == -1
                                      ? _c(
                                          "span",
                                          { staticClass: "red--text" },
                                          [_c("small", [_vm._v("*Required")])]
                                        )
                                      : _vm._e(),
                                    _c("v-text-field", {
                                      ref: "numAdultsO",
                                      attrs: {
                                        label: `${_vm.adultsLabel} - Non-binary`,
                                        type: "number",
                                        inputmode: "numeric",
                                        pattern: "[0-9]*",
                                        min: "0",
                                        rules: [
                                          () =>
                                            _vm.numAdultsO >= 0 ||
                                            "This field is required",
                                        ],
                                        required: "",
                                        readonly: _vm.cannotModifyFields,
                                      },
                                      on: { input: _vm.handleAttendeeInput },
                                      model: {
                                        value: _vm.numAdultsO,
                                        callback: function ($$v) {
                                          _vm.numAdultsO = _vm._n($$v)
                                        },
                                        expression: "numAdultsO",
                                      },
                                    }),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _c(
                              "v-col",
                              { attrs: { cols: "12", md: "3" } },
                              [
                                _c("v-text-field", {
                                  attrs: {
                                    label: `Total ${_vm.adultsLabel}`,
                                    type: "number",
                                    min: "0",
                                    readonly: "",
                                  },
                                  model: {
                                    value: _vm.tripRequest.totalAdults,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.tripRequest,
                                        "totalAdults",
                                        _vm._n($$v)
                                      )
                                    },
                                    expression: "tripRequest.totalAdults",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
                _c(
                  "v-col",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value:
                          _vm.tripRequest.category !=
                            _vm.tripCategory.STAFF_ONLY && _vm.usesRoster,
                        expression:
                          "tripRequest.category != tripCategory.STAFF_ONLY && usesRoster",
                      },
                    ],
                    attrs: { cols: "12", md: "8" },
                  },
                  [
                    _c(
                      "v-row",
                      [
                        _c(
                          "v-col",
                          {
                            staticClass: "elevation-1",
                            attrs: { cols: "12", md: "8" },
                          },
                          [
                            _c("v-text-field", {
                              staticClass: "mt-0 pt-0",
                              attrs: {
                                "append-icon": "mdi-magnify",
                                label: "Search",
                                "single-line": "",
                                "hide-details": "",
                              },
                              model: {
                                value: _vm.searchRoster,
                                callback: function ($$v) {
                                  _vm.searchRoster = $$v
                                },
                                expression: "searchRoster",
                              },
                            }),
                            _c("v-data-table", {
                              attrs: {
                                headers: _vm.studentRosterTableHeaders,
                                items: _vm.studentRosterList,
                                "item-key": "id",
                                search: _vm.searchRoster,
                                "show-select": !_vm.showSelectedStudents,
                                "items-per-page": 25,
                                height: "530px",
                                "footer-props": {
                                  itemsPerPageOptions: [25],
                                },
                              },
                              on: {
                                "toggle-select-all": function ($event) {
                                  return _vm.showSelectWholeTablePrompt(
                                    $event,
                                    "student"
                                  )
                                },
                              },
                              model: {
                                value: _vm.tripRequest.roster,
                                callback: function ($$v) {
                                  _vm.$set(_vm.tripRequest, "roster", $$v)
                                },
                                expression: "tripRequest.roster",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "v-col",
                          { attrs: { cols: "12", md: "4" } },
                          [
                            _c("v-select", {
                              staticClass: "mb-0",
                              attrs: {
                                label: "School",
                                value: _vm.schoolLocations[_vm.schoolId.name],
                                items: _vm.schoolLocations,
                                "item-text": "name",
                                "item-value": "id",
                                outlined: "",
                                disabled: _vm.showSelectedStudents,
                              },
                              on: {
                                input: function ($event) {
                                  return _vm.setSchoolStudentList(_vm.schoolId)
                                },
                              },
                              model: {
                                value: _vm.schoolId,
                                callback: function ($$v) {
                                  _vm.schoolId = $$v
                                },
                                expression: "schoolId",
                              },
                            }),
                            _c("v-switch", {
                              attrs: { label: `Show Selected Students` },
                              model: {
                                value: _vm.showSelectedStudents,
                                callback: function ($$v) {
                                  _vm.showSelectedStudents = $$v
                                },
                                expression: "showSelectedStudents",
                              },
                            }),
                            _vm.showSelectAllStudentsPrompt
                              ? _c("v-checkbox", {
                                  attrs: {
                                    label: `You have currently selected ${_vm.tripRequest.roster.length}/${_vm.studentRosterList.length} students. Would you like to select All Students?`,
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.addAlltoRoster("student")
                                    },
                                  },
                                })
                              : _vm._e(),
                            _c("h3", { staticClass: "mb-3" }, [
                              _vm._v("Totals:"),
                            ]),
                            _c("v-simple-table", {
                              attrs: { dense: "" },
                              scopedSlots: _vm._u([
                                {
                                  key: "default",
                                  fn: function () {
                                    return [
                                      _c("thead", [
                                        _c("tr", [
                                          _c(
                                            "th",
                                            { staticClass: "text-left" },
                                            [_vm._v("Category")]
                                          ),
                                          _c(
                                            "th",
                                            { staticClass: "text-left" },
                                            [_vm._v("Count")]
                                          ),
                                        ]),
                                      ]),
                                      _c("tbody", [
                                        _c("tr", [
                                          _c("td", [_vm._v("Girls")]),
                                          _c("td", [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.rosterSelected("F")
                                                ) +
                                                " "
                                            ),
                                          ]),
                                        ]),
                                        _c("tr", [
                                          _c("td", [_vm._v("Boys")]),
                                          _c("td", [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.rosterSelected("M")
                                                ) +
                                                " "
                                            ),
                                          ]),
                                        ]),
                                        _vm.tripRequestConfig.display.nonBinary
                                          ? _c("tr", [
                                              _c("td", [_vm._v("Non-Binary")]),
                                              _c("td", [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.rosterSelected("O")
                                                    ) +
                                                    " "
                                                ),
                                              ]),
                                            ])
                                          : _vm._e(),
                                        _c("tr", [
                                          _c("td", [
                                            _c("strong", [_vm._v("Total")]),
                                          ]),
                                          _c("td", [
                                            _c("strong", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.tripRequest.roster
                                                    ? _vm.tripRequest.roster
                                                        .length
                                                    : 0
                                                )
                                              ),
                                            ]),
                                          ]),
                                        ]),
                                      ]),
                                    ]
                                  },
                                  proxy: true,
                                },
                              ]),
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
            _c(
              "v-row",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value:
                      _vm.tripRequest.category != _vm.tripCategory.STAFF_ONLY,
                    expression:
                      "tripRequest.category != tripCategory.STAFF_ONLY",
                  },
                ],
              },
              [
                _c("v-spacer"),
                _c(
                  "v-col",
                  { attrs: { cols: "12", md: "8" } },
                  [
                    _vm.chaperoneRules
                      ? _c(
                          "v-alert",
                          {
                            attrs: {
                              outlined: "",
                              type: _vm.chaperoneRulesFailed ? "error" : "info",
                              text: "",
                            },
                          },
                          [
                            _c("p", [
                              _vm._v(
                                " Need " +
                                  _vm._s(_vm.chaperoneRules.adultsPerStudents) +
                                  " adult(s) for " +
                                  _vm._s(_vm.chaperoneRules.numStudents) +
                                  " or more students. "
                              ),
                            ]),
                            _c("p", [
                              _vm._v(
                                " Need " +
                                  _vm._s(
                                    _vm.chaperoneRules.adultsPerAdditional
                                  ) +
                                  " adult(s) for every additional " +
                                  _vm._s(
                                    _vm.chaperoneRules.additionalStudents
                                  ) +
                                  " students. "
                              ),
                            ]),
                            _vm.chaperoneRules.requireSameGender &&
                            _vm.tripRequestConfig.display.splitAttendeesByGender
                              ? _c("p", [
                                  _vm._v(
                                    " At least one female chaperone for female students and at least one male chaperone for male students is required. "
                                  ),
                                ])
                              : _vm._e(),
                          ]
                        )
                      : _vm._e(),
                  ],
                  1
                ),
              ],
              1
            ),
            _vm.tripRequestConfig.display.groupDescription
              ? _c(
                  "v-row",
                  [
                    _c(
                      "v-col",
                      { attrs: { cols: "12", md: "12" } },
                      [
                        _c("v-textarea", {
                          attrs: {
                            label:
                              _vm.tripRequestConfig.labels.groupDescription,
                            dense: "",
                            outlined: "",
                            rows: "3",
                            "auto-grow": "",
                            readonly:
                              _vm.readonly &&
                              !_vm.tripRequest.permissions.canEditSiteAdmin,
                          },
                          model: {
                            value: _vm.tripRequest.groupDescription,
                            callback: function ($$v) {
                              _vm.$set(_vm.tripRequest, "groupDescription", $$v)
                            },
                            expression: "tripRequest.groupDescription",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                )
              : _vm._e(),
            _c(
              "v-row",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value:
                      _vm.tripRequestConfig.display.healthConcerns.includes(
                        _vm.tripRequest.tripTypeId
                      ),
                    expression:
                      "tripRequestConfig.display.healthConcerns.includes(tripRequest.tripTypeId)",
                  },
                ],
              },
              [
                _c(
                  "v-col",
                  { attrs: { cols: "12", md: "2" } },
                  [
                    (!_vm.tripRequest.status || _vm.tripRequest.status == -1) &&
                    _vm.tripRequestConfig.display.requireHealthConcerns
                      ? _c("span", { staticClass: "red--text" }, [
                          _c("small", [_vm._v("*Required")]),
                        ])
                      : _vm._e(),
                    _c(
                      "v-radio-group",
                      {
                        ref: "healthConcerns",
                        staticClass: "mt-0",
                        attrs: { readonly: _vm.cannotModifyFields },
                        scopedSlots: _vm._u([
                          {
                            key: "label",
                            fn: function () {
                              return [
                                _c("div", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.tripRequestConfig.labels
                                        .healthConcerns
                                    )
                                  ),
                                ]),
                              ]
                            },
                            proxy: true,
                          },
                        ]),
                        model: {
                          value: _vm.tripRequest.haveHealthConcerns,
                          callback: function ($$v) {
                            _vm.$set(_vm.tripRequest, "haveHealthConcerns", $$v)
                          },
                          expression: "tripRequest.haveHealthConcerns",
                        },
                      },
                      [
                        _c("v-radio", {
                          attrs: { value: 1 },
                          scopedSlots: _vm._u([
                            {
                              key: "label",
                              fn: function () {
                                return [
                                  _c("div", [
                                    _c(
                                      "strong",
                                      { staticClass: "success--text" },
                                      [_vm._v("Yes")]
                                    ),
                                  ]),
                                ]
                              },
                              proxy: true,
                            },
                          ]),
                        }),
                        _c("v-radio", {
                          attrs: { value: 0 },
                          scopedSlots: _vm._u([
                            {
                              key: "label",
                              fn: function () {
                                return [
                                  _c("div", [
                                    _c(
                                      "strong",
                                      { staticClass: "primary--text" },
                                      [_vm._v("No")]
                                    ),
                                  ]),
                                ]
                              },
                              proxy: true,
                            },
                          ]),
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "v-col",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value:
                          _vm.tripRequest.category !=
                            _vm.tripCategory.STAFF_ONLY &&
                          _vm.tripRequest.haveHealthConcerns == 1,
                        expression:
                          "tripRequest.category != tripCategory.STAFF_ONLY && tripRequest.haveHealthConcerns == 1",
                      },
                    ],
                    attrs: { cols: "12", md: "10" },
                  },
                  [
                    _c("v-textarea", {
                      attrs: {
                        placeholder:
                          _vm.tripRequestConfig.messages.healthConcerns,
                        dense: "",
                        "persistent-placeholder": "",
                        outlined: "",
                        rows: "3",
                        readonly: _vm.cannotModifyFields,
                      },
                      model: {
                        value: _vm.tripRequest.healthConcerns,
                        callback: function ($$v) {
                          _vm.$set(_vm.tripRequest, "healthConcerns", $$v)
                        },
                        expression: "tripRequest.healthConcerns",
                      },
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
            _c(
              "v-row",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value:
                      _vm.tripRequestConfig.display.awayForLunch.includes(
                        _vm.tripRequest.tripTypeId
                      ) &&
                      _vm.tripRequest.category != _vm.tripCategory.STAFF_ONLY,
                    expression:
                      "\n          tripRequestConfig.display.awayForLunch.includes(tripRequest.tripTypeId) &&\n          tripRequest.category != tripCategory.STAFF_ONLY\n        ",
                  },
                ],
              },
              [
                _c(
                  "v-col",
                  { attrs: { cols: "12", md: "10" } },
                  [
                    _c("v-label", [
                      _vm._v(
                        "Will the students be away from school during lunch?"
                      ),
                    ]),
                  ],
                  1
                ),
                _c("v-col", { attrs: { cols: "12", md: "2" } }, [
                  _c(
                    "div",
                    { staticClass: "d-flex align-center justify-center" },
                    [
                      _c("span", [_vm._v("No")]),
                      _c("v-switch", {
                        staticClass: "ml-4",
                        attrs: {
                          "true-value": 1,
                          "false-value": 0,
                          inset: "",
                          readonly: _vm.cannotModifyFields,
                        },
                        model: {
                          value: _vm.tripRequest.awayForLunch,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.tripRequest,
                              "awayForLunch",
                              _vm._n($$v)
                            )
                          },
                          expression: "tripRequest.awayForLunch",
                        },
                      }),
                      _c("span", [_vm._v("Yes")]),
                    ],
                    1
                  ),
                ]),
              ],
              1
            ),
            _c(
              "v-row",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value:
                      _vm.tripRequestConfig.display.packedLunch &&
                      _vm.tripRequest.awayForLunch == 1,
                    expression:
                      "tripRequestConfig.display.packedLunch && tripRequest.awayForLunch == 1",
                  },
                ],
              },
              [
                _c(
                  "v-col",
                  { attrs: { cols: "12", md: "10" } },
                  [
                    _c("v-label", [
                      _vm._v("If so, will these students need packed lunches?"),
                    ]),
                  ],
                  1
                ),
                _c("v-col", { attrs: { cols: "12", md: "2" } }, [
                  _c(
                    "div",
                    { staticClass: "d-flex align-center justify-center" },
                    [
                      _c("span", [_vm._v("No")]),
                      _c("v-switch", {
                        staticClass: "ml-4",
                        attrs: {
                          "true-value": 1,
                          "false-value": 0,
                          inset: "",
                          readonly: _vm.cannotModifyFields,
                        },
                        model: {
                          value: _vm.tripRequest.needLunch,
                          callback: function ($$v) {
                            _vm.$set(_vm.tripRequest, "needLunch", _vm._n($$v))
                          },
                          expression: "tripRequest.needLunch",
                        },
                      }),
                      _c("span", [_vm._v("Yes")]),
                    ],
                    1
                  ),
                ]),
              ],
              1
            ),
            _c(
              "v-row",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value:
                      _vm.tripRequest.awayForLunch == 1 &&
                      _vm.tripRequest.needLunch == 1,
                    expression:
                      "tripRequest.awayForLunch == 1 && tripRequest.needLunch == 1",
                  },
                ],
              },
              [
                _c(
                  "v-col",
                  { attrs: { cols: "12", md: "10" } },
                  [
                    _c("v-label", [
                      _vm._v(_vm._s(_vm.tripRequestConfig.messages.needLunch)),
                    ]),
                  ],
                  1
                ),
                _c(
                  "v-col",
                  { attrs: { cols: "12", md: "12" } },
                  [
                    _c("v-textarea", {
                      attrs: {
                        hint: _vm.tripRequestConfig.messages.packedLunch,
                        readonly: _vm.cannotModifyFields,
                        dense: "",
                        outlined: "",
                        "persistent-hint": "",
                        rows: "5",
                      },
                      model: {
                        value: _vm.tripRequest.foodAccomadations,
                        callback: function ($$v) {
                          _vm.$set(_vm.tripRequest, "foodAccomadations", $$v)
                        },
                        expression: "tripRequest.foodAccomadations",
                      },
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
            _vm.customFormFields
              ? _c(
                  "v-row",
                  _vm._l(_vm.customFormFields, function (cff, i) {
                    return _c("custom-form-field", {
                      key: i,
                      ref: cff.id,
                      refInFor: true,
                      attrs: {
                        cff: cff,
                        value: _vm.tripRequest.customFormFields[cff.id],
                        readonly: _vm.readonly,
                      },
                      on: {
                        handleCFFInput: function ($event) {
                          return _vm.$emit("handleCFFInput", {
                            cffId: cff.id,
                            value: $event,
                          })
                        },
                      },
                    })
                  }),
                  1
                )
              : _vm._e(),
          ],
          1
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }