<template>
  <div>
    <v-row dense>
      <v-col cols="12" md="12">
        <v-data-table fixed-header :headers="headers" :items="audit" item-key="id" sort-by="time" disable-sort>
          <template v-slot:[`item.time`]="{ item }">
            {{ format(new Date(item.timestamp * 1000), 'PP p') }}
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { format } from 'date-fns';

export default {
  name: 'TripRequestAudit',
  inject: ['eventHub'],
  mixins: [],
  components: {},
  props: {
    auditHistory: Array,
    approval: Object,
  },
  data() {
    return {
      format,
      headers: [
        { text: 'Date', value: 'time' },
        { text: 'User', value: 'user' },
        { text: 'Action', value: 'action' },
        { text: 'Comment', value: 'comment' },
      ],
      approvalActions: {
        '-2': 'Denied',
        '-1': 'Changes Requested',
        0: 'Changes Requested',
        1: 'Approved',
      },
    };
  },
  computed: {
    ...mapGetters('approvalLevel', ['approvalLevelsById']),
    audit() {
      if (this.auditHistory && this.approval) {
        return this.auditHistory
          .map((e) => ({
            timestamp: e.timestamp,
            user: e.displayName || 'System',
            action: e.description.replace(';', ' - '),
          }))
          .concat(
            this.approval.history.map((e) => {
              const approvalLevelName = this.approvalLevelsById?.[e.approvalLevelId]?.name || 'Unknown level';
              const approvalAction = this.approvalActions?.[e.approved] || 'Unknown action';

              return {
                timestamp: e.timestamp,
                user: e.user,
                action: `${approvalLevelName} - ${approvalAction}`,
                comment: e.comments,
              };
            })
          )
          .sort((a, b) => b.timestamp - a.timestamp);
      }
      return [];
    },
  },
  methods: {},
};
</script>

<style lang="scss"></style>
