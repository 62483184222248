var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-card",
    { attrs: { outlined: "", loading: _vm.isLoadingFAD } },
    [
      _c(
        "v-card-title",
        [
          _vm._v(
            " " +
              _vm._s(_vm.isDeclined ? "Declined " : "") +
              "Assignment #" +
              _vm._s(_vm.index + 1) +
              " "
          ),
          _vm.driverConfig.driverAccept || _vm.driverConfig.driverDecline
            ? _c(
                "v-chip",
                {
                  staticClass: "font-weight-bold ml-4",
                  attrs: {
                    color: _vm.getAssignmentStatus(_vm.assignment)?.color,
                    label: "",
                  },
                },
                [
                  _vm._v(
                    " " +
                      _vm._s(_vm.getAssignmentStatus(_vm.assignment)?.text) +
                      " "
                  ),
                ]
              )
            : _vm._e(),
          _vm.assignment.leg > 0
            ? _c(
                "v-icon",
                {
                  staticClass: "ml-4",
                  attrs: { color: _vm.assignment.leg == 1 ? "green" : "blue" },
                },
                [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.assignment.leg == 1
                          ? "mdi-arrow-right-bold-circle"
                          : "mdi-arrow-left-bold-circle"
                      ) +
                      " "
                  ),
                ]
              )
            : _vm._e(),
          _vm.assignment.assignedAt
            ? _c("p", { staticClass: "mb-0 ml-4 text-subtitle-2 inline" }, [
                _vm._v(_vm._s(_vm.assignedBy)),
              ])
            : _vm._e(),
          _c("v-spacer"),
          _vm.hasTripTicketTemplate && !_vm.isDeclined
            ? _c("trip-ticket-download-button", {
                attrs: { assignmentIds: [_vm.assignment.id] },
              })
            : _vm._e(),
          _vm.isAdminOrVehicleOwner && !_vm.isDeclined
            ? _c(
                "v-btn",
                {
                  attrs: { text: "", color: "error" },
                  on: {
                    click: function ($event) {
                      return _vm.removeAssignment(_vm.assignment.id)
                    },
                  },
                },
                [_c("v-icon", [_vm._v("mdi-delete")])],
                1
              )
            : _vm._e(),
        ],
        1
      ),
      _c(
        "v-card-text",
        [
          _vm.assignment.notes
            ? _c(
                "v-row",
                { attrs: { dense: "" } },
                [
                  _c("v-col", { attrs: { cols: "12" } }, [
                    _c("p", { staticClass: "mb-0" }, [
                      _vm._v(_vm._s(_vm.assignment.notes)),
                    ]),
                  ]),
                ],
                1
              )
            : _vm._e(),
          _c(
            "v-row",
            [
              _c(
                "v-col",
                {
                  attrs: {
                    cols: "12",
                    md: _vm.assignment.assistantId ? "4" : "6",
                  },
                },
                [
                  _c("v-simple-table", {
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function () {
                          return [
                            _c("thead", [
                              _c("tr", [
                                _c("th", { attrs: { width: "40px" } }),
                                _c("th", { staticClass: "text-left" }, [
                                  _vm._v("Vehicle"),
                                ]),
                                _c("th", { staticClass: "text-left" }, [
                                  _vm._v("Vehicle Location"),
                                ]),
                              ]),
                            ]),
                            _c("tbody", [
                              _vm.assignment.vehicleId || _vm.assignment.vehicle
                                ? _c("tr", [
                                    _c(
                                      "td",
                                      [
                                        _vm.tripRequest.vehicleType ===
                                          _vm.VEHICLE_TYPES.NORMAL &&
                                        _vm.isAdminOrVehicleOwner &&
                                        !_vm.isDeclined
                                          ? _c(
                                              "v-btn",
                                              {
                                                attrs: {
                                                  icon: "",
                                                  color: "error",
                                                  disabled: _vm.isLoading,
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.$emit(
                                                      "removeVehicle",
                                                      _vm.assignment
                                                    )
                                                  },
                                                },
                                              },
                                              [
                                                _c("v-icon", [
                                                  _vm._v("mdi-delete"),
                                                ]),
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                      ],
                                      1
                                    ),
                                    _c("td", [
                                      _vm._v(
                                        _vm._s(_vm.getVehicle(_vm.assignment))
                                      ),
                                    ]),
                                    _c("td", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.getVehicleLocation(
                                            _vm.assignment.vehicleId
                                          )
                                        )
                                      ),
                                    ]),
                                  ])
                                : _vm._e(),
                            ]),
                          ]
                        },
                        proxy: true,
                      },
                    ]),
                  }),
                ],
                1
              ),
              _c(
                "v-col",
                { attrs: { cols: "12", md: "6" } },
                [
                  _c("v-simple-table", {
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function () {
                          return [
                            _c("thead", [
                              _c("tr", [
                                _c("th", { attrs: { width: "40px" } }),
                                _c("th", { staticClass: "text-left" }, [
                                  _vm._v("Driver"),
                                ]),
                                _c("th", { staticClass: "text-left" }, [
                                  _vm._v("Email"),
                                ]),
                                _vm.driverConfig.driverAccept &&
                                _vm.assignment.status === 1 &&
                                _vm.isAdmin
                                  ? _c("th", { attrs: { width: "40px" } }, [
                                      _vm._v("Accept"),
                                    ])
                                  : _vm._e(),
                                _vm.driverConfig.driverDecline &&
                                _vm.assignment.status === 1 &&
                                _vm.isAdmin
                                  ? _c("th", { attrs: { width: "40px" } }, [
                                      _vm._v("Decline"),
                                    ])
                                  : _vm._e(),
                              ]),
                            ]),
                            _vm.driverConfig.useAvailableDrivers &&
                            _vm.tripRequest.needDriverAssigned &&
                            (_vm.assignment.driver || _vm.assignment.driverId)
                              ? _c("tbody", [
                                  _c("tr", [
                                    _c(
                                      "td",
                                      [
                                        _vm.tripRequest.vehicleType ===
                                          _vm.VEHICLE_TYPES.NORMAL &&
                                        _vm.isAdminOrVehicleOwner &&
                                        !_vm.isDeclined
                                          ? _c(
                                              "v-btn",
                                              {
                                                attrs: {
                                                  icon: "",
                                                  color: "error",
                                                  disabled: _vm.isLoading,
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.removeDriver(
                                                      _vm.assignment
                                                    )
                                                  },
                                                },
                                              },
                                              [
                                                _c("v-icon", [
                                                  _vm._v("mdi-delete"),
                                                ]),
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "td",
                                      [
                                        !_vm.assignment.driverId
                                          ? [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.assignment.driver
                                                  ) +
                                                  " "
                                              ),
                                            ]
                                          : [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.driversById[
                                                      _vm.assignment.driverId
                                                    ].firstName
                                                  ) +
                                                  " " +
                                                  _vm._s(
                                                    _vm.driversById[
                                                      _vm.assignment.driverId
                                                    ].lastName
                                                  ) +
                                                  " "
                                              ),
                                            ],
                                      ],
                                      2
                                    ),
                                    _c("td", [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.getDriverEmail({
                                              driverId: _vm.assignment.driverId,
                                              driverEmail:
                                                _vm.assignment.driverEmail,
                                            })
                                          ) +
                                          " "
                                      ),
                                    ]),
                                    _vm.driverConfig.driverAccept &&
                                    _vm.assignment.status === 1 &&
                                    _vm.isAdmin
                                      ? _c(
                                          "td",
                                          [
                                            _c(
                                              "v-btn",
                                              {
                                                attrs: {
                                                  icon: "",
                                                  color: "primary",
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.adminAccept(
                                                      _vm.assignment.id
                                                    )
                                                  },
                                                },
                                              },
                                              [
                                                _c("v-icon", [
                                                  _vm._v("mdi-check"),
                                                ]),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                    _vm.driverConfig.driverDecline &&
                                    _vm.assignment.status === 1 &&
                                    _vm.isAdmin
                                      ? _c(
                                          "td",
                                          [
                                            _c(
                                              "v-btn",
                                              {
                                                attrs: {
                                                  icon: "",
                                                  color: "primary",
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.$emit(
                                                      "adminDecline",
                                                      _vm.assignment.id
                                                    )
                                                  },
                                                },
                                              },
                                              [
                                                _c("v-icon", [
                                                  _vm._v("mdi-cancel"),
                                                ]),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                  ]),
                                ])
                              : _vm._e(),
                          ]
                        },
                        proxy: true,
                      },
                    ]),
                  }),
                  !_vm.driverConfig.useAvailableDrivers ||
                  !_vm.tripRequest.needDriverAssigned
                    ? _c(
                        "v-row",
                        { staticClass: "mt-2" },
                        [
                          _c(
                            "v-col",
                            { attrs: { cols: "12", md: "1" } },
                            [
                              _vm.showDriverDeleteButton
                                ? _c(
                                    "v-btn",
                                    {
                                      staticClass: "mt-3",
                                      attrs: {
                                        icon: "",
                                        color: "error",
                                        disabled: _vm.isLoading,
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.removeDriver(
                                            _vm.assignment
                                          )
                                        },
                                      },
                                    },
                                    [_c("v-icon", [_vm._v("mdi-delete")])],
                                    1
                                  )
                                : _vm._e(),
                            ],
                            1
                          ),
                          !_vm.driverConfig.useAvailableDrivers
                            ? [
                                _c(
                                  "v-col",
                                  { attrs: { cols: "10", md: "5" } },
                                  [
                                    _c("v-combobox", {
                                      attrs: {
                                        items: _vm.driverItems,
                                        "item-text": _vm.getDriverText,
                                        label: "Driver",
                                        "return-object": "",
                                        outlined: "",
                                        disabled:
                                          _vm.tripRequest.vehicleType ===
                                            _vm.VEHICLE_TYPES
                                              .APPROVED_CHARTER ||
                                          !_vm.canAssign,
                                      },
                                      on: {
                                        change: function ($event) {
                                          return _vm.setDriverEmail(
                                            _vm.assignment
                                          )
                                        },
                                      },
                                      model: {
                                        value: _vm.assignment.driver,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.assignment,
                                            "driver",
                                            $$v
                                          )
                                        },
                                        expression: "assignment.driver",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "v-col",
                                  { attrs: { cols: "10", md: "5" } },
                                  [
                                    _c("v-text-field", {
                                      attrs: {
                                        label: "Driver Email",
                                        outlined: "",
                                        disabled:
                                          _vm.tripRequest.vehicleType ===
                                            _vm.VEHICLE_TYPES
                                              .APPROVED_CHARTER ||
                                          !_vm.canAssign,
                                      },
                                      model: {
                                        value: _vm.assignment.driverEmail,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.assignment,
                                            "driverEmail",
                                            $$v
                                          )
                                        },
                                        expression: "assignment.driverEmail",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "v-col",
                                  { attrs: { cols: "auto" } },
                                  [
                                    _c(
                                      "v-btn",
                                      {
                                        staticClass: "mt-3",
                                        attrs: {
                                          text: "",
                                          color: "success",
                                          loading: _vm.isLoading,
                                          disabled: !_vm.canAssign,
                                          icon: "",
                                        },
                                        on: {
                                          click: function ($event) {
                                            return _vm.$emit("reserve", [
                                              _vm.assignment,
                                            ])
                                          },
                                        },
                                      },
                                      [
                                        _c("v-icon", [
                                          _vm._v("mdi-content-save"),
                                        ]),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ]
                            : [
                                _c("v-col", [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.assignment.driver ||
                                          _vm.getDriverText(
                                            _vm.driversById[
                                              _vm.assignment.driverId
                                            ]
                                          )
                                      ) +
                                      " "
                                  ),
                                ]),
                                _c("v-col", [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.assignment.driverEmail ||
                                          _vm.driversById[
                                            _vm.assignment.driverId
                                          ]?.email
                                      ) +
                                      " "
                                  ),
                                ]),
                              ],
                        ],
                        2
                      )
                    : _vm._e(),
                  _vm.tripRequest.vehicleType !== _vm.VEHICLE_TYPES.NORMAL
                    ? _c(
                        "v-row",
                        { staticClass: "mt-2" },
                        [
                          _c(
                            "v-col",
                            { attrs: { cols: "12", md: "1" } },
                            [
                              (_vm.me.is.superAdmin ||
                                _vm.me.is.transportationAdmin ||
                                _vm.me.is.vehicleOwner ||
                                _vm.me.is.specialNeedsVehicleOwner) &&
                              (_vm.assignment.mechanic ||
                                _vm.assignment.mechanicEmail)
                                ? _c(
                                    "v-btn",
                                    {
                                      staticClass: "mt-3",
                                      attrs: {
                                        icon: "",
                                        color: "error",
                                        disabled: _vm.isLoading,
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.$emit(
                                            "removeMechanic",
                                            _vm.assignment
                                          )
                                        },
                                      },
                                    },
                                    [_c("v-icon", [_vm._v("mdi-delete")])],
                                    1
                                  )
                                : _vm._e(),
                            ],
                            1
                          ),
                          _vm.mechanicItems?.length
                            ? _c(
                                "v-col",
                                { attrs: { cols: "10", md: "5" } },
                                [
                                  _c("v-combobox", {
                                    attrs: {
                                      value: _vm.assignment.mechanic,
                                      items: _vm.mechanicItems,
                                      "item-text": _vm.getMechanicText,
                                      label: "Mechanic",
                                      "return-object": "",
                                      outlined: "",
                                    },
                                    on: {
                                      change: function ($event) {
                                        return _vm.handleMechanicAdded(
                                          $event,
                                          _vm.assignment
                                        )
                                      },
                                      "update:search-input": function ($event) {
                                        return _vm.handleMechanicAdded(
                                          $event,
                                          _vm.assignment
                                        )
                                      },
                                    },
                                    model: {
                                      value: _vm.assignment.mechanic,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.assignment,
                                          "mechanic",
                                          $$v
                                        )
                                      },
                                      expression: "assignment.mechanic",
                                    },
                                  }),
                                ],
                                1
                              )
                            : _vm._e(),
                          _c(
                            "v-col",
                            { attrs: { cols: "12", md: "5" } },
                            [
                              _c("v-text-field", {
                                attrs: {
                                  value: _vm.assignment.mechanicEmail,
                                  label: "Mechanic Email",
                                  outlined: "",
                                },
                                model: {
                                  value: _vm.assignment.mechanicEmail,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.assignment,
                                      "mechanicEmail",
                                      $$v
                                    )
                                  },
                                  expression: "assignment.mechanicEmail",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            { attrs: { cols: "12", md: "1" } },
                            [
                              _c(
                                "v-btn",
                                {
                                  staticClass: "mt-3",
                                  attrs: {
                                    icon: "",
                                    color: "success",
                                    disabled: _vm.isLoading || !_vm.canAssign,
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.$emit(
                                        "saveAssignmentMechanic",
                                        [_vm.assignment]
                                      )
                                    },
                                  },
                                },
                                [_c("v-icon", [_vm._v("mdi-content-save")])],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
              _vm.assignment.assistantId
                ? _c(
                    "v-col",
                    { attrs: { cols: "12", md: "2" } },
                    [
                      _c("v-simple-table", {
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function () {
                                return [
                                  _c("thead", [
                                    _c("tr", [
                                      _c("th", { attrs: { width: "40px" } }),
                                      _c("th", { staticClass: "text-left" }, [
                                        _vm._v("Assistant"),
                                      ]),
                                    ]),
                                  ]),
                                  _c("tbody", [
                                    _c("tr", [
                                      _c(
                                        "td",
                                        [
                                          _vm.isAdminOrVehicleOwner &&
                                          !_vm.isDeclined
                                            ? _c(
                                                "v-btn",
                                                {
                                                  attrs: {
                                                    icon: "",
                                                    color: "error",
                                                    disabled: _vm.isLoading,
                                                  },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.removeAssistant(
                                                        _vm.assignment
                                                      )
                                                    },
                                                  },
                                                },
                                                [
                                                  _c("v-icon", [
                                                    _vm._v("mdi-delete"),
                                                  ]),
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                        ],
                                        1
                                      ),
                                      _c("td", [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.driversById[
                                                _vm.assignment.assistantId
                                              ].firstName
                                            ) +
                                            " " +
                                            _vm._s(
                                              _vm.driversById[
                                                _vm.assignment.assistantId
                                              ].lastName
                                            ) +
                                            " "
                                        ),
                                      ]),
                                    ]),
                                  ]),
                                ]
                              },
                              proxy: true,
                            },
                          ],
                          null,
                          false,
                          843137681
                        ),
                      }),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
          _vm.tripRequest.vehicleType === _vm.VEHICLE_TYPES.NORMAL &&
          !_vm.isDeclined
            ? _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    {
                      attrs: {
                        cols: "12",
                        md: _vm.assignment.assistantId ? "4" : "6",
                      },
                    },
                    [
                      !_vm.assignment.vehicleId
                        ? _c(
                            "v-btn",
                            {
                              attrs: {
                                color: "primary",
                                disabled: !_vm.canAssign,
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.$emit(
                                    "openFAV",
                                    _vm.assignment.id,
                                    _vm.assignment.leg
                                  )
                                },
                              },
                            },
                            [_vm._v(" " + _vm._s(_vm.favText) + " ")]
                          )
                        : _c(
                            "v-btn",
                            {
                              attrs: {
                                color: "primary",
                                disabled: !_vm.canAssign,
                                loading: _vm.isLoadingVehicle,
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.$emit(
                                    "openFAV",
                                    _vm.assignment.id,
                                    _vm.assignment.leg
                                  )
                                },
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "loader",
                                    fn: function () {
                                      return [
                                        _c("v-progress-circular", {
                                          attrs: { indeterminate: "" },
                                        }),
                                      ]
                                    },
                                    proxy: true,
                                  },
                                ],
                                null,
                                false,
                                1765764368
                              ),
                            },
                            [_vm._v(" Change Vehicle ")]
                          ),
                    ],
                    1
                  ),
                  _vm.driverConfig.useAvailableDrivers
                    ? _c(
                        "v-col",
                        { attrs: { cols: "12", md: "6" } },
                        [
                          !_vm.assignment.driverId && !_vm.assignment.driver
                            ? _c(
                                "v-btn",
                                {
                                  attrs: {
                                    color: "primary",
                                    disabled: !_vm.canAssign,
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.$emit(
                                        "openFAD",
                                        _vm.assignment.id
                                      )
                                    },
                                  },
                                },
                                [_vm._v(" " + _vm._s(_vm.fadText) + " ")]
                              )
                            : _vm.tripRequest.needDriverAssigned
                            ? [
                                _c(
                                  "v-btn",
                                  {
                                    attrs: {
                                      color: "primary",
                                      disabled: !_vm.canAssign,
                                      loading: _vm.isLoading,
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.$emit(
                                          "openFAD",
                                          _vm.assignment.id
                                        )
                                      },
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "loader",
                                          fn: function () {
                                            return [
                                              _c("v-progress-circular", {
                                                attrs: { indeterminate: "" },
                                              }),
                                            ]
                                          },
                                          proxy: true,
                                        },
                                      ],
                                      null,
                                      false,
                                      1765764368
                                    ),
                                  },
                                  [_vm._v(" Change Driver ")]
                                ),
                              ]
                            : !_vm.tripRequest.needDriverAssigned
                            ? [
                                _c(
                                  "v-btn",
                                  {
                                    attrs: {
                                      color: "primary",
                                      disabled: !_vm.canAssign,
                                      loading: _vm.isLoading,
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.$emit(
                                          "openFAD",
                                          _vm.assignment.id
                                        )
                                      },
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "loader",
                                          fn: function () {
                                            return [
                                              _c("v-progress-circular", {
                                                attrs: { indeterminate: "" },
                                              }),
                                            ]
                                          },
                                          proxy: true,
                                        },
                                      ],
                                      null,
                                      false,
                                      1765764368
                                    ),
                                  },
                                  [_vm._v(" Change Driver ")]
                                ),
                              ]
                            : _vm._e(),
                          _vm.assignment.driverId &&
                          !_vm.assignment.assistantId &&
                          _vm.isAdminOrVehicleOwner
                            ? _c(
                                "v-btn",
                                {
                                  staticClass: "ml-4",
                                  attrs: {
                                    color: "primary",
                                    disabled: !_vm.canAssign,
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.$emit(
                                        "openFAD",
                                        _vm.assignment.id,
                                        0,
                                        true
                                      )
                                    },
                                  },
                                },
                                [_vm._v(" Add Assistant ")]
                              )
                            : _vm._e(),
                        ],
                        2
                      )
                    : _vm._e(),
                  _vm.assignment.assistantId
                    ? _c(
                        "v-col",
                        { attrs: { cols: "12", md: "2" } },
                        [
                          _c(
                            "v-btn",
                            {
                              attrs: {
                                color: "primary",
                                disabled: !_vm.canAssign,
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.$emit(
                                    "openFAD",
                                    _vm.assignment.id,
                                    0,
                                    true
                                  )
                                },
                              },
                            },
                            [_vm._v(" Change Assistant ")]
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }