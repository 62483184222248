var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    { attrs: { fluid: "" } },
    [
      _c("v-form", { staticClass: "full-width" }, [
        _c(
          "div",
          [
            _c(
              "v-row",
              [
                _c(
                  "v-col",
                  { attrs: { cols: "12", md: "12" } },
                  [
                    _c(
                      "v-alert",
                      { attrs: { outlined: "", type: "info", text: "" } },
                      [
                        _c("div", {
                          domProps: { innerHTML: _vm._s(_vm.generalMessage) },
                        }),
                      ]
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
            _c(
              "v-row",
              [
                _c(
                  "v-col",
                  { attrs: { cols: "12", md: "4" } },
                  [
                    !_vm.tripRequest.status || _vm.tripRequest.status == -1
                      ? _c("span", { staticClass: "red--text" }, [
                          _c("small", [_vm._v("*Required")]),
                        ])
                      : _vm._e(),
                    _c("v-select", {
                      ref: "category",
                      attrs: {
                        "menu-props": { bottom: true, offsetY: true },
                        label: "Category",
                        items: _vm.categories,
                        "item-text": "name",
                        "item-value": "id",
                        rules: [
                          () =>
                            !!_vm.tripRequest.category ||
                            "This field is required",
                        ],
                        required: "",
                        outlined: "",
                        readonly: _vm.readonly,
                      },
                      on: { change: _vm.handleTripCategorySelect },
                      model: {
                        value: _vm.tripRequest.category,
                        callback: function ($$v) {
                          _vm.$set(_vm.tripRequest, "category", $$v)
                        },
                        expression: "tripRequest.category",
                      },
                    }),
                  ],
                  1
                ),
                _c("v-spacer"),
                _c(
                  "v-col",
                  { attrs: { cols: "12", md: "4" } },
                  [
                    !_vm.tripRequest.status || _vm.tripRequest.status == -1
                      ? _c("span", { staticClass: "red--text" }, [
                          _c("small", [_vm._v("*Required")]),
                        ])
                      : _vm._e(),
                    _c("v-select", {
                      ref: "tripTypeId",
                      attrs: {
                        "menu-props": { bottom: true, offsetY: true },
                        items: _vm.filteredTripTypes,
                        "item-text": "name",
                        "item-value": "id",
                        label: "Trip Type",
                        rules: [
                          () =>
                            !!_vm.tripRequest.tripTypeId ||
                            "This field is required",
                        ],
                        required: "",
                        outlined: "",
                        disabled: !_vm.tripRequest.category,
                        readonly: _vm.readonly,
                      },
                      on: { change: _vm.handleTripTypeSelect },
                      model: {
                        value: _vm.tripRequest.tripTypeId,
                        callback: function ($$v) {
                          _vm.$set(_vm.tripRequest, "tripTypeId", $$v)
                        },
                        expression: "tripRequest.tripTypeId",
                      },
                    }),
                  ],
                  1
                ),
                _c("v-spacer"),
              ],
              1
            ),
            _c(
              "v-row",
              [
                _c(
                  "v-col",
                  { attrs: { cols: "12", md: "4" } },
                  [
                    !_vm.tripRequest.status || _vm.tripRequest.status == -1
                      ? _c("span", { staticClass: "red--text" }, [
                          _c("small", [_vm._v("*Required")]),
                        ])
                      : _vm._e(),
                    _c("v-select", {
                      ref: "tripEventIds",
                      attrs: {
                        "menu-props": { bottom: true, offsetY: true },
                        items: _vm.filteredTripEvents,
                        "item-text": "name",
                        "item-value": "id",
                        label: "Event",
                        multiple: "",
                        rules: [
                          () =>
                            _vm.tripRequest.tripEventIds.length > 0 ||
                            "This field is required",
                        ],
                        required: "",
                        outlined: "",
                        disabled: !_vm.tripRequest.tripTypeId,
                        readonly:
                          (_vm.readonly && _vm.isFieldReadonlyAfterApproval) ||
                          _vm.isTripApprovedAndSubmittedByRequester,
                      },
                      on: {
                        input: _vm.limiter,
                        change: _vm.handleTripEventSelect,
                      },
                      scopedSlots: _vm._u(
                        [
                          _vm.tripRequest.tripTypeId &&
                          _vm.tripTypesById[_vm.tripRequest.tripTypeId]
                            .allowEventRates
                            ? {
                                key: "item",
                                fn: function ({ item }) {
                                  return [_vm._v(" " + _vm._s(item.name) + " ")]
                                },
                              }
                            : null,
                        ],
                        null,
                        true
                      ),
                      model: {
                        value: _vm.tripRequest.tripEventIds,
                        callback: function ($$v) {
                          _vm.$set(_vm.tripRequest, "tripEventIds", $$v)
                        },
                        expression: "tripRequest.tripEventIds",
                      },
                    }),
                  ],
                  1
                ),
                _c("v-spacer"),
                _c(
                  "v-col",
                  { attrs: { cols: "12", md: "4" } },
                  [
                    !_vm.tripRequest.status || _vm.tripRequest.status == -1
                      ? _c("span", { staticClass: "red--text" }, [
                          _c("small", [_vm._v("*Required")]),
                        ])
                      : _vm._e(),
                    _c("v-select", {
                      ref: "locationId",
                      attrs: {
                        "menu-props": { bottom: true, offsetY: true },
                        items: _vm.locations,
                        "item-text": _vm.getLocationText,
                        "item-value": "id",
                        label: "Your School/Dept",
                        rules: [
                          () =>
                            !!_vm.tripRequest.locationId ||
                            "This field is required",
                        ],
                        required: "",
                        outlined: "",
                        readonly:
                          _vm.readonly ||
                          (Object.keys(_vm.tripRequest.permissions).length &&
                            (!_vm.tripRequest.permissions.canEditPreApproval ||
                              !_vm.tripRequest.permissions.canEditMidApproval ||
                              !_vm.tripRequest.permissions.canEditApproved)) ||
                          (_vm.usesRoster &&
                            Number.isInteger(_vm.tripRequest.locationId) &&
                            !_vm.step),
                      },
                      on: { change: _vm.handleLocationSelect },
                      model: {
                        value: _vm.tripRequest.locationId,
                        callback: function ($$v) {
                          _vm.$set(_vm.tripRequest, "locationId", $$v)
                        },
                        expression: "tripRequest.locationId",
                      },
                    }),
                  ],
                  1
                ),
                _c("v-spacer"),
              ],
              1
            ),
            _vm.customFormFields
              ? _c(
                  "v-row",
                  _vm._l(_vm.customFormFields, function (cff, i) {
                    return _c("custom-form-field", {
                      key: i,
                      ref: cff.id,
                      refInFor: true,
                      attrs: {
                        cff: cff,
                        value: _vm.tripRequest.customFormFields[cff.id],
                        readonly: _vm.readonly,
                      },
                      on: {
                        handleCFFInput: function ($event) {
                          return _vm.$emit("handleCFFInput", {
                            cffId: cff.id,
                            value: $event,
                          })
                        },
                      },
                    })
                  }),
                  1
                )
              : _vm._e(),
          ],
          1
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }