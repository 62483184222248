<template>
  <v-dialog v-model="dialog" max-width="1300px" no-click-animation persistent scrollable fullscreen>
    <v-card>
      <v-card-title>
        <v-btn icon @click="close" class="mr-2">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        {{ override ? 'Reserve any Vehicle' : 'Find Available Vehicles' }}
      </v-card-title>
      <v-card-text class="pb-0">
        <v-row v-if="override">
          <v-col cols="12" md="12">
            <v-alert class="mb-0" outlined type="info" text>
              This list DOES NOT check for vehicle availability!
            </v-alert>
          </v-col>
        </v-row>
        <v-row class="mt-0">
          <v-col cols="12" md="12">
            <assignment-info
              :leg="leg"
              :tripRequest="tripRequest"
              :tripRequestConfig="tripRequestConfig"
              :reserveFromLocation="reserveFromLocation"
            ></assignment-info>
          </v-col>
        </v-row>
        <v-row dense>
          <v-col
            cols="auto"
            v-if="
              isAdmin ||
              (me.is.vehicleOwner && permissions.vehicleOwner.overrideVehicle) ||
              (me.is.specialNeedsVehicleOwner && permissions.specialNeedsVehicleOwner.overrideVehicle)
            "
          >
            <v-btn class="mt-4" color="primary" @click="overrideList()">
              {{ override ? 'Check Availability' : 'Override' }}
            </v-btn>
          </v-col>
          <v-col
            cols="auto"
            v-if="
              isAdmin ||
              (me.is.vehicleOwner && permissions.vehicleOwner.showAllLocations) ||
              (me.is.specialNeedsVehicleOwner && permissions.specialNeedsVehicleOwner.showAllLocations)
            "
          >
            <v-btn class="mt-4" color="primary" @click="filterList(true)">
              {{ allLocations ? 'Trip Location' : 'All Locations' }}
            </v-btn>
          </v-col>
          <v-col cols="auto ml-4">
            <v-checkbox v-model="showWithLift" @change="filterList(false)" label="w/ Wheelchair Lift"></v-checkbox>
          </v-col>
          <v-spacer></v-spacer>
          <v-col cols="12" md="4">
            <v-select
              :menu-props="{ bottom: true, offsetY: true }"
              v-model="filterVehicleType"
              :items="vehicleTypeItems"
              item-text="name"
              item-value="id"
              label="VehicleType"
              outlined
              clearable
              hide-details
              @change="filterList(false, false)"
            ></v-select>
          </v-col>
        </v-row>
        <v-row class="mt-0">
          <v-col cols="12" md="12" class="mt-0 py-0">
            <v-data-table
              v-model="selected"
              fixed-header
              :headers="headers"
              :items="filteredAvailable"
              hide-default-footer
              :items-per-page="filteredAvailable.length"
              item-key="id"
              show-select
              disable-sort
              no-data-text="No vehicles available"
              :height="tableHeight"
              :loading="loading"
            >
              <template v-slot:[`header.data-table-select`]></template>
              <template v-slot:[`item.data-table-select`]="{ item, isSelected, select }">
                <v-simple-checkbox
                  :value="isSelected"
                  :disabled="item.cannotAssign"
                  @input="select($event)"
                  v-ripple
                ></v-simple-checkbox>
              </template>
              <template v-slot:[`item.type`]="{ item }">
                {{ vehicleTypesById[item.type] ? vehicleTypesById[item.type].name : '-' }}
              </template>
              <template v-slot:[`item.driver`]="{ item }">
                <v-tooltip right :disabled="!item.driverConflict">
                  <template v-slot:activator="{ on, attrs }">
                    {{
                      vehicleTypesById[item.driverId]
                        ? driversById[item.driverId].firstName + ' ' + driversById[item.driverId].lastName
                        : item.driver || '-'
                    }}
                    <v-icon v-if="item.driverConflict" color="warning" dark v-bind="attrs" v-on="on">
                      mdi-alert
                    </v-icon>
                  </template>
                  This driver cannot be assigned
                </v-tooltip>
              </template>
              <template v-slot:[`item.cap`]="{ item }">
                {{ item.capacityElem ? item.capacityElem : '' }}{{ item.capacityMid ? `/${item.capacityMid}` : ''
                }}{{ item.capacityHigh ? `/${item.capacityHigh}` : '' }}
              </template>
              <template v-slot:[`item.location`]="{ item }">
                {{ getVehicleLocation(item.id) }}
              </template>
              <template v-slot:[`item.conditions`]="{ item }">
                {{ item.travelConditions ? travelConditions[item.travelConditions] : '' }}
              </template>
              <template v-slot:[`item.lift`]="{ item }">
                <v-icon>mdi-{{ item.wheelchairLift ? 'check' : 'close' }}</v-icon>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <!-- <v-btn class="mx-2" text @click="close">Cancel</v-btn> -->
        <v-spacer></v-spacer>
        <template v-if="selected.length">
          <v-btn
            v-if="hasDriver(selected) && !hideDrivers"
            class="mx-2"
            color="primary"
            @click="reserve(true)"
            :loading="reserving"
            :disabled="reserving || isDriverManuallyAssigned || driverConflict"
          >
            Reserve {{ selected.length }} Vehicle{{ selected.length > 1 ? 's' : '' }} & Driver(s)
          </v-btn>
          <v-btn class="mx-2" color="primary" @click="reserve(false)" :loading="reserving" :disabled="reserving">
            Reserve {{ selected.length }} Vehicle{{ selected.length > 1 ? 's' : '' }}
          </v-btn>
        </template>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { format } from 'date-fns';
import { GET_AVAILABLE_VEHICLES } from '@/store/modules/Vehicle/actions';
import AssignmentInfo from './AssignmentInfo.vue';
import { getVehicleLocation } from '@/util';
import VEHICLE_TYPES from '@/shared/types';

export default {
  name: 'FindAvailableVehicles',
  inject: ['eventHub'],
  mixins: [],
  components: { AssignmentInfo },
  props: {
    tripRequest: Object,
    tripRequestConfig: Object,
    voConfig: Object,
    reserveFromLocation: Number,
    max: Number,
    reserved: Number,
    reserving: Boolean,
    hideDrivers: { type: Boolean, default: false },
  },
  data() {
    return {
      format,
      getVehicleLocation,
      VEHICLE_TYPES,
      dialog: false,
      assignmentId: 0,
      override: false,
      leg: 0,
      available: [],
      filteredAvailable: [],
      reserveFromDepotId: 0,
      filterVehicleType: 0,
      vehicleTypeItems: [],
      allLocations: false,
      showWithLift: false,
      selected: [],
      loading: false,
      headers: [
        { text: 'Type', value: 'type' },
        { text: 'Vehicle', value: 'name' },
        { text: 'Driver', value: 'driver' },
        { text: 'Capacity', value: 'cap' },
        { text: 'Location', value: 'location' },
        { text: 'Travel Conditions', value: 'conditions' },
        { text: 'Lift', value: 'lift' },
        { text: 'WC Slots', value: 'wheelchair' },
        { text: 'Harnesses', value: 'numSafetyVest' },
        { text: 'Fold Down Seats', value: 'numFoldDownSeat' },
      ],
      travelConditions: { 1: 'In County Only', 2: 'Unlimited Mileage', 3: '50 Mile Radius' },
    };
  },
  computed: {
    ...mapGetters('driver', ['drivers', 'driversById']),
    ...mapGetters('location', ['locations', 'locationsById']),
    ...mapGetters('destination', ['destinations', 'destinationsById']),
    ...mapGetters('driver', ['driversById']),
    ...mapGetters('vehicle', ['vehicles', 'vehiclesById']),
    ...mapGetters('vehicleType', ['vehicleTypes', 'vehicleTypesById']),
    ...mapGetters('stop', ['stopsById']),
    ...mapGetters('user', ['me']),
    ...mapGetters('config', ['permissions']),
    isDriverManuallyAssigned() {
      const assignmentsMap = this.tripRequest.assignments.reduce((acc, obj) => {
        acc[obj.id] = obj;
        return acc;
      }, {});

      const assignment = assignmentsMap[this.assignmentId] || {};
      const { driverId, driver } = assignment;

      if (!driver) return false;

      return driverId === 0;
    },
    driverConflict() {
      return this.selected.some((e) => e.driverConflict);
    },
    tableHeight() {
      return window.innerHeight - 504 - (this.override ? 82 - 36 : 0 - 12);
    },
    isAdmin() {
      return this.me.is.superAdmin || this.me.is.transportationAdmin || this.me.is.limitedAdmin;
    },
  },
  methods: {
    ...mapActions('vehicle', [GET_AVAILABLE_VEHICLES]),
    async getItems() {
      let fromDate, fromTime, toDate, toTime;
      switch (this.leg) {
        case 1:
          fromDate = this.tripRequest.dropOffLegVehPickupDate;
          fromTime = this.tripRequest.dropOffLegVehPickupTime;
          toDate = this.tripRequest.dropOffLegVehReturnDate;
          toTime = this.tripRequest.dropOffLegVehReturnTime;
          break;
        case 2:
          fromDate = this.tripRequest.returnLegVehPickupDate;
          fromTime = this.tripRequest.returnLegVehPickupTime;
          toDate = this.tripRequest.returnLegVehReturnDate;
          toTime = this.tripRequest.returnLegVehReturnTime;
          break;
        default:
          fromDate = this.tripRequest.leaveDate;
          fromTime = this.tripRequest.leaveTime;
          toDate = this.tripRequest.returnDate;
          toTime = this.tripRequest.returnTime;
          break;
      }
      this.loading = true;
      if (this.tripRequest.actualTimeTBD) {
        this.available = await this.getAvailableVehicles({
          fromDate,
          fromTime: '00:00',
          toDate,
          toTime: '23:59',
          override: this.override,
        });
      } else {
        this.available = await this.getAvailableVehicles({
          fromDate,
          fromTime,
          toDate,
          toTime,
          override: this.override,
        });
      }
      this.reserveFromDepotId = this.locationsById[this.reserveFromLocation].depotId;
      if (
        (this.me.is.vehicleOwner && !this.permissions.vehicleOwner.assignAllLocations) ||
        (this.me.is.specialNeedsVehicleOwner && !this.permissions.specialNeedsVehicleOwner.assignAllLocations)
      )
        this.available.forEach((e) => {
          if (e.depotId != this.reserveFromDepotId) e.cannotAssign = true;
        });
      this.filterVehicleType = this.tripRequest.vehicleTypeId;
      this.showWithLift = this.tripRequest.needSpecialNeedsVehicle ? true : false;
      this.vehicleTypeItems = this.vehicleTypes.filter((e) => e.active && e.vehicleType === this.VEHICLE_TYPES.NORMAL);
      this.filterList(false);
      this.loading = false;
    },
    async reserve(includeDriver) {
      const objs = [];
      for (let vehicle of this.selected) {
        const a = {
          tripRequestId: this.tripRequest.id,
          vehicleId: vehicle.id,
          override: this.override,
          leg: this.leg,
        };
        if (includeDriver) {
          a.driverId = vehicle.driverId || 0;
          a.driver = vehicle.driver || null;
          a.driverEmail = this.driversById[vehicle.driverId].email || null;
        }
        if (this.assignmentId > 0) {
          a.id = this.assignmentId;
          a.raw = true;
        }
        objs.push(a);
      }
      this.$emit('reserve', objs);
      this.selected = [];
      this.close();
    },
    overrideList() {
      this.filteredAvailable = [];
      this.override = !this.override;
      this.getItems();
    },
    filterList(locations) {
      this.toggleLocations(locations);
      this.toggleLift();
      this.filterByVehicleType();
    },
    toggleLocations(toggle) {
      if (toggle) this.allLocations = !this.allLocations;
      if (this.allLocations) this.filteredAvailable = this.available;
      else this.filteredAvailable = this.available.filter((e) => e.depotId == this.reserveFromDepotId);
    },
    toggleLift() {
      if (this.showWithLift) this.filteredAvailable = this.filteredAvailable.filter((e) => e.wheelchairLift);
    },
    filterByVehicleType() {
      if (this.filterVehicleType)
        this.filteredAvailable = this.filteredAvailable.filter((e) => e.type == this.filterVehicleType);
    },
    close() {
      this.selected = [];
      this.dialog = false;
    },
    hasDriver(list) {
      for (let v of list) if (v.driverId) return true;
      return false;
    },
  },
  watch: {
    selected(val, oldVal) {
      let res = this.tripRequest.splitTrip
        ? this.tripRequest.assignments.filter((e) => e.leg == this.leg).length
        : this.reserved;
      const cap = this.assignmentId > 0 ? 1 : this.max - res;
      if (val.length > cap) {
        this.$nextTick(() => {
          this.selected = oldVal;
        });
      }
    },
    reserveFromLocation(val) {
      this.reserveFromDepotId = this.locationsById[val].depotId;
    },
  },
};
</script>

<style lang="scss">
.v-input__icon--append .v-icon {
  color: #4caf50;
}
</style>
