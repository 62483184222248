<template>
  <div class="pa-2">
    <section class="mb-4">
      <div v-if="!tripRequest.needDistrictVehicles" class="d-flex">
        <v-icon class="mr-2" color="green"> mdi-checkbox-marked-circle </v-icon>
        <h2>This trip does not require District Vehicles</h2>
      </div>

      <div v-else-if="awaitingApproval" class="d-flex">
        <v-icon class="mr-2" color="green"> mdi-alert-circle </v-icon>
        <h2>This trip requires approval first</h2>
      </div>

      <div v-else-if="!assignmentsNeeded && !driversNeeded && !vehiclesNeeded" class="d-flex">
        <v-icon class="mr-2" color="green"> mdi-checkbox-marked-circle </v-icon>
        <h2>This trip has a vehicle & driver assigned</h2>
      </div>

      <div v-else class="d-flex">
        <v-icon class="mr-2" color="blue"> mdi-alert-circle </v-icon>
        <h2>This trip requires vehicle/driver assignment</h2>
      </div>
    </section>

    <v-row>
      <v-col cols="12" md="12">
        <v-progress-linear v-if="isLoading" indeterminate></v-progress-linear>
      </v-col>
    </v-row>

    <section v-if="tripRequest.needDistrictVehicles && !awaitingApproval">
      <v-row>
        <v-col cols="12" md="12">
          <v-card
            outlined
            v-if="tripIsPickupDropoffOnly['Pickup'] || tripIsPickupDropoffOnly['Dropoff']"
            class="amber--text darken-1"
          >
            <v-alert outlined color="amber darken-2" class="pa-0 ma-0">
              <v-card-title
                ><v-icon color="warning" class="m-r-2">mdi-map-marker-alert-outline</v-icon>This trip is{{
                  tripIsPickupDropoffOnly.Dropoff ? ' dropoff ' : ' pickup '
                }}
                only.
              </v-card-title>
            </v-alert>
          </v-card>

          <v-card outlined>
            <v-card-title>Reserve Vehicles</v-card-title>

            <v-banner color="red lighten-4" v-if="!orderOfAssignmentLocations.length && !canUseVehicleLocationDropdown">
              The chosen location for this trip -- <strong>{{ tripLocationName }}</strong> -- does not have Vehicle
              Order of Assignments set up, and therefore you cannot reserve a vehicle location. Please reach out to your
              transportation admin.
            </v-banner>

            <v-card-text v-else>
              <v-row>
                <v-col cols="12" md="6">
                  <v-select
                    :disabled="!canUseVehicleLocationDropdown"
                    :hint="reserveFromLocationVehicleOwner"
                    :item-text="getLocationText"
                    :items="vehicleLocations"
                    :menu-props="{ bottom: true, offsetY: true }"
                    @change="handleLocationChange"
                    item-value="id"
                    label="Location Used to Reserve Vehicles"
                    outlined
                    persistent-hint
                    v-model="reserveFromLocation"
                  ></v-select>
                </v-col>

                <v-col cols="auto">
                  <v-btn
                    :disabled="disableNext"
                    :loading="updatingReserveLocation"
                    @click="nextLocation(false)"
                    class="mt-2"
                    color="primary"
                  >
                    Next Location
                  </v-btn>
                </v-col>

                <v-col cols="auto" v-show="canUseVehicleLocationDropdown">
                  <v-btn
                    :disabled="!canAssign || !reserveLocationChanged"
                    :loading="savingReserveLocation"
                    @click="nextLocation(true)"
                    class="mt-2"
                    color="success"
                  >
                    Save Reserve Location
                  </v-btn>
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="12" md="3">
                  <div class="text-subtitle-2 font-weight-bold">Trip Location Zone</div>
                  <div class="text-subtitle-2 font-weight-bold">Bid ID</div>
                  <div class="text-subtitle-2 font-weight-bold">
                    # Vehicles Needed {{ tripRequest.splitTrip ? '(Per Leg)' : '' }}
                  </div>
                  <div class="text-subtitle-2 font-weight-bold" v-if="tripRequest.splitTrip">Split Trip</div>
                </v-col>

                <v-col cols="12" md="3">
                  <div class="text-subtitle-2 font-weight-normal">{{ tripZone }}</div>
                  <div class="text-subtitle-2 font-weight-normal">xx-xx-xx</div>
                  <div class="text-subtitle-2 font-weight-normal">{{ tripRequest.numVehicles }}</div>
                  <div class="text-subtitle-2 font-weight-normal" v-if="tripRequest.splitTrip">Yes</div>
                </v-col>

                <v-col cols="12" md="3">
                  <div class="text-subtitle-2 font-weight-bold">Trip Year/Week</div>
                  <div class="text-subtitle-2 font-weight-bold">Trip Hours</div>
                  <div class="text-subtitle-2 font-weight-bold">Number w/ Lift</div>
                </v-col>

                <v-col cols="12" md="3">
                  <div class="text-subtitle-2 font-weight-normal">YYYY-MM</div>
                  <div class="text-subtitle-2 font-weight-normal">
                    {{ tripHours }} <span v-if="tripRequest.actualTimeTBD" class="red--text">*Actual Time TBD</span>
                  </div>
                  <div class="text-subtitle-2 font-weight-normal">0</div>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>

      <v-row v-if="tripRequest.splitTrip && assignments.length === 0 && !showVehicleAndDriverAssignmentButtons">
        <v-col cols="12" md="6">
          <v-btn
            color="primary"
            class="mx-2"
            @click="showVehicleAndDriverAssignment()"
            :disabled="!canAssign || disableAssignments"
          >
            Click to Show Vehicle and Driver assignment buttons
          </v-btn>
        </v-col>
      </v-row>

      <v-row v-else-if="tripRequest.splitTrip">
        <v-col cols="12" md="6" v-if="doLegAssignmentsNeeded">
          <div class="subtitle-2 leg-label">Drop-Off Leg:</div>

          <template v-if="assignmentsNeeded">
            <v-btn
              color="primary"
              class="mx-2"
              @click="openFAV(0, 1)"
              :disabled="!canAssign || disableAssignments || !reserveFromLocation"
            >
              {{ favText }}
            </v-btn>

            <v-btn
              color="primary"
              class="mx-2"
              @click="openFAD(0, 1)"
              :disabled="!canAssign || disableAssignments"
              v-if="driverConfig.useAvailableDrivers"
            >
              {{ fadText }}
            </v-btn>
          </template>
        </v-col>
        <v-col cols="12" md="6" v-else> <v-text></v-text></v-col>

        <v-col cols="12" md="6" v-if="rLegAssignmentsNeeded">
          <div class="subtitle-2 leg-label">Return Leg:</div>

          <template v-if="assignmentsNeeded">
            <v-btn
              color="primary"
              class="mx-2"
              @click="openFAV(0, 2)"
              :disabled="!canAssign || disableAssignments || !reserveFromLocation"
            >
              {{ favText }}
            </v-btn>

            <v-btn
              color="primary"
              class="mx-2"
              @click="openFAD(0, 2)"
              :disabled="!canAssign || disableAssignments"
              v-if="driverConfig.useAvailableDrivers"
            >
              {{ fadText }}
            </v-btn>
          </template>
        </v-col>
        <v-col cols="12" md="6" v-else> <v-text></v-text></v-col>
      </v-row>

      <v-row v-else-if="tripRequest.vehicleType !== VEHICLE_TYPES.NORMAL && tripRequest.additionalTransportationId">
        <v-col cols="12" md="6" v-if="assignmentsNeeded">
          <v-btn
            color="primary"
            @click="addAgencyAssignments()"
            :disabled="
              isLoading ||
              !canAssign ||
              disableAssignments ||
              (tripRequest.status == -3 && !isAdmin && !vehicleOwnerConfig.assignCanceled)
            "
          >
            Fulfill Assignments From {{ vehicleTypesById[tripRequest.vehicleTypeId].name }}
          </v-btn>
        </v-col>
      </v-row>

      <v-row v-else>
        <v-col cols="12" md="6">
          <template v-if="assignmentsNeeded">
            <v-btn
              color="primary"
              @click="openFAV(0)"
              :disabled="
                !canAssign ||
                disableAssignments ||
                (tripRequest.status == -3 && !isAdmin && !vehicleOwnerConfig.assignCanceled) ||
                !reserveFromLocation
              "
            >
              {{ favText }}
            </v-btn>
          </template>
        </v-col>

        <v-col cols="12" md="6" v-if="driverConfig.useAvailableDrivers">
          <template v-if="assignmentsNeeded">
            <v-btn
              color="primary"
              @click="openFAD(0)"
              :disabled="
                !canAssign ||
                disableAssignments ||
                (tripRequest.status == -3 && !isAdmin && !vehicleOwnerConfig.assignCanceled) ||
                (!tripRequest.needDriverAssigned && !driverConfig.useAvailableDrivers)
              "
              v-if="driverConfig.useAvailableDrivers"
            >
              {{ fadText }}
            </v-btn>
          </template>
        </v-col>

        <v-col v-else-if="!assignments.length || assignments.length < tripRequest.numVehicles">
          <v-row>
            <v-col cols="9">
              <v-row>
                <v-col cols="12" md="6">
                  <v-combobox
                    v-model="driver.details"
                    :items="driverItems"
                    :item-text="getDriverText"
                    label="Driver"
                    return-object
                    v-bind="inputProps"
                    @keydown="driver.details = null"
                    @change="driver.email = driver.details?.email || driver.email"
                    :disabled="!canAssign"
                  />
                </v-col>

                <v-col cols="12" md="6">
                  <v-text-field
                    v-model="driver.email"
                    v-bind="inputProps"
                    label="Driver Email"
                    :disabled="!canAssign"
                  />
                </v-col>
              </v-row>
            </v-col>

            <v-col cols="3" class="d-flex align-center justify-center">
              <v-btn block color="primary" :disabled="isReserveDriverDisabled" @click="reserveDriver">
                Reserve Driver
              </v-btn>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" md="12" v-for="(assignment, i) of assignments" :key="i">
          <trip-request-assignment-card
            :key="i"
            :assignment="assignment"
            :tripRequest="tripRequest"
            :index="i"
            :isLoading="isLoading"
            :isLoadingFAD="isLoadingFAD"
            :isLoadingVehicle="isLoadingVehicle"
            :driverItems="driverItems"
            :mechanicItems="mechanicItems"
            @adminDecline="adminDecline"
            @openFAD="openFAD"
            @openFAV="openFAV"
            @reserve="reserve"
            @refresh="refresh"
          ></trip-request-assignment-card>
        </v-col>
      </v-row>
      <v-row v-if="declinedAssignments.length > 0" dense>
        <v-col cols="12" md="6">
          <v-btn color="primary" class="mt-2" text @click="toggleDeclinedAssignments">
            {{ showDeclinedAssignments ? 'Hide' : 'Show' }} Declined Assignments
          </v-btn>
        </v-col>
      </v-row>
      <v-row v-show="showDeclinedAssignments">
        <v-col cols="12" md="12" v-for="(assignment, i) of declinedAssignments" :key="i">
          <trip-request-assignment-card
            :key="i"
            :assignment="assignment"
            :tripRequest="tripRequest"
            :index="i"
            :isLoading="isLoading"
            :isLoadingFAD="isLoadingFAD"
            :isLoadingVehicle="isLoadingVehicle"
            :driverItems="driverItems"
            :mechanicItems="mechanicItems"
            @adminDecline="adminDecline"
            @openFAD="openFAD"
            @openFAV="openFAV"
            @reserve="reserve"
            @refresh="refresh"
          ></trip-request-assignment-card>
        </v-col>
      </v-row>
    </section>

    <find-available-vehicles
      ref="fav"
      :tripRequest="tripRequest"
      :tripRequestConfig="tripRequestConfig"
      :voConfig="permissions.vehicleOwner"
      :reserveFromLocation="reserveFromLocation"
      :max="maxAssignments"
      :reserved="assignments.length"
      :reserving="reserving"
      @reserve="reserve"
    ></find-available-vehicles>

    <find-available-drivers
      ref="fad"
      :tripRequest="tripRequest"
      :tripRequestConfig="tripRequestConfig"
      :driverConfig="driverConfig"
      :reserveFromLocation="reserveFromLocation"
      :max="maxAssignments"
      :reserved="assignments.length"
      :reserving="reserving"
      @reserve="reserve"
    ></find-available-drivers>

    <decline-assignment ref="decline" @refresh="refresh"></decline-assignment>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
import { format, differenceInMinutes } from 'date-fns';

import { SAVE_ASSIGNMENT, ACCEPT_ASSIGNMENT, DELETE_ASSIGNMENT } from '@/store/modules/Assignment/actions';
import { UPDATE_ASSIGNMENT_LOCATION } from '@/store/modules/TripRequest/actions';
import { GET_MECHANICS, SAVE_MECHANIC } from '@/store/modules/Mechanic/actions';

import TripRequestAssignmentCard from './TripRequestAssignmentCard.vue';
import FindAvailableVehicles from '@/components/FindAvailableVehicles.vue';
import FindAvailableDrivers from '@/components/FindAvailableDrivers.vue';
import DeclineAssignment from '@/components/DeclineAssignment.vue';

import VEHICLE_TYPES from '@/shared/types';
import { ASSIGNMENT_STATUS } from '@/shared/common';

import { getVehicleOwner, inputProps } from '@/util';
import { validateSplitTripDates } from '@/util/tripRequest';
import { GET_LOCATION_VEHICLE_ORDER_OF_ASSIGNMENTS } from '@/store/modules/Location/actions';

const FAV_TEXT = 'Find Available Vehicles';
const FAD_TEXT = 'Find Available Drivers';

export default {
  name: 'TripRequestAssignments',
  inject: ['eventHub'],
  mixins: [],
  components: {
    TripRequestAssignmentCard,
    FindAvailableVehicles,
    FindAvailableDrivers,
    DeclineAssignment,
  },
  props: {
    tripRequest: Object,
    tripRequestConfig: Object,
  },
  data() {
    return {
      assignment: {},
      disableAssignments: false,
      driver: { email: null, details: null },
      fadText: FAD_TEXT,
      favText: FAV_TEXT,
      format,
      inputProps,
      isLoading: false, // Track loading state
      isLoadingFAD: false,
      isLoadingVehicle: false, // Track loading state
      mechanicItems: [],
      reserveFromLocation: 0,
      reserveFromLocationVehicleOwner: '',
      reserveLocationChanged: false,
      reserving: false,
      savingReserveLocation: false,
      showDeclinedAssignments: false,
      showVehicleAndDriverAssignmentButtons: false,
      updatingReserveLocation: false,
      VEHICLE_TYPES,
      vehicleLocations: [],
    };
  },
  mounted() {
    this.init();
  },
  computed: {
    ...mapGetters('additionalTransportation', ['additionalTransportationsById']),
    ...mapGetters('config', ['driverConfig', 'vehicleOwnerConfig', 'permissions', 'schoolFinanceDriverEditable']),
    ...mapGetters('destination', ['destinations', 'destinationsById']),
    ...mapGetters('driver', ['drivers', 'driversById']),
    ...mapGetters('location', ['locations', 'locationsById']),
    ...mapGetters('location', { stateVehicleLocations: 'vehicleLocations' }),
    ...mapGetters('mechanic', ['mechanicsList']),
    ...mapGetters('stop', ['stopsById']),
    ...mapGetters('tripRequest', ['tripRequestsById']),
    ...mapGetters('user', ['me', 'vehicleOwners']),
    ...mapGetters('vehicle', ['vehiclesById']),
    ...mapGetters('vehicleType', ['vehicleTypesById']),
    assignments() {
      return this.tripRequest.assignments.filter(
        (e) => e.status != ASSIGNMENT_STATUS.SELF_DECLINED && e.status != ASSIGNMENT_STATUS.ADMIN_DECLINED
      );
    },
    declinedAssignments() {
      return this.tripRequest.assignments.filter(
        (e) => e.status == ASSIGNMENT_STATUS.SELF_DECLINED || e.status == ASSIGNMENT_STATUS.ADMIN_DECLINED
      );
    },
    canAssign() {
      // Requester has no editable permission in assignments
      return (
        this.me.is.superAdmin ||
        this.me.is.transportationAdmin ||
        this.me.is.vehicleOwner ||
        this.me.is.specialNeedsVehicleOwner ||
        this.schoolFinanceDriverEditable ||
        this.tripRequest.permissions.canAssign
      );
    },
    canUseVehicleLocationDropdown() {
      return this.canAssign && (this.isAdmin || this.vehicleOwnerCanUseVehicleDropdown);
    },
    disableNext() {
      return (
        !this.canAssign ||
        this.isLastVehicleLocation ||
        !this.vehicleLocations[this.currentLocationIndex]?.isOrderOfAssignment ||
        !this.vehicleLocations[this.currentLocationIndex + 1]?.isOrderOfAssignment
      );
    },
    orderOfAssignmentLocations() {
      return this.vehicleLocations.filter((record) => record.isOrderOfAssignment);
    },
    currentLocationIndex() {
      return this.vehicleLocations.findIndex((location) => location.id === this.reserveFromLocation);
    },
    isAdmin() {
      return this.me.is.superAdmin || this.me.is.transportationAdmin || this.me.is.limitedAdmin;
    },
    isLastVehicleLocation() {
      return this.currentLocationIndex === this.lastVehicleLocationIndex;
    },
    isReserveDriverDisabled() {
      return this.driver.details === null || !this.canAssign;
    },
    lastVehicleLocationIndex() {
      return -1 & (this.vehicleLocations.length - 1);
    },
    vehicleOwnerCanUseVehicleDropdown() {
      return (
        (this.me.is.specialNeedsVehicleOwner && this.permissions.specialNeedsVehicleOwner.anyVehicleLocation) ||
        (this.me.is.vehicleOwner && this.permissions.vehicleOwner.anyVehicleLocation)
      );
    },
    tripLocationName() {
      return this.locationsById[this.tripRequest.locationId].name;
    },
    awaitingApproval() {
      const approval = this.tripRequest.approval;
      const currentApprovalLevel = approval?.awaitingApproval?.id;

      if (!currentApprovalLevel) return false;

      const levelInApprovalLevelList = approval.requiredApprovalLevels.find(
        (level) => level.approvalLevelId === currentApprovalLevel
      );

      const position = approval.requiredApprovalLevels.indexOf(levelInApprovalLevelList);

      for (let i = position; i < approval.requiredApprovalLevels.length; i++) {
        if (approval.requiredApprovalLevels[i]?.canAssign) {
          return true;
        }
      }

      return false;
    },
    tripIsPickupDropoffOnly() {
      return {
        Pickup: !!this.tripRequest.pickupOnly,
        Dropoff: !!this.tripRequest.dropoffOnly,
      };
    },
    doLegAssignmentsNeeded() {
      const doAssignments = this.assignments.filter((e) => e.leg == 1);
      return doAssignments.length != this.tripRequest.numVehicles;
    },
    rLegAssignmentsNeeded() {
      const rAssignments = this.assignments.filter((e) => e.leg == 2);
      return rAssignments.length != this.tripRequest.numVehicles;
    },
    assignmentsNeeded() {
      if (this.tripRequest.splitTrip) return this.doLegAssignmentsNeeded || this.rLegAssignmentsNeeded;
      else if (!this.assignments.length && !this.tripRequest.numVehicles) return false;
      else return this.assignments.length < this.tripRequest.numVehicles;
    },
    driversNeeded() {
      if (this.assignments.length) {
        const driverNeeded = this.assignments.filter((assignment) => assignment.driverId == 0);
        return driverNeeded.length ? true : false;
      }
      return true;
    },
    vehiclesNeeded() {
      if (this.assignments.length) {
        const vehicleNeeded = this.assignments.filter((assignment) => assignment.vehicleId == 0);
        return vehicleNeeded.length ? true : false;
      }
      return true;
    },
    maxAssignments() {
      return this.tripRequest.numVehicles;
    },
    tripZone() {
      let zone;

      if (this.tripRequestConfig.other.determineZoneBy === 'request')
        zone = this.locationsById[this.tripRequest.locationId].zone;
      else if (this.tripRequestConfig.other.determineZoneBy === 'vehicle')
        zone = this.locationsById[this.reserveFromLocation].zone;

      return zone || 'no zone';
    },
    tripHours() {
      const start = new Date(`${this.tripRequest.leaveDate}, ${this.tripRequest.leaveTime}`);
      const end = new Date(`${this.tripRequest.returnDate}, ${this.tripRequest.returnTime}`);
      const diff = differenceInMinutes(end, start);

      return Math.round((diff / 60 + Number.EPSILON) * 100) / 100;
    },
  },
  created() {
    this.init();
  },
  methods: {
    ...mapActions('assignment', [SAVE_ASSIGNMENT, ACCEPT_ASSIGNMENT, DELETE_ASSIGNMENT]),
    ...mapActions('mechanic', [GET_MECHANICS, SAVE_MECHANIC]),
    ...mapActions('location', [GET_LOCATION_VEHICLE_ORDER_OF_ASSIGNMENTS]),
    ...mapActions('tripRequest', [UPDATE_ASSIGNMENT_LOCATION]),
    ...mapMutations('assignment', ['updateAssignment', 'removeAssignmentById']),
    ...mapMutations('tripRequest', ['updateTripRequest']),
    resetDriver() {
      this.driver.email = null;
    },
    async reserveDriver() {
      const { details } = this.driver;

      const isDriverFromDb = !!details.id;
      const driverName = `${details?.firstName} ${details?.lastName}`;
      const assignment = [
        {
          tripRequestId: this.tripRequest.id,
          vehicleId: 0,
          override: false,
          driverId: details?.id || 0,
          driver: isDriverFromDb ? driverName : details,
          driverEmail: this.driver.email || details?.email || null,
        },
      ];

      await this.reserve(assignment);

      this.resetDriver();
    },
    async init() {
      this.requestLocationVehicleAssignments();

      if (!this.assignments) this.assignments = Array.from(Array(this.tripRequest.numVehicles));

      this.getReserveFromLocation();

      const activeDrivers = this.drivers.filter((driver) => driver.active);

      this.driverItems = [{ header: 'Select an existing Driver or enter a name' }, ...activeDrivers];

      if (this.tripRequest.vehicleType === this.VEHICLE_TYPES.NORMAL) return;

      await this.getMechanics();

      this.mechanicItems = [{ header: 'Select an existing Mechanic or enter a name' }, ...this.mechanicsList];
    },
    async requestLocationVehicleAssignments() {
      this[GET_LOCATION_VEHICLE_ORDER_OF_ASSIGNMENTS](this.tripRequest.locationId)
        .then((response) => (this.vehicleLocations = response))
        .catch(() => {});
    },
    adminDecline(assignmentId) {
      this.$refs.decline.assignmentId = assignmentId;
      this.$refs.decline.adminDecline = true;
      this.$refs.decline.dialog = true;
    },
    getReserveFromLocation() {
      this.reserveFromLocation = this.tripRequest.assignmentLocationId || this.tripRequest.locationId || 0;

      const owner = getVehicleOwner(this.tripRequest);

      if (owner) {
        this.reserveFromLocationVehicleOwner = owner.displayName
          ? `${owner.displayName} (${owner.email})` || owner.email
          : '';
      }
    },
    handleLocationChange(locationId) {
      this.disableAssignments = !(
        this.isAdmin ||
        (this.me.is.vehicleOwner && this.permissions.vehicleOwner.assignAllLocations) ||
        (this.me.is.specialNeedsVehicleOwner && this.permissions.specialNeedsVehicleOwner.assignAllLocations)
      );

      this.$refs.fav.getItems();

      const owner = getVehicleOwner({ ...this.tripRequest, assignmentLocationId: locationId });

      if (owner)
        this.reserveFromLocationVehicleOwner = owner.displayName
          ? `${owner.displayName} (${owner.email})` || owner.email
          : '';

      this.reserveLocationChanged = true;
    },
    refresh() {
      this.$emit('refreshTripRequest');
      this.$refs.fad.getItems();
      this.$refs.fav.getItems();
      this.$emit('refreshTripRequests');
    },
    async nextLocation(useSelected) {
      if (this.savingReserveLocation || this.updatingReserveLocation) return;

      if (useSelected) this.savingReserveLocation = true;
      else this.updatingReserveLocation = true;

      let nextLocationId = 0;

      if (useSelected) {
        nextLocationId = this.reserveFromLocation;
      } else {
        const nextLocationIndex = this.currentLocationIndex + 1;
        const nextLocation = this.vehicleLocations[nextLocationIndex];

        nextLocationId = nextLocation.id;
      }

      try {
        const r = await this.updateAssignmentLocation({
          tripRequestId: this.tripRequest.id,
          assignmentLocationId: nextLocationId,
        });

        if (r && r.done) {
          this.updateTripRequest({ ...this.tripRequest, assignmentLocationId: nextLocationId });

          const owner = this.vehicleOwners.find((e) => e.locationId == nextLocationId);

          if (owner) {
            this.reserveFromLocationVehicleOwner = owner.displayName
              ? `${owner.displayName} (${owner.userEmail})` || owner.userEmail
              : '';
          }

          this.$myalert.success('Assignment Location changed & Vehicle Owner notified', true);
          this.$emit('refreshTripRequest');
          this.reserveFromLocation = nextLocationId;
        }
      } catch (error) {
        this.$myalert.error(error.message);
      } finally {
        this.reserveLocationChanged = false;
        this.savingReserveLocation = false;
        this.updatingReserveLocation = false;
      }
    },
    async openFAV(updateId, leg) {
      this.isLoadingVehicle = true;
      this.$refs.fav.assignmentId = updateId;
      this.$refs.fav.leg = leg;
      await this.$refs.fav.getItems();
      this.isLoadingVehicle = false;
      this.$refs.fav.dialog = true;
    },
    async openFAD(updateId, leg, assistant) {
      this.isLoadingFAD = true;

      this.$refs.fad.assignmentId = updateId;
      this.$refs.fad.leg = leg;
      this.$refs.fad.assistant = assistant;
      this.$refs.fad.max = assistant ? 1 : this.maxAssignments;
      await this.$refs.fad.getItems();
      this.isLoadingFAD = false;
      this.$refs.fad.dialog = true;
    },
    async addAgencyAssignments() {
      const assignments = [];
      const at = this.additionalTransportationsById[this.tripRequest.additionalTransportationId];
      for (let i = 0; i < this.tripRequest.numVehicles; i++)
        assignments.push({
          tripRequestId: this.tripRequest.id,
          vehicleId: 0,
          vehicle: `${at.type[0].toUpperCase() + at.type.slice(1)} (${at.name})`,
          driver: 'Provided',
        });
      await this.reserve(assignments);
    },
    async reserve(objs) {
      this.isLoading = true;
      this.reserving = true;

      try {
        for (let obj of objs) {
          this.handleDriverAdded(obj);

          const { enableSave, ...assignment } = obj;

          await this[SAVE_ASSIGNMENT](assignment);
        }

        this.$myalert.success(`Assignment${objs.length > 1 ? 's' : ''} saved`);

        this.refresh();
      } catch (error) {
        this.$myalert.error(error.message);
      } finally {
        this.isLoading = false;
        this.reserving = false;
      }
    },
    toggleDeclinedAssignments() {
      this.showDeclinedAssignments = !this.showDeclinedAssignments;
    },
    getLocationText(location) {
      return location.name + ' (' + location.code + ')';
    },
    getDriverText(driver) {
      return [driver?.firstName, driver?.lastName].join(' ').trim();
    },
    getMechanicText(mechanic) {
      return mechanic?.name || '';
    },
    handleDriverAdded(assignment) {
      const isDriverManuallyTyped = assignment.driver?.id === undefined;

      const driver = {
        id: assignment.driver?.id,
        email: assignment.driverEmail || assignment.driver?.email,
        name: isDriverManuallyTyped ? assignment.driver : this.getDriverText(assignment.driver),
      };

      if (!driver.name) return;

      if (driver.id) assignment.driverId = driver.id;

      assignment.driver = driver.name;
      assignment.driverEmail = driver.email || null;
      assignment.enableSave = true;
    },
    showVehicleAndDriverAssignment() {
      // validate split trip date
      const errorMessage = validateSplitTripDates(this.tripRequest);
      if (errorMessage) {
        this.$myalert.error(errorMessage);
      } else {
        this.showVehicleAndDriverAssignmentButtons = true;
      }
    },
  },
};
</script>

<style scoped>
.leg-label {
  display: inline-block;
  width: 90px;
  margin-right: 10px;
}
</style>
