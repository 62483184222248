<template>
  <v-dialog v-model="dialog" max-width="1300px" no-click-animation persistent scrollable fullscreen>
    <v-card>
      <v-card-title>
        <v-btn icon @click="close" class="mr-2">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        {{ override ? 'Reserve any Driver' : 'Find Available Drivers' }}
      </v-card-title>

      <v-card-text class="pb-0">
        <v-row v-if="override">
          <v-col cols="12" md="12">
            <v-alert class="mb-0" outlined type="info" text>
              This list DOES NOT check for driver availability!
            </v-alert>
          </v-col>
        </v-row>

        <v-row class="mt-0">
          <v-col cols="12" md="12">
            <assignment-info
              :leg="leg"
              :tripRequest="tripRequest"
              :tripRequestConfig="tripRequestConfig"
              :reserveFromLocation="reserveFromLocation"
            ></assignment-info>
          </v-col>
        </v-row>

        <v-row class="mt-0" dense>
          <v-col cols="auto" v-if="isAdmin || (me.is.vehicleOwner && permissions.vehicleOwner.overrideDriver)">
            <v-btn class="mt-3" color="primary" @click="overrideList()">
              {{ override ? 'Check Availability' : 'Override' }}
            </v-btn>
          </v-col>

          <v-col cols="auto">
            <v-btn class="mt-3" color="primary" @click="filterList(true)">
              {{ showAllZones ? 'Trip Zone' : 'All Zones' }}
            </v-btn>
          </v-col>

          <v-spacer></v-spacer>

          <v-col cols="12" md="3">
            <v-combobox
              v-model="nameFilter"
              :value="nameFilter"
              label="Filter by name"
              :items="filteredAvailable"
              :item-text="getDriverText"
              item-value="id"
              clearable
              outlined
              hide-details
              @change="filterByName"
            ></v-combobox>
          </v-col>

          <v-col cols="12" md="3">
            <v-select
              :menu-props="{ bottom: true, offsetY: true }"
              v-model="filterLocation"
              :items="locations"
              :item-text="getLocationText"
              item-value="id"
              label="Location"
              outlined
              clearable
              hide-details
              @change="filterList(false)"
            ></v-select>
          </v-col>

          <v-col cols="12" md="2">
            <v-select
              :menu-props="{ bottom: true, offsetY: true }"
              v-model="filterDesignation"
              :items="['All', 'None', ...designations]"
              label="Designation"
              outlined
              clearable
              hide-details
              @change="filterList(false)"
            ></v-select>
          </v-col>
        </v-row>

        <v-row class="mt-0">
          <v-col cols="12" md="12" class="pb-0">
            <v-radio-group v-model="sort" row @change="handleSort" class="mt-0" hide-details>
              <v-radio label="Sort By Rotation" value="rotation"></v-radio>
              <v-radio label="Sort By Name" value="name"></v-radio>
            </v-radio-group>
          </v-col>
        </v-row>

        <v-row class="mt-0">
          <v-col cols="12" md="12">
            <v-data-table
              v-model="selected"
              fixed-header
              :headers="headers"
              :items="filteredAvailable"
              hide-default-footer
              :items-per-page="filteredAvailable.length"
              item-key="id"
              show-select
              disable-sort
              no-data-text="No drivers available"
              :height="tableHeight"
              :loading="loading"
            >
              <template v-slot:[`header.data-table-select`]></template>

              <template v-slot:[`header.bus`]="{ header }">
                <v-tooltip right color="rgb(227, 227, 227)">
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon color="primary" dark v-bind="attrs" v-on="on"> mdi-information </v-icon>
                    {{ header.text }}
                  </template>

                  <p class="black--text">Matches Lift & Vehicle Type</p>
                  <p class="font-weight-bold amber--text text--darken-2">Lift mismatch</p>
                  <p class="font-weight-bold deep-purple--text text--darken-3">Vehicle Type mismatch</p>
                  <p class="font-weight-bold red--text text--darken-1">Lift & Vehicle Type mismatch</p>
                </v-tooltip>
              </template>

              <template v-slot:[`item.name`]="{ item }"> {{ item.firstName + ' ' + item.lastName }} </template>

              <template v-slot:[`item.tooltip`]="{ item }">
                <v-tooltip right>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon color="primary" dark v-bind="attrs" v-on="on"> mdi-information </v-icon>
                  </template>

                  <p>
                    <strong>Base Location: </strong>
                    {{
                      locationsById[item.baseLocationId] ? locationsById[item.baseLocationId].name : '(not assigned)'
                    }}
                  </p>

                  <p v-if="item.secondaryLocationId">
                    <strong>Secondary Location: </strong>{{ locationsById[item.secondaryLocationId].name }}
                  </p>
                </v-tooltip>
              </template>

              <template v-slot:[`item.bus`]="{ item }">
                <v-tooltip right :disabled="!item.vehicleConflict">
                  <template v-slot:activator="{ on, attrs }">
                    <span :class="item.vehicleColor">
                      {{ vehiclesById[item.assignedVehicleId] ? vehiclesById[item.assignedVehicleId].name : '-' }}
                      <v-icon v-if="item.vehicleConflict" color="warning" dark v-bind="attrs" v-on="on">
                        mdi-alert
                      </v-icon>
                    </span>
                  </template>
                  This vehicle cannot be assigned
                </v-tooltip>
              </template>

              <template v-slot:[`item.regHours`]="{ item }">
                {{ item.regHoursWeek }}
              </template>

              <template v-slot:[`item.am`]="{ item }">
                <v-icon>mdi-{{ item.prefAM ? 'check' : 'close' }}</v-icon>
              </template>

              <template v-slot:[`item.pm`]="{ item }">
                <v-icon>mdi-{{ item.prefPM ? 'check' : 'close' }}</v-icon>
              </template>

              <template v-slot:[`item.midday`]="{ item }">
                <v-icon>mdi-{{ item.prefMidDay ? 'check' : 'close' }}</v-icon>
              </template>

              <template v-slot:[`item.overnight`]="{ item }">
                <v-icon>mdi-{{ item.prefOvernight ? 'check' : 'close' }}</v-icon>
              </template>

              <template v-slot:[`item.weekend`]="{ item }">
                <v-icon>mdi-{{ item.prefWeekend ? 'check' : 'close' }}</v-icon>
              </template>

              <template v-slot:[`item.nonSchool`]="{ item }">
                <v-icon>mdi-{{ item.prefNonSchool ? 'check' : 'close' }}</v-icon>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </v-card-text>

      <v-divider></v-divider>

      <v-card-actions>
        <v-text-field
          v-model.trim="driverLogNotes"
          :value="driverLogNotes"
          label="Log Notes"
          outlined
          hide-details
        ></v-text-field>
        <v-btn class="mx-2" text @click="submitLogNotes">Save</v-btn>
        <v-spacer></v-spacer>

        <template v-if="selected.length">
          <v-btn
            v-if="!assistant && hasVehicle(selected)"
            class="mx-2"
            color="primary"
            @click="reserve(true)"
            :loading="reserving"
            :disabled="reserving || vehicleConflict"
          >
            Reserve {{ selected.length }} Driver{{ selected.length > 1 ? 's' : '' }} & Vehicle(s)
          </v-btn>

          <v-btn class="mx-2" color="primary" @click="reserve(false)" :loading="reserving" :disabled="reserving">
            Reserve {{ selected.length }} Driver{{ selected.length > 1 ? 's' : '' }}
            {{ assistant ? 'as Assistant' : '' }}
          </v-btn>
        </template>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { format } from 'date-fns';
import { sortBy } from 'lodash';
import { GET_AVAILABLE_DRIVERS } from '@/store/modules/Driver/actions';
import AssignmentInfo from './AssignmentInfo.vue';

export default {
  name: 'FindAvailableDrivers',
  inject: ['eventHub'],
  mixins: [],
  components: { AssignmentInfo },
  props: {
    tripRequest: Object,
    tripRequestConfig: Object,
    driverConfig: Object,
    reserveFromLocation: Number,
    max: Number,
    reserved: Number,
    reserving: Boolean,
  },
  data() {
    return {
      format,
      sortBy,
      dialog: false,
      assignmentId: 0,
      override: false,
      leg: 0,
      assistant: false,
      available: [],
      filteredAvailable: [],
      showAllZones: false,
      filterLocation: 0,
      filterDesignation: 0,
      nameFilter: '',
      sort: 'rotation',
      selected: [],
      driverLogNotes: '',
      loading: false,
    };
  },
  computed: {
    ...mapGetters('driver', ['drivers', 'driversById', 'designations']),
    ...mapGetters('location', ['locations', 'locationsById']),
    ...mapGetters('destination', ['destinations', 'destinationsById']),
    ...mapGetters('vehicle', ['vehiclesById']),
    ...mapGetters('vehicleType', ['vehicleTypesById']),
    ...mapGetters('user', ['me']),
    ...mapGetters('config', ['permissions']),
    tableHeight() {
      // allow table height to increase further than the actual computed one for lower resolution devices
      let offset = 0;
      if (window.innerHeight < 800) {
        offset = 250;
      }
      return window.innerHeight - 606 - (this.override ? 82 - 44 : 0 - 20) + offset;
    },
    isAdmin() {
      return this.me.is.superAdmin || this.me.is.transportationAdmin || this.me.is.limitedAdmin;
    },
    headers() {
      const h = [
        { text: 'Driver', value: 'name' },
        { text: 'Sen.', value: 'seniority' },
        { text: 'Zone', value: 'zone' },
        { text: 'Bus', value: 'bus' },
        { text: 'Designation', value: 'designation' },
        // { text: this.driverConfig.routingDriverHoursLabel || 'Route Hours', value: 'regHours' },
      ];
      if (this.driverConfig.defaultSortRotation === 'weekly') {
        h.push({ text: 'Trips this Week', value: 'tripsThisWeek' });
        h.push({ text: 'Hours this Week', value: 'hoursThisWeek' });
      } else {
        h.push({ text: 'Total Trips', value: 'totalTrips' });
        h.push({ text: 'Total Hours', value: 'totalHours' });
      }
      if (this.driverConfig.prefLabel1.length) h.push({ text: this.driverConfig.prefLabel1, value: 'am' });
      if (this.driverConfig.prefLabel2.length) h.push({ text: this.driverConfig.prefLabel2, value: 'midday' });
      if (this.driverConfig.prefLabel3.length) h.push({ text: this.driverConfig.prefLabel3, value: 'pm' });
      if (this.driverConfig.prefLabel4.length) h.push({ text: this.driverConfig.prefLabel4, value: 'overnight' });
      if (this.driverConfig.prefLabel5.length) h.push({ text: this.driverConfig.prefLabel5, value: 'weekend' });
      if (this.driverConfig.prefLabel6.length) h.push({ text: this.driverConfig.prefLabel6, value: 'nonSchool' });
      h.concat([
        { text: 'Total Trips', value: 'totalTrips' },
        { text: 'Total Hours', value: 'totalHours' },
      ]);
      if (this.driverConfig.driverTooltip) h.splice(1, 0, { text: '', value: 'tooltip' });
      return h;
    },
    vehicleConflict() {
      return this.selected.some((e) => e.vehicleConflict);
    },
  },
  created() {
    this.getItems();
  },
  methods: {
    ...mapActions('driver', [GET_AVAILABLE_DRIVERS]),
    ...mapActions('driverLog', ['saveDriverLog']),
    async getItems() {
      this.loading = true;

      if (this.tripRequest.actualTimeTBD) {
        this.available = await this.getAvailableDrivers({
          fromDate: this.tripRequest.leaveDate,
          fromTime: '00:00',
          toDate: this.tripRequest.returnDate,
          toTime: '23:59',
          override: this.override,
        });
      } else {
        this.available = await this.getAvailableDrivers({
          fromDate: this.tripRequest.leaveDate,
          fromTime: this.tripRequest.leaveTime,
          toDate: this.tripRequest.returnDate,
          toTime: this.tripRequest.returnTime,
          override: this.override,
        });
      }

      this.available.forEach((e, i) => {
        e.rotationOrder = i;
        e.vehicleColor = this.getVehicleColor(e.assignedVehicleId);
      });

      this.filterDesignation = this.driverConfig.defaultDesignation;
      this.filterList(false);
      this.loading = false;
    },
    overrideList() {
      this.filteredAvailable = [];
      this.override = !this.override;
      this.getItems();
    },
    filterList(toggle) {
      this.toggleZones(toggle);
      this.triggerFilters();
      this.handleSort();
    },
    toggleZones(toggle) {
      if (toggle) this.showAllZones = !this.showAllZones;

      if (this.showAllZones) {
        this.filteredAvailable = [...this.available];
      } else {
        const filterZone =
          this.tripRequestConfig.other.determineZoneBy == 'request'
            ? this.locationsById[this.tripRequest.locationId].zone
            : this.locationsById[this.reserveFromLocation].zone;

        this.filteredAvailable = this.available.filter((e) => e.zone == filterZone);
      }
    },
    triggerFilters() {
      this.filteredAvailable = this.filteredAvailable.filter((driver) => {
        let toFilter = true;

        if (this.filterLocation) {
          toFilter &= driver.baseLocationId === this.filterLocation;
        }

        if (this.filterDesignation !== 'All') {
          if (this.filterDesignation === 'None') {
            toFilter &= !driver.designation || driver.designation === '';
          } else if (this.filterDesignation) {
            toFilter &= driver.designation == this.filterDesignation;
          }
        }

        return toFilter;
      });

      if (this.nameFilter && this.nameFilter.length) this.filterByName();
    },
    filterByName(str) {
      if (str) {
        this.nameFilter = typeof str != 'string' ? `${str.firstName} ${str.lastName}` : str;
      }

      if (this.nameFilter && this.nameFilter.length) {
        this.filteredAvailable = this.filteredAvailable.filter(
          (e) => `${e.firstName} ${e.lastName}`.toLowerCase().indexOf(this.nameFilter.toLowerCase()) >= 0
        );
      } else {
        this.filteredAvailable = [...this.available];
        this.filterList(false);
      }
    },
    handleSort() {
      if (this.sort == 'rotation') this.filteredAvailable = sortBy(this.filteredAvailable, ['rotationOrder']);
      else if (this.sort == 'name') this.filteredAvailable = sortBy(this.filteredAvailable, ['lastName', 'firstName']);
    },
    async reserve(includeVehicle) {
      const objs = [];
      for (let driver of this.selected) {
        if (this.assistant) {
          const a = {
            id: this.assignmentId,
            assistantId: driver.id,
            notes: this.driverLogNotes.length ? this.driverLogNotes : null,
            override: this.override,
            raw: true,
          };
          objs.push(a);
        } else {
          const a = {
            tripRequestId: this.tripRequest.id,
            driverId: driver.id,
            notes: this.driverLogNotes.length ? this.driverLogNotes : null,
            override: this.override,
            leg: this.leg,
            driverEmail: this.driversById[driver.id].email || null,
          };
          if (includeVehicle) a.vehicleId = driver.assignedVehicleId || 0;
          if (this.assignmentId > 0) {
            a.id = this.assignmentId;
            a.raw = true;
          }
          objs.push(a);
        }
      }
      this.$emit('reserve', objs);
      this.selected = [];
      this.driverLogNotes = '';
      this.close();
    },
    close() {
      this.selected = [];
      this.dialog = false;
    },
    getDriverText(driver) {
      return driver.firstName + ' ' + driver.lastName;
    },
    getLocationText(location) {
      return location.name + ' (' + location.code + ')';
    },
    hasVehicle(list) {
      for (let d of list) if (d.assignedVehicleId) return true;
      return false;
    },
    getVehicleColor(vehicleId) {
      if (!vehicleId) return '';
      const vehicle = this.vehiclesById[vehicleId];
      if (!vehicle) return '';
      if (
        this.tripRequest.needSpecialNeedsVehicle != vehicle.wheelchair &&
        this.tripRequest.vehicleTypeId != vehicle.type
      )
        return 'font-weight-bold red--text text--darken-1';
      else if (this.tripRequest.needSpecialNeedsVehicle != vehicle.wheelchair)
        return 'font-weight-bold amber--text text--darken-2';
      else if (this.tripRequest.vehicleTypeId != vehicle.type)
        return 'font-weight-bold deep-purple--text text--darken-3';
      else return '';
    },
    async submitLogNotes() {
      if (!this.driverLogNotes) return this.$myalert.error('Please enter a comment first');
      try {
        if (!this.selected.length) return this.$myalert.error('Please select a driver first');
        const r = await this.saveDriverLog({ driverId: this.selected[0].id, message: this.driverLogNotes });
        if (r) {
          this.$myalert.success('Driver comment added');
          this.driverLogNotes = '';
        }
      } catch (error) {
        this.$myalert.error(error.message);
      }
    },
  },
  watch: {
    selected(val, oldVal) {
      let res = this.tripRequest.splitTrip
        ? this.tripRequest.assignments.filter((e) => e.leg == this.leg).length
        : this.reserved;
      const cap = this.assignmentId > 0 ? 1 : this.max - res;
      if (val.length > cap) {
        this.$nextTick(() => {
          this.selected = oldVal;
        });
      }
    },
  },
};
</script>

<style lang="scss">
.v-input__icon--append .v-icon {
  color: #4caf50;
}
</style>
